<template>
    <Panel title="Wallet Transactions" :loading="loading" @closePanel="closePanel">
        <Datatable
            name="Wallet transactions"
            @search="fetchData"
            :pagination.sync="pagination"
            :loading="loading"
            :hideActions="hideActions"
        >
            <template v-slot:thead>
                <tr>
                    <th>ID</th>
                    <th>Amount</th>
                    <th>Comment</th>
                    <th>Transaction at</th>
                    <th>Created at</th>
                    <th></th>
                </tr>
            </template>
            <tr
                v-for="(transaction, index) in transactions"
                :key="index"
                @click="openTransactionModal(transaction)"
                valign="middle"
            >
                <td>
                    <code>{{ transaction.id }}</code>
                </td>
                <td>
                    <Badge :color="transaction.amount > 0 ? 'success' : 'danger'">{{
                        transaction.amount | currency
                    }}</Badge>
                </td>
                <td>
                    {{ transaction.main_comment }}
                    <code v-if="Object.keys(transaction.comments).length > 0" class="d-block text-muted">
                        <template v-for="(comment, key) in transaction.comments">
                            <template v-if="key === 'quote_line_id'">
                                {{ key }}:
                                <a
                                    :key="key"
                                    href="#"
                                    @click.prevent="openQuoteModal(comment)"
                                    :class="{ 'pe-none opacity-50': loadingLink }"
                                    >{{ comment }}</a
                                >
                            </template>

                            <a
                                :key="key"
                                v-if="key == 'wallet_id'"
                                href="#"
                                @click.prevent="openWallet(comment)"
                                class="custom-link"
                            >
                                {{ key }}: {{ comment }} <br />
                            </a>
                            <template v-else> {{ key }}: {{ comment }}<br /> </template>
                        </template>
                    </code>
                    <code v-if="transaction.start_date || transaction.expiration_date" class="d-block text-muted">
                        <template v-if="transaction.start_date">
                            start_date: {{ transaction.start_date | formatDate('DD/MM/YYYY') }} <br />
                        </template>
                        <template v-if="transaction.expiration_date">
                            expiration_date: {{ transaction.expiration_date | formatDate('DD/MM/YYYY') }}
                        </template>
                    </code>
                </td>
                <td>{{ transaction.transaction_at | formatDate }}</td>
                <td>{{ transaction.created_at | formatDate }}</td>
                <td class="text-end"><Icon icon="angle-right" v-if="transaction.payment_transaction" /></td>
            </tr>
        </Datatable>
        <ID name="Hexeko" :value="id" class="mt-3 me-1" />
    </Panel>
</template>

<script>
    import { Panel, Datatable, Badge, Alerts, ID } from '@tech_hexeko/design-system'

    export default {
        name: 'WalletTransactionsPanel',
        props: {
            id: Number,
        },
        data() {
            return {
                loading: true,
                search: null,
                loadingLink: false,
                pagination: {
                    current_page: 1,
                },
                transactions: [],
                hideActions: true,
            }
        },
        components: {
            Panel,
            Datatable,
            Badge,
            ID,
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.search = search
                this.$api.wallets
                    .getTransactions(this.id, {
                        search: this.search ? this.search : undefined,
                        page: this.pagination.current_page,
                        include: ['payment_transaction'],
                    })
                    .then((response) => {
                        this.transactions = response.data
                        this.pagination = response.meta.pagination
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            openQuoteModal(quoteLineId) {
                let quoteId = null
                this.loadingLink = true
                this.$api.billing.quotes
                    .showLine(quoteLineId)
                    .then((response) => {
                        quoteId = response.quote_id ? response.quote_id : null
                    })
                    .catch((error) => {
                        if (error && error.response) Alerts.notificationError(error.response.data.message)
                    })
                    .finally(() => {
                        this.openPanel('QuotePanel', quoteId)
                        this.loadingLink = false
                    })
            },
            openWallet(walletId) {
                this.openPanel('WalletTransactions', walletId)
            },
            openTransactionModal(transaction) {
                if (transaction && transaction.payment_transaction) {
                    this.openPanel('PaymentTransactionPanel', transaction.payment_transaction.id)
                }
            },
            closePanel(payload) {
                this.$emit('closePanel', payload)
            },
        },
    }
</script>
<style lang="css" scoped>
    .table-hover tr:hover td code .custom-link {
        color: #ffffff !important;
        text-decoration: underline;
    }
</style>
