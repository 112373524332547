<template>
    <FormRoot :loading="dataLoading">
        <Field
            :form.sync="form"
            type="text"
            name="name"
            :translate="true"
            placeholder="Name"
            hint="Name of the offer"
        ></Field>
        <Field :form.sync="form" type="text" name="price" placeholder="Price (€)" hint="Price of the offer"></Field>
        <Field
            :form.sync="form"
            type="text"
            name="vat_rate"
            placeholder="VAT rate (%)"
            hint="VAT rate of the offer"
        ></Field>
        <Field
            :form.sync="form"
            type="boolean"
            name="is_promotion"
            placeholder="Is promotion ?"
            @input="handlePromotion"
        ></Field>
        <Field
            :form.sync="form"
            type="text"
            name="price_original"
            placeholder="Original price (€)"
            hint="Original price of the offer"
            v-if="form.is_promotion"
        ></Field>
        <Field
            :form.sync="form"
            type="tags"
            name="tags"
            placeholder="Tags of the offer"
            hint="What are the tags of the offer ?"
        ></Field>
        <Field
            :form.sync="form"
            type="component"
            component="Stores"
            multiple
            name="stores"
            hint="Stores where the offer is published"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit"
                >Update</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        props: {
            id: {
                type: Number,
                default: null,
            },
        },
        data() {
            return {
                fields: {
                    name: null,
                    price: null,
                    price_original: null,
                    vat_rate: null,
                    is_promotion: false,
                    tags: [],
                    stores: [],
                },
                messageSuccess: 'The offer has been saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            loadData() {
                return this.$api.offers.show(this.id).then((offer) => {
                    offer.tags = _.map(offer.tags, function(item) {
                        return typeof item === 'object' ? item.en : item
                    })
                    return offer
                })
            },
            submit() {
                if (this.id) {
                    return this.$api.offers.update(this.id, this.$data.form.data())
                } else {
                    return this.$api.activities.createOffer(this.values.activity_id, this.$data.form.data())
                }
            },
            handlePromotion(val) {
                if (val && !this.form.price_original) {
                    this.form.price_original = this.form.price
                } else {
                    this.form.price_original = null
                }
            },
        },
    }
</script>
