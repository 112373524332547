<template>
    <Panel :title="panelTitle" :loading="loading" @closePanel="closePanel">
        <Tabs v-if="!loading">
            <Tab name="Partner" :selected="true">
                <div class="mb-2">
                    <Boolean v-model="partner.enabled" textOn="Enabled" textOff="Disabled" class="me-1" />
                    <Boolean
                        v-model="partner.contract_signed"
                        textOn="Contract signed"
                        textOff="Contract not signed"
                        class="me-1"
                    />
                    <Boolean v-model="partner.suggested_by" textOn="Suggested" textOff="Added by admin" />
                </div>
                <Sheet name="Partner">
                    <Row name="Name">{{ partner.name[$store.state.lang.displayLanguage] }}</Row>
                    <Row name="Address" v-if="partner.address">
                        <Address :address="partner.address" />
                    </Row>
                    <template v-if="partner.contacts">
                        <Row name="E-mail" v-if="partner.contacts.email"
                            ><a :href="'mailto:' + partner.contacts.email">{{ partner.contacts.email }}</a></Row
                        >
                        <Row name="Phone" v-if="partner.contacts.phone"
                            ><a :href="'tel:' + partner.contacts.phone">{{ partner.contacts.phone }}</a></Row
                        >
                    </template>
                    <Row name="Website"
                        ><a :href="partner.website" target="_blank">{{ partner.website }}</a></Row
                    >
                    <Row name="IBAN" v-if="partner.iban_last_four">•••• {{ partner.iban_last_four }}</Row>
                    <Row name="Merchant ID" v-if="partner.mid">{{ partner.mid }}</Row>
                    <Row name="Opening hours" v-if="partner.opening_hours">
                        <div class="row" v-for="(hours, day) in partner.opening_hours">
                            <div class="col-4 text-muted">{{ day }}</div>
                            <div class="col-8">
                                <template v-if="hours.length > 0">
                                    <div v-for="hour in hours">{{ hour }}</div>
                                </template>
                                <div v-else>-</div>
                            </div>
                        </div>
                    </Row>
                    <Row name="Description" v-if="partner.description[$store.state.lang.displayLanguage]"
                        ><div v-html="partner.description[$store.state.lang.displayLanguage]"></div
                    ></Row>
                    <Row name="Sync Google Places">
                        <Boolean v-model="partner.sync_google_maps" textOn="Enabled" textOff="Disabled"></Boolean>
                    </Row>
                    <Row name="Suggested by" v-if="partner.suggested_by"
                        ><a href="#" @click.prevent="openUser(partner.suggested_by)">{{
                            partner.suggested_by.name
                        }}</a></Row
                    >
                    <Row name="Created at">{{ partner.created_at | formatDate }}</Row>
                    <Row name="Updated at">{{ partner.updated_at | formatDate }}</Row>
                </Sheet>

                <Sheet name="Financial">
                    <Row name="Registration number" v-if="partner.registration_number">{{
                        partner.registration_number
                    }}</Row>
                    <Row name="VAT number" v-if="partner.vat_number">{{ partner.vat_number }}</Row>
                    <Row name="Commission (%)" v-if="partner.commission !== null">{{ partner.commission }} %</Row>
                </Sheet>

                <ID name="Hexeko" :value="partner.id" />
            </Tab>
            <Tab name="Activities" v-if="$store.getters.userHasAnyPermission(['showPartners', 'managePartners'])">
                <Activities :params="{ partners: [partner.id] }"></Activities>
            </Tab>
            <Tab name="Bookings" v-if="$store.getters.userHasAnyPermission(['showBookings', 'manageBookings'])">
                <Bookings :params="{ partner: partner.id }"></Bookings>
            </Tab>
            <Tab name="Expenses" v-if="$store.getters.userHasAnyPermission(['showExpenses', 'manageExpenses'])">
                <Expenses
                    :params="{ partner: partner.id }"
                    :showAllowances="false"
                    :showPaymentIntents="false"
                    :showPaymentTransactions="false"
                />
            </Tab>
            <Tab name="Issuing">
                <Issuing :id="partner.id" />
            </Tab>
            <Tab name="Types">
                <TypesSelector :types="partner.types" @save="typesSave" />
            </Tab>
            <Tab name="Administrators" v-if="$store.getters.userHasPermission('managePartners')">
                <Table striped borderless>
                    <template v-slot:thead>
                        <tr>
                            <th>Name</th>
                        </tr>
                    </template>
                    <tr v-for="user in users">
                        <td>
                            <a href="#" @click.prevent="openUser(user)">{{ user.name }}</a>
                        </td>
                        <td align="right">
                            <Button
                                is="confirm"
                                @confirm="removeAccess(user.id)"
                                title="Remove the administrator ?"
                                color="danger"
                                small
                                icon="times"
                                >Remove administrator</Button
                            >
                        </td>
                    </tr>
                </Table>

                <template v-if="$store.getters.userHasPermission('managePartners')">
                    <!-- Give access -->
                    <Button color="secondary" small @click="openModal('giveAccess')" icon="plus" class="me-1"
                        >Add administrator</Button
                    >
                    <Modal
                        v-if="isModalOpen('giveAccess')"
                        @close="closeModal('giveAccess')"
                        title="Add an administrator"
                    >
                        <GiveAccess :partner="partner.id" @submitted="administratorAdded"></GiveAccess>
                    </Modal>
                </template>
            </Tab>
        </Tabs>
        <template v-slot:actions v-if="$store.getters.userHasPermission('managePartners') && partner">
            <!-- Actions -->
            <Dropdown small title="Actions" position="right">
                <!-- Enable / Disable -->
                <DropdownItem v-if="partner.enabled" confirm @confirm="disable" class="text-danger" icon="times-circle"
                    >Disable</DropdownItem
                >
                <DropdownItem v-else @click="enable" class="text-success" icon="check-circle">Enable</DropdownItem>
                <!-- Update -->
                <DropdownItem @click="openModal('updatePartner')" icon="edit">Update partner</DropdownItem>
                <DropdownItem @click="openModal('updateDescription')" icon="images"
                    >Update description and medias</DropdownItem
                >
                <DropdownItem @click="openModal('options')" icon="cogs">Options</DropdownItem>
                <!-- Delete -->
                <DropdownItem confirm @confirm="deletePartner" class="text-danger" icon="trash-alt"
                    >Delete partner</DropdownItem
                >
            </Dropdown>
            <!-- Modal Update -->
            <Modal v-if="isModalOpen('updatePartner')" @close="closeModal('updatePartner')" title="Update partner">
                <UpdatePartner :id="partner.id" @submitted="partnerUpdated"></UpdatePartner>
            </Modal>
            <!-- Modal Description -->
            <Modal
                v-if="isModalOpen('updateDescription')"
                @close="closeModal('updateDescription')"
                title="Update description"
            >
                <UpdateDescription :id="partner.id" @submitted="partnerUpdated"></UpdateDescription>
            </Modal>
            <!-- Modal Options -->
            <Modal v-if="isModalOpen('options')" @close="closeModal('options')" title="Options">
                <Options :id="partner.id" @submitted="partnerUpdated"></Options>
            </Modal>
        </template>
    </Panel>
</template>

<script>
    import {
        Tabs,
        Tab,
        Panel,
        Sheet,
        Row,
        Boolean,
        Button,
        Modal,
        Table,
        Dropdown,
        DropdownItem,
        Alerts,
        ID,
    } from '@tech_hexeko/design-system'
    import Activities from '@/components/Activities'
    import Bookings from '@/components/layout/Tabs/Bookings'
    import Expenses from '@/components/layout/Tabs/Expenses'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import GiveAccess from '@/components/Partners/GiveAccess'
    import Address from '@/components/Address'
    import UpdatePartner from './UpdatePartner'
    import UpdateDescription from './UpdateDescription'
    import Options from './Options'
    import Issuing from './Issuing'
    import TypesSelector from '../Types/Selector'

    export default {
        name: 'PartnerPanel',
        props: {
            id: Number,
        },
        components: {
            Panel,
            Sheet,
            Row,
            Boolean,
            Button,
            Tabs,
            Tab,
            Activities,
            Modal,
            GiveAccess,
            Table,
            Address,
            Dropdown,
            DropdownItem,
            UpdatePartner,
            UpdateDescription,
            Options,
            Bookings,
            Expenses,
            Issuing,
            TypesSelector,
            ID,
        },
        data() {
            return {
                loading: true,
                partner: null,
                users: [],
            }
        },
        mounted() {
            this.fetchData()
        },
        mixins: [Modals],
        methods: {
            fetchData() {
                this.loading = true
                this.fetchPartner().then((response) => {
                    this.fetchAdministrators().then((response) => {
                        this.loading = false
                    })
                })
            },
            fetchPartner() {
                return this.$api.partners.show(this.id, { includes: ['suggested_by', 'types'] }).then((response) => {
                    this.partner = response
                })
            },
            fetchAdministrators() {
                return this.$api.partners.getUsers(this.id).then((response) => {
                    this.users = response.data
                })
            },
            openUser(user) {
                this.openPanel('userPanel', user.id)
            },
            enable() {
                this.$api.partners.enable(this.id).then(() => {
                    this.fetchData()
                })
            },
            disable() {
                this.$api.partners.disable(this.id).then(() => {
                    this.fetchData()
                })
            },
            deletePartner() {
                this.$api.partners.delete(this.id).then(() => {
                    this.closePanel(true)
                })
            },
            administratorAdded() {
                this.closeModal('giveAccess')
                this.fetchAdministrators()
            },
            partnerUpdated() {
                this.closeModal('updatePartner')
                this.fetchData()
            },
            removeAccess(user_id) {
                this.$api.partners
                    .removeAccessToPartner(this.id, {
                        user_id: user_id,
                    })
                    .then(() => {
                        this.fetchAdministrators()
                    })
            },
            closePanel(refresh) {
                this.$emit('closePanel', {
                    refresh: refresh,
                })
            },
            typesSave(types) {
                this.$api.partners.update(this.id, { types: types }).then((response) => {
                    Alerts.messageSuccess('Types saved')
                })
            },
        },
        computed: {
            panelTitle: function() {
                return this.partner ? this.partner.name[this.$store.state.lang.displayLanguage] : null
            },
        },
    }
</script>
