<template>
    <Datatable
        name="Groups"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
    >
        <template v-slot:filters>
            <Dropdown class="me-1 mb-1" color="secondary" title="Filter by market segment" small>
                <li v-for="(segment, index) in marketSegments" :key="index">
                    <a
                        href="#"
                        class="dropdown-item text-xs"
                        :class="marketSegment === segment.technical_name ? 'bg-primary text-white' : 'bg-white'"
                        @click.prevent="filterByMarketSegment(segment.technical_name)"
                        >{{ segment.name }}</a
                    >
                </li>
            </Dropdown>
            <Button
                class="me-1"
                @click="toggleFilterWithCardIssuing"
                :color="withCards ? 'success' : withCards === false ? 'danger' : 'secondary'"
                small
            >
                With card issuing
            </Button>
            <Button
                class="me-1"
                @click="toggleFilterRequiresIssFunding"
                :color="requiresIssFunding ? 'success' : requiresIssFunding === false ? 'danger' : 'secondary'"
                small
            >
                Requires issuing funding
            </Button>
        </template>
        <template v-slot:thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <template v-if="withIssuing">
                    <th>Issuing Balance</th>
                    <th>Should be</th>
                    <th>Difference</th>
                </template>
                <template v-else>
                    <th>Market</th>
                    <th>Source</th>
                </template>
                <th>Users</th>
                <th>Issuing</th>
            </tr>
        </template>
        <tr v-for="(group, index) in groups" @click="open(group)" class="cursor-pointer" :key="index">
            <td width="10%">
                <code>{{ group.id }}</code>
            </td>
            <td :width="withIssuing ? '30%' : '50%'">{{ group.name }}</td>
            <template v-if="withIssuing && group.issuing">
                <td width="10%">
                    {{ group.issuing.balance | currency }}
                </td>
                <td>
                    {{ group.issuing.balance_estimated | currency
                    }}<span v-if="group.issuing.balance_alt_groups" class="text-muted">
                        (+ {{ group.issuing.balance_alt_groups | currency }})</span
                    >
                </td>
                <td
                    width="10%"
                    :class="{
                        'text-success': group.issuing.balance_difference > 0,
                        'text-danger': group.issuing.balance_difference < 0,
                    }"
                >
                    {{ Math.abs(group.issuing.balance_difference) | currency }}
                </td>
            </template>
            <template v-else>
                <td class="text-muted"><span v-if="group.market_segment">{{ group.market_segment.name }}</span></td>
                <td class="text-muted"><GroupSource :group="group" /></td>
            </template>
            <td class="text-muted">
                <Icon icon="users" /> {{ group.count_users }} <span v-if="group.count_users > 1">users</span
                ><span v-else>user</span>
            </td>
            <td width="10%">
                <Icon icon="check-circle" v-if="group.issuing && group.issuing.can_issue" color="#00e5a5" />
                <Icon icon="times-circle" v-else color="#fe6c61" />
            </td>
        </tr>
        <template v-slot:actions v-if="$store.getters.userHasPermission('manageGroups')">
            <Button small @click="openModal('createGroup')" icon="plus">Create group</Button>
            <Modal v-if="isModalOpen('createGroup')" @close="closeModal('createGroup')" title="Create a group">
                <UpdateGroup :values="params" @submitted="groupSubmitted"></UpdateGroup>
            </Modal>
        </template>
    </Datatable>
</template>

<script>
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import { Alerts, Boolean, Button, Datatable, Dropdown, Modal } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import UpdateGroup from './UpdateGroup'
    import GroupSource from '@/components/Groups/source'
    import { formatBoolean } from '@/utils/query'

    export default {
        name: 'Groups',
        components: {
            Button,
            Datatable,
            Dropdown,
            Modal,
            UpdateGroup,
            GroupSource,
        },
        mixins: [Modals],
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                search: null,
                pagination: {
                    current_page: 1,
                },
                groups: [],
                marketSegment: null,
                marketSegments: null,
                withCards: null,
                requiresIssFunding: null,
            }
        },
        mounted() {
            this.fetchMarketSegments()
            this.fetchData()
        },
        computed: {
            withIssuing() {
                return this.withCards !== null || this.requiresIssFunding !== null
            },
        },
        methods: {
            fetchData(search) {
                this.search = search
                this.loading = true
                let params = this.mergeParams({
                    search: this.search,
                    page: this.pagination.current_page,
                    market_segments: [this.marketSegment],
                    with_cards_allowed: formatBoolean(this.withCards),
                    order_by: 'created_at',
                    sort_by: 'desc',
                    has_positive_issuing_balance_difference: formatBoolean(this.requiresIssFunding),
                    includes: ['issuing', 'external_id'],
                    isCancellable: true,
                })
                this.$api.groups
                    .get(params)
                    .then((response) => {
                        this.groups = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
                    .catch((error) => {
                        if (error && error.response) console.log(error.response.data.message)
                    })
            },

            fetchMarketSegments() {
                this.$api.groups
                    .getMarketSegments()
                    .then((response) => {
                        this.marketSegments = response.data
                    })
                    .catch((error) => {
                        let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
            },
            filterByMarketSegment(segment) {
                this.marketSegment = this.marketSegment === segment ? null : segment
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            open(group) {
                let panel = this.openPanel('groupPanel', group.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
            groupSubmitted() {
                this.fetchData(this.search)
                this.closeModal('createGroup')
            },
            toggleFilterWithCardIssuing() {
                if (this.withCards === null) this.withCards = true
                else if (this.withCards === true) this.withCards = false
                else this.withCards = null
                this.fetchData(this.search)
            },
            toggleFilterRequiresIssFunding() {
                if (this.requiresIssFunding === null) this.requiresIssFunding = true
                else if (this.requiresIssFunding === true) this.requiresIssFunding = false
                else this.requiresIssFunding = null
                this.fetchData(this.search)
            },
        },
    }
</script>
