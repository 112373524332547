<template>
    <FormRoot :loading="dataLoading">
        <Field
            :form.sync="form"
            type="text"
            name="name"
            placeholder="List name"
            hint="Name of the list (visible to administrators only)"
        ></Field>
        <Field
            :form.sync="form"
            type="textarea"
            :translate="true"
            name="instruction_intro"
            placeholder="Instructions intro"
            hint="Intro text describing how to use the voucher (above the voucher code)"
        ></Field>
        <Field
            :form.sync="form"
            type="textarea"
            :translate="true"
            name="instruction_outro"
            placeholder="Footer text"
            hint="Footer text below the voucher code"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit"
                >Save list</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        data() {
            return {
                fields: {
                    name: null,
                    instruction_intro: null,
                    instruction_outro: null,
                },
                messageSuccess: 'Voucher list saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            loadData() {
                return this.$api.voucherLists.show(this.id)
            },
            submit() {
                if (this.id) {
                    return this.$api.voucherLists.update(this.id, this.$data.form.data())
                } else {
                    return this.$api.voucherLists.create(this.$data.form.data())
                }
            },
        },
    }
</script>
