<template>
    <div>
        <Datatable
            name="Voucher lists"
            @search="fetchData"
            :pagination.sync="pagination"
            :loading="loading"
            :hideActions="hideActions"
        >
            <template v-slot:thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Remaining</th>
                    <th></th>
                </tr>
            </template>
            <tr v-for="(list, index) in lists" @click="open(list)" class="cursor-pointer" :key="index">
                <td width="5%">
                    <code>{{ list.id }}</code>
                </td>
                <td width="55%">{{ list.name }}</td>
                <td width="20%">
                    <Progress :total="list.count_vouchers" :count="list.count_vouchers_picked"></Progress>
                </td>
                <td width="5%" class="text-right"><Icon icon="angle-right" /></td>
            </tr>
            <template v-slot:actions v-if="$store.getters.userHasPermission('manageVouchers')">
                <Button small @click="openModal('createList')" icon="plus">Create voucher list</Button>
                <Modal v-if="isModalOpen('createList')" @close="closeModal('createList')" title="Create a voucher list">
                    <UpdateList @submitted="listSubmitted"></UpdateList>
                </Modal>
            </template>
        </Datatable>
    </div>
</template>

<script>
    import { Datatable, Modal, Progress, Button, Boolean, Badge } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import UpdateList from '@/components/VoucherLists/UpdateList'

    export default {
        name: 'VoucherLists',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                pagination: {
                    current_page: 1,
                },
                lists: [],
            }
        },
        components: {
            Datatable,
            UpdateList,
            Modal,
            Button,
            Progress,
            Badge,
        },
        mixins: [Modals],
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.$api.voucherLists
                    .get(
                        this.mergeParams({
                            search: search,
                            page: this.pagination.current_page,
                            order_by: 'availability',
                            sort_by: 'desc',
                        })
                    )
                    .then((response) => {
                        this.lists = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            open(list) {
                let panel = this.openPanel('VoucherListPanel', list.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData()
                    }
                })
            },
            listSubmitted() {
                this.closeModal('createList')
                this.fetchData()
            },
        },
    }
</script>
