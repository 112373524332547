<template>
    <svg class="is--stroke" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15 1.5v6A1.5 1.5 0 0 0 16.5 9h6"
            stroke="#000"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M13.5 22.5h3m3 6h-15a3 3 0 0 1-3-3v-21a3 3 0 0 1 3-3H15L22.5 9v16.5a3 3 0 0 1-3 3Zm-12-21H9 7.5Zm0 9h9-9Z"
            stroke="#000"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-quotable-activation',
    }
</script>
