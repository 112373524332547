<template>
    <FormRoot>
        <Field
            :form.sync="form"
            type="component"
            component="VoucherLists"
            name="voucher_list_id"
            :searchFunction="fetchVoucherLists"
            hint="Select a list of vouchers"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" @click="onSubmit">Link voucher list</Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        props: {
            offer: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                fields: {
                    voucher_list_id: null,
                },
                messageSuccess: 'Vouchers list is linked with offer',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            submit() {
                return this.$api.offers.setVoucherList(this.offer, this.$data.form.data())
            },
            fetchVoucherLists(search) {
                return this.$api.voucherLists.get({
                    search: search,
                })
            },
        },
    }
</script>
