<template>
    <svg viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.404 2.561V1.93h.533a.539.539 0 0 0 .532-.545V.84a.539.539 0 0 0-.532-.545H7.742a.539.539 0 0 0-.532.545v.545c0 .301.238.545.532.545h.533v.632c-3.61.53-6.388 3.702-6.388 7.546h14.905c0-3.844-2.778-7.015-6.388-7.546Zm6.92 8.636H1.355a.539.539 0 0 0-.532.545v1.09c0 .301.238.545.532.545h15.97a.539.539 0 0 0 .533-.545v-1.09a.539.539 0 0 0-.532-.545Z"
            fill="#000"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-meal',
    }
</script>
