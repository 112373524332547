<template>
    <Badge v-if="license.used_at" color="secondary" :large="large" icon="check-circle">Used</Badge>
    <Badge v-else color="success" :large="large" icon="circle-notch">Available</Badge>
</template>
<script>
    import { Badge } from '@tech_hexeko/design-system'

    export default {
        name: 'LicenseStatus',
        props: {
            license: Object,
            large: Boolean,
        },
        components: {
            Badge,
        },
    }
</script>
