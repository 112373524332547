<template>
    <Table striped borderless>
        <template v-slot:thead>
            <tr>
                <th width="20%">Date</th>
                <th width="25%">Status</th>
                <th>Reason</th>
            </tr>
        </template>
        <tr v-for="(status, index) in statuses" :key="'history-' + index">
            <td>
                {{ status.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}
            </td>
            <td>
                <slot :status="status"></slot>
            </td>
            <td>
                <div v-if="status.reason" style="max-width:400px">
                    <Reason :slug="status.reason" />
                </div>
                <code class="d-block mt-1 mb-1">{{ status.reason }}</code>
                <RawOutput
                    v-if="status.extra_data && Object.keys(status.extra_data).length > 0"
                    :item="status.extra_data"
                />
            </td>
        </tr>
    </Table>
</template>

<script>
    import { Table } from '@tech_hexeko/design-system'
    import RawOutput from '@/components/RawOutput'
    import Reason from '@/components/Reason'

    export default {
        name: 'History',
        components: {
            RawOutput,
            Reason,
            Table,
        },
        props: {
            statuses: {
                type: Array,
            },
        },
    }
</script>
