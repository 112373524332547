<template>
    <Badge :color="getColor" :icon="getIcon">{{ getTitle }}</Badge>
</template>
<script>
    import { Badge } from '@tech_hexeko/design-system'

    export default {
        name: 'CardTokenStatus',
        props: {
            status: {
                type: String,
            },
        },
        components: {
            Badge,
        },
        data() {
            return {
                statuses: {
                    active: {
                        icon: 'check-circle',
                        title: 'Active',
                        color: 'success',
                    },
                    suspended: {
                        icon: 'pause-circle',
                        title: 'Suspended',
                        color: 'warning',
                    },
                    deleted: {
                        icon: 'stop-circle',
                        title: 'Deleted',
                        color: 'danger',
                    },
                },
            }
        },
        computed: {
            getColor() {
                return this.statuses[this.status]?.color ? this.statuses[this.status].color : 'primary'
            },
            getTitle() {
                return this.statuses[this.status]?.title ? this.statuses[this.status].title : 'N/A'
            },
            getIcon() {
                return this.statuses[this.status]?.icon ? this.statuses[this.status].icon : 'check-circle'
            },
        },
    }
</script>
