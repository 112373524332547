<template>
    <svg class="is--stroke" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.313 9.087 5.35 12.5 1.938 4.537"
            stroke="#000"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M5.35 12.5c0-6.279 5.096-11.375 11.375-11.375A11.33 11.33 0 0 1 27.031 7.7a11.321 11.321 0 0 1 1.069 4.8c0 6.279-5.096 11.375-11.375 11.375A11.33 11.33 0 0 1 6.419 17.3"
            stroke="#000"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-refund',
    }
</script>
