<template>
    <code v-if="item && Object.keys(item).length > 0" class="d-block text-muted">
        <template v-for="(line, key) in item">
            <template v-if="!!line && Object.keys(line).length > 0 && typeof line === 'object'">
                {{ key }}:<br />
                <template v-for="(subline, subkey) in line"> -- {{ subkey }}: {{ subline }}<br /> </template> </template
            ><template v-else>{{ key }}: {{ line }}<br /></template>
        </template>
    </code>
</template>

<script>
    export default {
        name: 'RawOutput',
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
    }
</script>
