import fetch from 'node-fetch';
import jose from 'node-jose';
const _ = require('lodash');

export default {
    data() {
        return {
            genUppercase: [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L',
                'M',
                'N',
                'O',
                'P',
                'Q',
                'R',
                'S',
                'T',
                'U',
                'V',
                'W',
                'X',
                'Y',
                'Z',
            ],
            genLowercase: [
                'a',
                'b',
                'c',
                'd',
                'e',
                'f',
                'g',
                'h',
                'i',
                'j',
                'k',
                'l',
                'm',
                'n',
                'o',
                'p',
                'q',
                'r',
                's',
                't',
                'u',
                'v',
                'w',
                'x',
                'y',
                'z',
            ],
            genSpecial: [
                '!',
                '@',
                '#',
                '$',
                '%',
                '^',
                '&',
                '*',
                '(',
                ')',
                '_',
                '+',
                '-',
                '=',
                '{',
                '}',
                '[',
                ']',
                ':',
                ';',
                '?',
                '.',
            ],
            genNumbers: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
            genLength: 12,
        };
    },
    computed: {
        genNonSpecial() {
            return [...this.genUppercase, ...this.genLowercase, ...this.genNumbers];
        },
    },
    methods: {
        async encryptPin(pin) {
            // Fetch Stripe's RSA public key
            const keyData = await fetch('https://issuing-key.stripe.com/v1/keys').then((r) => r.json());
            const opts = { format: 'compact' };
            const jwe = await jose.JWE.createEncrypt(opts, keyData.jwk)
                .update(pin)
                .final()
                .then((result) => {
                    return result;
                });
            return jwe;
        },
        generatePassword(length) {
            let password = this.getRandomChar(this.genUppercase);
            password += this.getRandomChar(this.genLowercase);
            password += this.getRandomChar(this.genSpecial);
            password += this.getRandomChar(this.genNumbers);

            for (let i = 0; i < this.genLength - 4; i++) password += this.getRandomChar(this.genNonSpecial);
            password = _.shuffle(password).join('');

            return password;
        },
        generatePin(length = 4) {
            let pin = '';
            for (let i = 0; i < length; i++) pin += this.randomIntInRange(0, 9);
            return pin;
        },
        randomFloat() {
            let int = '';
            if (window.crypto && window.crypto.getRandomValues) {
                int = window.crypto.getRandomValues(new Uint32Array(1))[0];
            } else {
                int = Math.random();
            }
            return int / 2 ** 32;
        },
        randomIntInRange(min, max) {
            const range = max - min;
            return Math.floor(this.randomFloat() * range + min);
        },
        getRandomChar(arr) {
            const rand = this.randomIntInRange(0, arr.length);
            return arr[rand];
        },
    },
};
