<template>
    <div>
        <Tabs :pills="!embedInTab" :small="embedInTab" @selectTab="selectTab" ref="embedTabs">
            <Tab
                name="Payouts"
                :selected="selected('payouts', true)"
                :to="{ name: 'payouts' }"
                v-if="$store.getters.userHasAnyPermission(['showPayouts', 'managePayouts'])"
            />
            <Tab
                name="Requests"
                :selected="selected('payout-requests')"
                :to="{ name: 'payout-requests' }"
                v-if="$store.getters.userHasAnyPermission(['showPayouts', 'managePayouts'])"
            />
        </Tabs>
        <router-view :params="params" v-if="!embedInTab" />
        <template v-else-if="activeComponent">
            <component :params="params" :is="activeComponent.name" />
        </template>
    </div>
</template>

<script>
    import TabsMixin from '../../../mixins/TabsMixin'
    import Payouts from '../../Payouts'
    import PayoutRequests from '../../PayoutRequests'

    export default {
        components: {
            Payouts,
            PayoutRequests,
        },
        mixins: [TabsMixin],
    }
</script>
