<template>
    <div>
        <Tabs :pills="!embedInTab" :small="embedInTab" @selectTab="selectTab" ref="embedTabs">
            <Tab
                name="Cards"
                :selected="selected('cards', true)"
                :to="{ name: 'cards' }"
                v-if="$store.getters.userHasAnyPermission(['showCards', 'manageCards'])"
            />
            <Tab
                name="Authorizations"
                :selected="selected('card-authorizations')"
                :to="{ name: 'card-authorizations' }"
                v-if="$store.getters.userHasAnyPermission(['showCards', 'manageCards'])"
            />
            <Tab
                name="Transactions"
                :selected="selected('card-transactions')"
                :to="{ name: 'card-transactions' }"
                v-if="$store.getters.userHasAnyPermission(['showCards', 'manageCards'])"
            />
        </Tabs>
        <router-view :params="params" v-if="!embedInTab" />
        <template v-else-if="activeComponent">
            <component :params="params" :is="activeComponent.name" />
        </template>
    </div>
</template>

<script>
    import TabsMixin from '../../../mixins/TabsMixin'
    import Cards from '../../Cards'
    import CardAuthorizations from '../../CardAuthorizations'
    import CardTransactions from '../../CardTransactions'

    export default {
        components: {
            Cards,
            CardAuthorizations,
            CardTransactions,
        },
        props: {
            showAllowances: {
                type: Boolean,
                default: true,
            },
        },
        mixins: [TabsMixin],
    }
</script>
