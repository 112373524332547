<template>
    <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.283 8.21a9.151 9.151 0 0 0-2.936-5.807c-.367.342-1.929 1.905-2.637 4.301 2.698.413 4.715 1.153 5.573 1.506ZM3.003 2.386A9.151 9.151 0 0 0 .05 8.21a21.664 21.664 0 0 1 5.597-1.573c-.73-2.4-2.313-3.948-2.644-4.251ZM14.714 1.877c-.51.503-2.075 2.215-2.79 4.72a24.35 24.35 0 0 0-5.496-.056c-.73-2.504-2.303-4.202-2.79-4.682A9.128 9.128 0 0 1 9.166 0c2.082 0 4.007.7 5.547 1.877ZM5.844 7.398c-1.876.257-3.85.778-5.842 1.685L0 9.167a9.146 9.146 0 0 0 3.11 6.871c2.815-3.346 3.214-6.323 2.734-8.64ZM18.331 9.083c-.383-.175-2.603-1.136-5.812-1.618-.46 2.305-.046 5.252 2.725 8.555a9.146 9.146 0 0 0 3.09-6.853l-.003-.084Z"
            fill="#000"
        />
        <path
            d="M14.604 16.54a9.114 9.114 0 0 1-5.437 1.793A9.123 9.123 0 0 1 3.75 16.56c2.97-3.539 3.38-6.736 2.867-9.251a23.443 23.443 0 0 1 5.123.055c-.499 2.5-.074 5.672 2.863 9.177Z"
            fill="#000"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-sport',
    }
</script>
