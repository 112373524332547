<template>
    <div>
        <Datatable
            name="Wallet spending limits"
            :pagination.sync="pagination"
            :loading="loading"
            search-class="d-none"
            :hover="false"
        >
            <template v-slot:thead>
                <tr>
                    <th>ID</th>
                    <th>Max. amount</th>
                    <th>Period</th>
                    <th>Days of week</th>
                    <th>Created at</th>
                    <template v-if="$store.getters.userHasPermission('manageWallets')">
                        <th>Actions</th>
                    </template>
                </tr>
            </template>
            <tr v-for="(spendingLimit, index) in spendingLimits" :key="index">
                <td>
                    <code>{{ spendingLimit.id }}</code>
                </td>
                <td>{{ spendingLimit.amount | currency }}</td>
                <td>{{ spendingLimit.days ? 'Per day' : 'Per transaction' }}</td>
                <td>
                    <template v-if="spendingLimit.for_days">
                        <Badge v-for="day in spendingLimit.for_days" :key="day" class="me-1 mb-1">{{
                            days[day]
                        }}</Badge>
                    </template>
                    <Badge v-else>All</Badge>
                </td>
                <td>{{ spendingLimit.created_at | formatDate }}</td>
                <template v-if="$store.getters.userHasPermission('manageWallets')">
                    <td>
                        <a
                            href="#"
                            @click.prevent
                            is="confirm"
                            tag="a"
                            class="text-danger"
                            @confirm="deleteRow(spendingLimit.id)"
                            title="Are you sure you want to delete?"
                        >
                            <Icon icon="trash-alt" />
                            Delete
                        </a>
                    </td>
                </template>
            </tr>
        </Datatable>
    </div>
</template>

<script>
    import { Datatable, Badge } from '@tech_hexeko/design-system'

    export default {
        props: {
            wallet: Number,
        },
        data() {
            return {
                loading: true,
                pagination: {
                    current_page: 1,
                },
                spendingLimits: [],
                days: {
                    1: 'Monday',
                    2: 'Tuesday',
                    3: 'Wednesday',
                    4: 'Thursday',
                    5: 'Friday',
                    6: 'Saturday',
                    7: 'Sunday',
                },
            }
        },
        components: {
            Datatable,
            Badge,
        },
        methods: {
            fetchData() {
                this.loading = true
                this.$api.wallets
                    .getSpendingLimits(this.wallet, {
                        page: this.pagination.current_page,
                    })
                    .then((response) => {
                        this.spendingLimits = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
            },
            deleteRow(spendingLimit) {
                this.loading = true
                this.$api.spendingLimits
                    .delete(spendingLimit)
                    .then(() => {
                        this.loading = false
                        this.fetchData()
                    })
                    .catch(() => {
                        this.loading = false
                    })
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
