<template>
    <Datatable name="Allowances" hideActions :loading="loading" :pagination.sync="pagination" @search="fetchData">
        <template v-slot:filters>
            <Button
                v-for="(allowanceStatus, index) in statuses"
                :key="`status-${index}`"
                class="me-1"
                @click="filterByStatus(allowanceStatus.slug)"
                :color="status === allowanceStatus.slug ? 'success' : 'secondary'"
                small
                >{{ allowanceStatus.name }}</Button
            >
        </template>
        <template v-slot:thead>
            <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Period</th>
                <th>Store</th>
                <th>Type</th>
                <th>User</th>
                <th>Group</th>
                <th>IBAN</th>
                <th>Amount</th>
                <th>Status</th>
                <th></th>
            </tr>
        </template>
        <template v-slot:default>
            <tr v-for="(allowance, index) in allowances" valign="middle" :key="index" @click="open(allowance.id)">
                <!-- ID -->
                <td>
                    <code>{{ allowance.id }}</code>
                </td>
                <!-- Date -->
                <td>{{ allowance.created_at | formatDate }}</td>
                <!-- Period-->
                <td>{{ allowance.period }}</td>
                <!-- Store -->
                <td>
                    <template v-if="allowance.user && allowance.user.store_id"
                        ><Store :id="allowance.user.store_id"
                    /></template>
                </td>
                <!-- Type -->
                <td align="middle">
                    <template v-if="allowance.metadata && allowance.metadata.type"
                        ><IconWallet :category="getIconTypeOfAllowance(allowance.metadata.type)" small
                    /></template>
                </td>
                <!-- User -->
                <td>
                    <template v-if="allowance.user && allowance.user.name">
                        <Icon icon="user" /> {{ allowance.user.name }}
                    </template>
                </td>
                <!-- Group -->
                <td>
                    <template v-if="allowance.group && allowance.group.name">{{ allowance.group.name }}</template>
                </td>
                <!-- IBAN -->
                <td>
                    <template v-if="allowance.iban_last_four">••••&nbsp;{{ allowance.iban_last_four }}</template>
                </td>
                <!-- Amount -->
                <td>{{ allowance.amount | currency }}</td>
                <!-- Status -->
                <td><AllowanceStatus v-if="allowance.status" :status="allowance.status" /></td>
                <td class="text-right"><Icon icon="angle-right" /></td>
            </tr>
        </template>
    </Datatable>
</template>

<script>
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import { Alerts, Button, Datatable } from '@tech_hexeko/design-system'
    import Store from '@/components/layout/Store'
    import AllowanceStatus from '@/components/Allowances/status'
    import IconWallet from '@/components/IconWallet'
    import statuses from './statuses'

    export default {
        name: 'Allowances',
        components: {
            AllowanceStatus,
            Button,
            Datatable,
            IconWallet,
            Store,
        },
        data() {
            return {
                allowances: [],
                loading: true,
                pagination: {
                    current_page: 1,
                },
                search: null,
                statuses: statuses,
                status: null,
            }
        },
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
        },
        methods: {
            fetchData(search) {
                this.loading = true

                // Set params
                this.search = search
                let params = this.mergeParams({
                    search: this.search,
                    statuses: [this.status],
                    page: this.pagination.current_page,
                    includes: ['media', 'user'],
                })

                // Search
                this.$api.allowances
                    .get(params)
                    .then((response) => {
                        this.allowances = response.data
                        this.pagination = response.meta.pagination
                    })
                    .catch((error) => {
                        if (error && error.response) Alerts.notificationError(error.response.data.message)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            filterByStatus(status) {
                this.status = this.status === status ? null : status
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            getIconTypeOfAllowance(type) {
                switch (type) {
                    case 'bicycle':
                        return 'sustainable_mobility_package'
                    case 'carpooling':
                        return 'carpooling'
                    case 'subscription':
                        return 'public_transport_passes'
                    default:
                        return 'mobility'
                }
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            openUser(id) {
                this.openPanel('userPanel', id)
            },
            open(id) {
                let panel = this.openPanel('AllowancePanel', id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
