import _ from 'lodash';

export default {
    state: {
        stores: [],
        user: null,
    },
    getters: {
        userHasRole: (state) => (role) => {
            return state.user && state.user.roles.includes(role);
        },
        userHasPermission: (state) => (permission) => {
            return state.user && state.user.permissions.includes(permission);
        },
        userHasAnyPermission: (state) => (permissions) => {
            return (
                state.user &&
                Array.isArray(permissions) &&
                state.user.permissions &&
                _.intersection(permissions, state.user.permissions).length > 0
            );
        },
        userHasAllPermission: (state) => (permissions) => {
            return (
                state.user &&
                Array.isArray(permissions) &&
                state.user.permissions &&
                _.intersection(permissions, state.user.permissions).length === permissions.length
            );
        },
        getStoreById: (state) => (id) => {
            return state.stores.filter((store) => {
                return store.id === id;
            })[0];
        },
        getUser: (state) => {
            return state.user;
        },
        getStores: (state) => {
            return state.stores;
        },
    },
    mutations: {
        setStores(state, stores) {
            state.stores = stores;
        },
        SET_USER(state, user) {
            state.user = user;
        },
    },
};
