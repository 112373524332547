<template>
    <FormRoot>
        <Alert color="warning mb-3" icon="exclamation-triangle">
            You can change the PIN for an issued card using this form. However, depending on the region the card is used
            in, the new PIN might not be immediately usable.
        </Alert>
        <Field
            :form.sync="form"
            type="text"
            name="pin"
            placeholder="****"
            hint="Set a PIN code for this card (4 digits)"
        />
        <FormActions>
            <Submit class="float-right" :disabled="disableSubmit" @click="onSubmit">Update PIN code</Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot } from '@tech_hexeko/design-system'
    import { Alert, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import Generator from '@/mixins/Generator'

    export default {
        mixins: [Forms, Generator],
        components: {
            Alert,
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        data() {
            return {
                fields: {
                    encrypted_pin: null,
                    pin: null,
                },
                messageSuccess: 'The PIN has been updated',
            }
        },
        props: {
            id: {
                type: String,
                required: true,
            },
        },
        computed: {
            disableSubmit() {
                return !this.form.submittable || !this.form.encrypted_pin || !this.form.pin || this.form.pin.length < 4
            },
        },
        methods: {
            handleEncryptPin() {
                this.encryptPin(this.form.pin).then((encrypted_pin) => {
                    this.form.encrypted_pin = encrypted_pin
                })
            },
            loadData() {
                return this.$api.cards.show(this.id)
            },
            submit() {
                let data = this.$data.form.notNullData()
                if (data) delete data.pin
                return this.$api.cards.updatePin(this.id, data)
            },
        },
        watch: {
            'form.pin': {
                handler(pin) {
                    if (pin && pin.length >= 4) this.handleEncryptPin()
                },
            },
        },
    }
</script>
