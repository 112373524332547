<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
        <path
            d="M14.37 1.968a.465.465 0 0 0-.651.666l1.713 1.675v2.18c0 .042.016.082.045.11l1.592 1.579v3.47c0 .4-.111 1.706-1.55 1.706-1.44 0-1.552-1.306-1.552-1.706V9.476c0-1.895-1.058-2.524-2.258-2.716V.93a.93.93 0 0 0-.93-.93H2.607a.93.93 0 0 0-.93.93v15.152H.62a.62.62 0 0 0-.62.62v1.143c0 .085.07.155.155.155h13.076c.086 0 .155-.07.155-.155v-1.142a.62.62 0 0 0-.62-.621h-1.057V7.703c.97.192 1.327.716 1.327 1.773v2.172c0 .979.523 2.637 2.482 2.637 1.96 0 2.482-1.658 2.482-2.637V5.714a.466.466 0 0 0-.14-.333l-3.49-3.413Zm-3.827 6.254c0 .085-.07.155-.155.155h-7.39a.155.155 0 0 1-.155-.155v-6.15c0-.085.07-.154.155-.154h7.39c.086 0 .155.07.155.155v6.149Z"
            fill="#000"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-fuel-expenses',
    }
</script>
