<template>
    <Datatable
        name="Licenses"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
        :hover="false"
        @search="fetchData"
        searchClass="d-none"
    >
        <template v-slot:thead>
            <tr>
                <th>Reference</th>
                <th>Type</th>
                <th>Created at</th>
                <th>Used at</th>
                <th>Metadata</th>
                <th>Status</th>
                <th></th>
            </tr>
        </template>
        <tr v-for="(license, index) in licenses" :key="'license-' + index" valign="middle">
            <td>{{ license.reference }}</td>
            <td>{{ license.license_type }}</td>
            <td>{{ license.created_at | formatDate }}</td>
            <td>{{ license.used_at | formatDate }}</td>
            <td>
                <code v-if="Object.keys(license.metadata).length > 0" class="d-block text-muted">
                    <template v-for="(metadata, key) in license.metadata"> {{ key }}: {{ metadata }}<br /> </template>
                </code>
            </td>
            <td><InvoiceStatus :license="license"></InvoiceStatus></td>
            <td v-if="$store.getters.userHasPermission('manageLicenses')">
                <Spinner v-if="loadingDelete" />
                <a
                    href="#"
                    @click.prevent
                    is="confirm"
                    tag="a"
                    class="text-danger"
                    @confirm="deleteLicense(license.id)"
                    title="Are you sure you want to delete ?"
                    v-else
                >
                    <Icon icon="trash-alt" />
                </a>
            </td>
        </tr>
    </Datatable>
</template>

<script>
    import { Alerts, Boolean, Datatable, Spinner } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import InvoiceStatus from '@/components/Licenses/status'

    export default {
        name: 'Licenses',
        components: {
            Datatable,
            InvoiceStatus,
            Spinner,
        },
        props: {
            group: {
                type: Number,
                required: true,
            },
            hideActions: Boolean,
            params: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                licenses: [],
                loading: true,
                loadingDelete: false,
                pagination: { current_page: 1 },
            }
        },
        methods: {
            fetchData() {
                if (!this.group) return
                this.loading = true
                this.$api.groups
                    .getGroupLicenses(
                        this.group,
                        this.mergeParams({
                            page: this.pagination.current_page,
                        })
                    )
                    .then((response) => {
                        this.licenses = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
                    .catch((error) => {
                        let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            deleteLicense(id) {
                this.loadingDelete = true
                this.$api.licenses
                    .delete(id)
                    .catch((error) => {
                        if (error && error.response) Alerts.notificationError(error.response.data.message)
                    })
                    .finally(() => {
                        this.pagination.current_page = 1
                        this.loadingDelete = false
                        this.fetchData()
                        Alerts.notificationSuccess('License deleted')
                    })
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
