<template>
    <Datatable
        name="Booking Requests"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
    >
        <template v-slot:filters>
            <Button
                class="me-1 mb-1"
                @click="filterByStatus(index)"
                :color="status === index ? avStatus.color : 'secondary'"
                small
                v-for="(avStatus, index) in statuses"
                :key="index"
            >
                {{ avStatus.name }}
            </Button>
        </template>
        <template v-slot:thead>
            <tr>
                <th>ID</th>
                <th>Date</th>
                <th>User</th>
                <th>Status</th>
                <th></th>
            </tr>
        </template>
        <template v-slot:default>
            <tr v-for="(request, index) in bookingRequests" @click="open(request)" :key="index">
                <td>
                    <code>{{ request.id }}</code>
                </td>
                <td>{{ request.created_at | formatDate }}</td>
                <td>
                    <div v-if="request.user">{{ request.user.name }}</div>
                </td>
                <td><BookingRequestStatus :status="request.status" /></td>
                <td class="text-right"><Icon icon="angle-right" /></td>
            </tr>
        </template>
    </Datatable>
</template>

<script>
    import { Datatable, Button, Boolean } from '@tech_hexeko/design-system'
    import BookingRequestStatus from '@/components/BookingRequests/status'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import statuses from './statuses'
    let _ = require('lodash')

    export default {
        name: 'BookingRequests',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                timer: null,
                loading: true,
                search: null,
                status: null,
                pagination: {
                    current_page: 1,
                },
                bookingRequests: [],
                statuses: statuses,
            }
        },
        components: {
            Datatable,
            BookingRequestStatus,
            Button,
        },
        mounted() {
            this.enableAutoRefresh()
            this.loading = true
            this.fetchData().then(() => {
                this.loading = false
            })
        },
        destroyed() {
            this.disableAutoRefresh()
        },
        methods: {
            enableAutoRefresh() {
                this.timer = setInterval(() => {
                    this.fetchData(this.search)
                }, 15 * 1000)
            },
            disableAutoRefresh() {
                clearInterval(this.timer)
            },
            fetchData(search) {
                this.search = search
                let params = this.mergeParams({
                    search: this.search,
                    statuses: [this.status],
                    page: this.pagination.current_page,
                    order_by: 'created_at',
                    sort_by: 'desc',
                    includes: ['user'],
                })
                return this.$api.bookings.getBookingRequests(params).then((response) => {
                    this.bookingRequests = response.data
                    this.pagination = response.meta.pagination
                })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(_.cloneDeep(this.params), params))
            },
            open(bookingRequest) {
                let panel = this.openPanel('BookingRequestPanel', bookingRequest.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
            filterByStatus(status) {
                this.status = this.status === status ? null : status
                this.fetchData()
            },
        },
    }
</script>
