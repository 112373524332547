<template>
    <Dropdown
        :color="metaSelected.selectedLength > 0 ? 'primary' : 'secondary'"
        :title="metaSelected.selectedLength + ' selected'"
        icon="check-circle"
        position="right"
        class="float-left me-1"
        small
    >
        <DropdownItem @click="selectAll" icon="plus-circle" :disabled="metaSelected.pageNotSelectedLength === 0"
            >Add {{ metaSelected.pageNotSelectedLength }} item(s) of page</DropdownItem
        >
        <DropdownItem @click="unselectAll" icon="minus-circle" :disabled="metaSelected.pageSelectedLength === 0"
            >Remove {{ metaSelected.pageSelectedLength }} item(s) of page</DropdownItem
        >
        <DropdownItem @confirm="reset" :icon="['far', 'circle']" :disabled="metaSelected.selectedLength === 0" confirm
            >Reset all</DropdownItem
        >
        <template v-if="$slots.default && metaSelected.selectedLength > 0">
            <li><hr class="dropdown-divider" /></li>
            <slot></slot>
        </template>
    </Dropdown>
</template>

<script>
    import { Dropdown, DropdownItem } from '@tech_hexeko/design-system'

    export default {
        props: {
            metaSelected: Object,
        },
        components: {
            Dropdown,
            DropdownItem,
        },
        methods: {
            selectAll() {
                this.$emit('selectAll')
            },
            unselectAll() {
                this.$emit('unselectAll')
            },
            reset() {
                this.$emit('reset')
            },
        },
    }
</script>
