<template>
    <Badge :color="getcolor" :large="large" :icon="getIcon">{{ getName }}</Badge>
</template>
<script>
    import { Badge } from '@tech_hexeko/design-system'
    let statuses = {
        pending: {
            name: 'Pending',
            color: 'warning',
            icon: 'clock',
        },
        paid: {
            name: 'Paid',
            color: 'success',
            icon: 'receipt',
        },
        performed: {
            name: 'Performed',
            color: 'success',
            icon: 'check-circle',
        },
        failed: {
            name: 'Failed',
            color: 'danger',
            icon: 'times-circle',
        },
        canceled: {
            name: 'Canceled',
            color: 'secondary',
            icon: 'times-circle',
        },
        sent: {
            name: 'Sent',
            color: 'warning',
            icon: 'paper-plane',
        },
        processing: {
            name: 'Processing',
            color: 'warning',
            icon: 'hourglass-half',
        },
        received_and_processed: {
            name: 'Received and processed',
            color: 'success',
            icon: 'clipboard-check',
        },
        received: {
            name: 'Received',
            color: 'success',
            icon: 'envelope',
        },
    }

    export default {
        name: 'PayoutStatus',
        props: {
            status: Object,
            large: Boolean,
        },
        computed: {
            getcolor() {
                return statuses[this.status?.name]?.color ? statuses[this.status?.name].color : 'secondary'
            },
            getIcon() {
                return statuses[this.status?.name]?.icon ? statuses[this.status?.name].icon : 'times-circle'
            },
            getName() {
                if (statuses[this.status?.name]?.name) {
                    return statuses[this.status?.name]?.name
                }
                if (this.status?.name) {
                    return this.status?.name
                }
                return 'No status'
            },
        },
        components: {
            Badge,
        },
    }
</script>
