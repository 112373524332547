<template>
    <Panel title="Expense" :loading="loading" @closePanel="$emit('closePanel', { refresh: false })">
        <div v-if="!loading">
            <ExpenseStatus :expense="expense" class="mb-1 me-1" />
            <Badge class="mb-1" color="secondary">{{ expense.refund_expenses.length }} refund(s)</Badge>

            <Sheet name="Details" class="mt-1">
                <Row name="Date">{{ expense.created_at | formatDate('DD/MM/YYYY') }}</Row>
                <Row name="User" v-if="expense.user">
                    <a href="#" @click.prevent="openUser(expense.user)"><Icon icon="user" /> {{ expense.user.name }}</a>
                </Row>
                <Row name="Partner" v-if="expense.partner">
                    <div>
                        <a href="#" @click.prevent="openPartner(expense.partner)">{{
                            expense.partner.name[$store.state.lang.displayLanguage]
                        }}</a>
                    </div>
                </Row>
                <Row name="Source">
                    <Payable :type="expense.source_type" :payable="expense.source" clickable />
                </Row>
                <Row name="Types">
                    <span class="me-2" v-for="type in expense.types" :key="type.id">
                        <span>{{ type.name[$store.state.lang.displayLanguage] }}</span>
                    </span>
                </Row>
                <Row name="Last update">{{ expense.updated_at | formatDate }}</Row>
            </Sheet>

            <Sheet name="Amounts" class="mt-1">
                <Row name="Amount with taxes">{{ expense.amount | currency }}</Row>
                <Row name="Amount without taxes"
                    >{{ expense.amount_without_taxes | currency }} ({{ expense.vat_rate }} %)</Row
                >
                <Row name="Amount to payout">{{ expense.amount_to_payout | currency }}</Row>
                <Row name="Commission (benefit)">{{ expense.commission | currency }}</Row>
                <Row name="Amount per type">
                    <div v-for="type in expense.transaction_types">
                        {{ type.amount }} € for {{ type.types.join(', ') }}
                    </div>
                </Row>
            </Sheet>

            <ID name="Hexeko" :value="expense.id" />
        </div>
    </Panel>
</template>

<script>
    import { Panel, Sheet, Row, ID, Badge } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import ExpenseStatus from '@/components/Expenses/status.vue'
    import Payable from '@/components/PaymentIntents/Payable.vue'

    export default {
        name: 'ExpensePanel',
        props: {
            id: String,
        },
        data() {
            return {
                loading: true,
                expense: null,
            }
        },
        components: {
            Payable,
            Panel,
            Sheet,
            Row,
            ExpenseStatus,
            ID,
            Badge,
        },
        mixins: [Modals],
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.loading = true
                // Load booking
                this.$api.expenses
                    .show(this.id, {
                        includes: ['user', 'partner', 'transaction_types', 'types', 'refund_expenses'],
                    })
                    .then((expense) => {
                        this.expense = expense
                        this.loading = false
                    })
            },
            openUser(user) {
                this.openPanel('userPanel', user.id)
            },
            openPartner(partner) {
                this.openPanel('partnerPanel', partner.id)
            },
        },
    }
</script>
