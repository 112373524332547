<template>
    <Card noPadding>
        <Table :loading="loading" striped hover class="mb-0">
            <template v-for="type in types">
                <TypeRow :type="type" @click="open(type)" />
                <template v-for="children in type.children">
                    <TypeRow :type="children" @click="open(children)" child />
                </template>
            </template>
            <template v-slot:actions v-if="$store.getters.userHasPermission('manageTypes')">
                <Button small @click="openModal('createType')" icon="plus">Create type</Button>
                <Modal v-if="isModalOpen('createType')" @close="closeModal('createType')" title="Create type">
                    <UpdateType @submitted="typeSubmitted" />
                </Modal>
            </template>
        </Table>
    </Card>
</template>

<script>
    import { Table, Modal, Button, Boolean, Card } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import UpdateType from '@/components/Types/UpdateType'
    import TypeRow from '@/components/Types/TypeRow'

    export default {
        name: 'Types',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                pagination: {
                    current_page: 1,
                },
                types: [],
            }
        },
        components: {
            Table,
            UpdateType,
            Modal,
            Button,
            Card,
            TypeRow,
        },
        mixins: [Modals],
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.$api.types
                    .get(
                        this.mergeParams({
                            search: search,
                            page: this.pagination.current_page,
                            depth: 1,
                        })
                    )
                    .then((response) => {
                        this.types = response.data
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            open(type) {
                let panel = this.openPanel('TypePanel', type.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData()
                    }
                })
            },
            typeSubmitted() {
                this.closeModal('createType')
                this.fetchData()
            },
        },
    }
</script>
