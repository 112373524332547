<template>
    <Datatable name="Tokens" search-class="d-none" :hover="true" :loading="loading">
        <template v-slot:thead>
            <tr>
                <th>ID</th>
                <th>Number</th>
                <th>Wallet provider</th>
                <th>Created at</th>
                <th>Status</th>
                <th></th>
            </tr>
        </template>
        <tr v-for="token in tokens" :key="token.id" @click="openToken(token)">
            <td>
                <code>{{ token.id }}</code>
            </td>
            <td><Icon icon="credit-card" /> •••• {{ token.last_four }}</td>
            <td>
                {{ token.wallet_provider ? token.wallet_provider : 'Unknown' }}
            </td>
            <td>{{ token.created_at | formatDate }}</td>
            <td><CardTokenStatus :status="token.status" /></td>
            <td align="right">
                <Icon icon="angle-right" />
            </td>
        </tr>
    </Datatable>
</template>
<script>
    import { Datatable, Alerts } from '@tech_hexeko/design-system'
    import CardTokenStatus from './CardTokenStatus.vue'
    export default {
        name: 'CardTokens',
        props: {
            card: String,
        },
        data() {
            return {
                loading: false,
                updateLoading: false,
                tokens: [],
            }
        },
        components: {
            Datatable,
            CardTokenStatus,
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.loading = true
                this.$api.cards
                    .getTokens(this.card)
                    .then((res) => {
                        this.tokens = res.data
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response && error.response.data
                                ? error.response.data.message
                                : 'An error occurred while trying to get card tokens'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            openToken(token) {
                let panel = this.openPanel('TokenPanel', {
                    card: this.card,
                    token: token.id,
                })
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData()
                    }
                })
            },
        },
    }
</script>
