<template>
    <Badge v-if="statusType[status]" :color="statusType[status].color" :icon="statusType[status].icon">
        {{ statusType[status].text }}
    </Badge>
</template>
<script>
    import { Badge } from '@tech_hexeko/design-system'

    export default {
        name: 'PaymentStatus',
        props: {
            status: String,
        },
        data() {
            return {
                statusType: {
                    pending: {
                        color: 'warning',
                        icon: 'pause-circle',
                        text: 'Pending',
                    },
                    failed: {
                        color: 'danger',
                        icon: 'times-circle',
                        text: 'Failed',
                    },
                    succeeded: {
                        color: 'success',
                        icon: 'check-circle',
                        text: 'Success',
                    },
                    none: {
                        color: 'secondary',
                        icon: 'exclamation-circle',
                        text: 'Unknown',
                    },
                },
            }
        },
        components: {
            Badge,
        },
    }
</script>
