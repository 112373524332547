<template>
    <Datatable
        name="Payment Intents"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
    >
        <template v-slot:filters>
            <Dropdown class="me-1 mb-1" color="secondary" title="Filter by payable type" small>
                <li v-for="(payable, technical_name) in payableTypes" :key="technical_name">
                    <a
                        href="#"
                        class="dropdown-item text-xs"
                        :class="payable_type === technical_name ? 'bg-primary text-white' : 'bg-white'"
                        @click.prevent="filterByPayableType(technical_name)"
                    >
                        {{ payable.name }}
                    </a>
                </li>
            </Dropdown>
            <Dropdown class="me-1 mb-1" color="secondary" title="Filter by status" small>
                <li v-for="status in statuses" :key="'filter-' + status.value">
                    <a
                        href="#"
                        class="dropdown-item text-xs"
                        :class="status_selected === status.value ? 'bg-primary text-white' : 'bg-white'"
                        @click.prevent="filterByStatus(status.value)"
                    >
                        {{ status.text }}
                    </a>
                </li>
            </Dropdown>
            <Button
                class="me-1"
                @click="toggleFilterFullyDebited"
                :color="fully_debited ? 'success' : fully_debited === false ? 'danger' : 'secondary'"
                small
            >
                Fully debited
            </Button>
        </template>
        <template v-slot:thead>
            <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Amount</th>
                <th>User</th>
                <th>Payable</th>
                <th>Status</th>
                <th></th>
            </tr>
        </template>
        <tr
            v-for="(paymentIntent, index) in paymentIntents"
            :key="'paymentIntent' + index"
            @click="open(paymentIntent)"
        >
            <td>
                <code>{{ paymentIntent.id }}</code>
            </td>
            <td>{{ paymentIntent.transaction_at | formatDate }}</td>
            <td>{{ paymentIntent.amount | currency }}</td>
            <td><Icon icon="user" /> {{ paymentIntent.payer.name }}</td>
            <td><Payable :type="paymentIntent.payable_type" :payable="paymentIntent.payable" /></td>
            <td><PaymentIntentStatus v-if="paymentIntent.status" :status="paymentIntent.status" /></td>
            <td class="text-end"><Icon icon="angle-right" /></td>
        </tr>
    </Datatable>
</template>

<script>
    import { Datatable, Button, Dropdown, Boolean } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import PaymentIntentStatus from './status'
    import Payables from './payables'
    import Payable from '@/components/PaymentIntents/Payable'
    import { formatBoolean } from '@/utils/query'

    export default {
        name: 'PaymentIntents',
        mixins: [Modals],
        components: {
            Datatable,
            Dropdown,
            Button,
            PaymentIntentStatus,
            Payable,
        },
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                search: null,
                fully_debited: null,
                overrideParams: false,
                payable_type: null,
                paymentIntents: [],
                payableTypes: Payables,
                statuses: [
                    { value: 'payment_successful', text: 'Successful' },
                    { value: 'payment_verified', text: 'Verified' },
                    { value: 'payment_processing', text: 'Processing' },
                    { value: 'payment_required_action', text: 'Required action' },
                    { value: 'payment_required_payment_method', text: 'Required payment action' },
                    { value: 'payment_failed', text: 'Failed' },
                ],
                status_selected: null,
                pagination: {
                    current_page: 1,
                },
            }
        },
        methods: {
            fetchData(search) {
                this.search = search
                this.loading = true
                const payload = this.mergeParams({
                    fully_debited: formatBoolean(this.fully_debited),
                    payable_type: !this.overrideParams ? this.params.payable_type : this.payable_type,
                    ...(this.search && { search: this.search }),
                    status: this.status_selected,
                    includes: ['payer', 'payable', 'status'],
                    ...(this.params.user && { payer: this.params.user }),
                    page: this.pagination.current_page,
                })
                this.$api.paymentIntents
                    .get(payload)
                    .then((response) => {
                        this.paymentIntents = response.data
                        this.pagination = response.meta.pagination
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            filterByStatus(status) {
                this.status_selected = this.status_selected === status ? null : status
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            filterByPayableType(payable) {
                this.overrideParams = true
                this.payable_type = this.payable_type === payable ? null : payable
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            toggleFilterFullyDebited() {
                if (this.fully_debited === null) this.fully_debited = true
                else if (this.fully_debited === true) this.fully_debited = false
                else this.fully_debited = null
                this.fetchData(this.search)
            },
            open(paymentIntent) {
                this.openPanel('PaymentIntentPanel', paymentIntent.id)
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
