<template>
    <Panel title="Card Token" :loading="loading" @closePanel="closePanel">
        <Sheet name="Informations" class="pt-2">
            <Row name="Status" v-if="tokenStatus">
                <CardTokenStatus :status="tokenStatus" />
            </Row>
            <Row name="Wallet provider" v-if="walletProvider">
                {{ walletProvider }}
            </Row>
            <Row name="Phone" v-if="phoneNumber">
                <a :href="'tel:' + phoneNumber">+{{ phoneNumber }}</a>
            </Row>
            <Row name="Card number source" v-if="cardNumberSource">
                {{ cardNumberSource }}
            </Row>
            <Row name="Last four">{{ lastFour }}</Row>
            <Row name="Device type">
                {{ deviceType }}
            </Row>
            <Row name="Device name">
                {{ deviceName }}
            </Row>
            <Row name="Reason codes" v-if="cardNumberSource">
                {{ reasonCodes }}
            </Row>
            <Row name="Token risk score" v-if="tokenRiskScore">
                {{ tokenRiskScore }}
            </Row>
            <Row name="Account trust score" v-if="accountTrustScore">
                {{ accountTrustScore }}
            </Row>
            <Row name="Device trust score" v-if="deviceTrustScore">
                {{ deviceTrustScore }}
            </Row>
            <Row v-if="token" name="Created at">{{ token.created_at | formatDate }}</Row>
        </Sheet>
        <div v-if="token" class="mt-4">
            <ID name="Token" :value="token.id" />
        </div>
        <template v-slot:actions v-if="$store.getters.userHasPermission('manageCards')">
            <!-- Actions -->
            <Dropdown small title="Actions" position="right" v-if="!loading">
                <DropdownItem
                    v-if="token.status == 'suspended'"
                    @click="updateStatus('active')"
                    icon="plus"
                    :loading="updateLoading"
                >
                    Activate
                </DropdownItem>
                <DropdownItem v-else icon="pause" :loading="updateLoading" @click="updateStatus('suspended')">
                    Suspend
                </DropdownItem>
                <DropdownItem
                    v-if="token.status != 'deleted'"
                    @confirm="updateStatus('deleted')"
                    confirm
                    icon="trash-alt"
                    :disabled="updateLoading"
                >
                    Delete
                </DropdownItem>
            </Dropdown>
        </template>
    </Panel>
</template>

<script>
    import { Alerts, Panel, Sheet, Row, Dropdown, DropdownItem, ID } from '@tech_hexeko/design-system'
    import CardTokenStatus from './CardTokenStatus.vue'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    export default {
        name: 'TokenPanel',
        mixins: [Modals],
        components: {
            Panel,
            Sheet,
            Row,
            Dropdown,
            DropdownItem,
            CardTokenStatus,
            ID,
        },
        data() {
            return {
                loading: true,
                updateLoading: false,
                token: null,
            }
        },
        props: {
            id: Object,
        },
        methods: {
            closePanel(args) {
                this.$emit('closePanel', args)
            },
            fetchData() {
                this.loading = true
                this.$api.cards
                    .getToken(this.id.card, this.id.token)
                    .then((res) => {
                        this.token = res
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response && error.response.data
                                ? error.response.data.message
                                : 'An error occurred while trying to get card token'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            updateStatus(status) {
                this.updateLoading = true
                this.$api.cards
                    .updateToken(this.id.card, this.id.token, { status })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response && error.response.data
                                ? error.response.data.message
                                : 'An error occurred while trying to update card token status'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.closePanel({ refresh: true })
                        this.updateLoading = false
                    })
            },
        },
        computed: {
            walletProvider() {
                return this.token?.wallet_provider || 'Unknown'
            },
            tokenStatus() {
                return this.token?.status || ''
            },
            phoneNumber() {
                return this.token?.network_data?.device?.phone_number || ''
            },
            tokenRiskScore() {
                return this.token?.network_data?.visa?.token_risk_score || ''
            },
            accountTrustScore() {
                return this.token?.network_data?.wallet_provider?.account_trust_score || ''
            },
            deviceTrustScore() {
                return this.token?.network_data?.wallet_provider?.device_trust_score || ''
            },
            cardNumberSource() {
                return this.token?.network_data?.wallet_provider?.card_number_source || ''
            },
            reasonCodes() {
                return this.token?.network_data?.wallet_provider?.reason_codes?.length > 0
                    ? this.token.network_data.wallet_provider.reason_codes.join(', ')
                    : ''
            },
            deviceType() {
                return this.token?.network_data?.device?.type || ''
            },
            deviceName() {
                return this.token?.network_data?.device?.name || ''
            },
            lastFour() {
                const lastFour = this.token?.last_four
                return lastFour ? `•••• ${lastFour}` : ''
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
