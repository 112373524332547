<template>
    <Panel title="Quote" :loading="loading" @closePanel="$emit('closePanel')">
        <Tabs v-if="quote" @selectTab="handleSelectTab">
            <!-- Quote -->
            <Tab name="Details" :selected="true">
                <div class="mt-1">
                    <div class="list-group mb-3">
                        <a
                            href="#"
                            class="list-group-item list-group-item-light list-group-item-action"
                            target="_blank"
                            v-for="(file, index) in quote.importer_ids"
                            :key="file"
                            @click.prevent="downloadQuote(file)"
                        >
                            <Icon icon="download" class="me-2" /> Download quote file {{ index + 1 }}
                        </a>
                    </div>
                </div>
                <QuoteStatus class="mb-2" :quote="quote" />
                <div class="progress-container">
                    <Steps :steps="stepsArray" :active="activeSteps" />
                </div>
                <Sheet name="Quote" class="mb-2">
                    <Row name="Date">{{ quote.created_at | formatDate }}</Row>
                    <Row name="Reference" v-if="quote.reference">{{ quote.reference }}</Row>
                    <Row name="Total">{{ quote.amount_total | currency }}</Row>
                    <Row v-if="quote.metadata" name="Metadata">
                        <RawOutput :item="quote.metadata" />
                    </Row>
                    <Row v-if="!!quote.finalized_at" name="Finalized at">{{ quote.finalized_at | formatDate }}</Row>
                    <Row v-if="!!quote.accepted_at" name="Accepted at">{{ quote.accepted_at | formatDate }}</Row>
                    <template v-if="!!quote.processing_started_at">
                        <Row name="Processed started at">{{
                            quote.processing_started_at | formatDate('DD/MM/YYYY HH:mm:ss')
                        }}</Row>
                        <Row name="Processed finished at">
                            <Spinner v-if="!quote.processing_finished_at" />
                            <span v-else>{{ quote.processing_finished_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                        </Row>
                    </template>
                </Sheet>

                <Sheet name="Billing account" v-if="billingAccount">
                    <Row name="Name"
                        ><a href="#" @click.prevent="openBillingAccount(billingAccount)">{{
                            billingAccount.name
                        }}</a></Row
                    >
                    <Row name="City">{{ billingAccount.city }} {{ billingAccount.country_iso }}</Row>
                </Sheet>

                <Sheet name="Invoice" v-if="invoice">
                    <Row name="ID"
                        ><a href="#" @click.prevent="openInvoice(invoice)">{{ invoice.id }}</a></Row
                    >
                    <Row name="Date">{{ invoice.created_at | formatDate }}</Row>
                    <Row name="Total">{{ invoice.total | currency }}</Row>
                    <Row name="Status"><InvoiceStatus :invoice="invoice" /></Row>
                </Sheet>
                <ID name="Hexeko" :value="quote.id" />
            </Tab>

            <!-- LineRow -->
            <Tab name="Lines">
                <Table striped borderless :loading="loadingGroupedLines">
                    <template v-slot:thead>
                        <tr>
                            <th width="60%" colspan="2">Product</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Amount</th>
                        </tr>
                    </template>
                    <LineRow
                        v-for="(line, index) in lines"
                        :icon="line.type"
                        :quantity="line.quantity"
                        :title="line.product"
                        :amount="line.amount_total"
                        :amount_unit="line.amount_unit ? line.amount_unit : line.amount_total"
                        :key="'line-' + index"
                    >
                        <code class="text-muted">{{ line.slug }}</code>
                    </LineRow>
                </Table>
            </Tab>

            <!-- Lines detail -->
            <Tab name="Lines detail">
                <Datatable
                    name="Lines detail"
                    striped
                    borderless
                    @search="fetchLinesDetail"
                    :pagination.sync="pagination"
                    :loading="loadingLinesDetail"
                    hideActions
                    :hover="false"
                >
                    <template v-slot:thead>
                        <tr>
                            <th width="60%" colspan="2">Product</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Amount</th>
                        </tr>
                    </template>
                    <LineRow
                        v-for="(line, index) in linesDetail"
                        :icon="line.type"
                        :title="line.product.description"
                        :quantity="line.quantity"
                        :amount="line.amount"
                        :amount_unit="line.amount"
                        :key="'lineDetail-' + index"
                    >
                        <div v-if="line.metadata && line.metadata.start_date">
                            Start date: {{ line.metadata.start_date | formatDate('DD/MM/YYYY') }}
                        </div>
                        <div v-if="line.metadata && line.metadata.end_date">
                            End date: {{ line.metadata.end_date | formatDate('DD/MM/YYYY') }}
                        </div>
                        <div v-if="line.user">
                            <a href="#" @click.prevent="openUser(line.user)" class="text-muted">
                                <Icon icon="user" class="me-1" />
                                <span v-if="line.user.first_name || line.user.last_name"
                                    >{{ line.user.first_name }} {{ line.user.last_name }}</span
                                >
                                <span v-else>User {{ line.user.id }}</span>
                            </a>
                        </div>
                        <div v-if="line.metadata && line.metadata.external_id">
                            External ID: {{ line.metadata.external_id }}
                        </div>
                    </LineRow>
                </Datatable>
            </Tab>
        </Tabs>

        <!-- Actions -->
        <template v-slot:actions v-if="$store.getters.userHasPermission('manageQuotes')">
            <Dropdown v-if="quote && (!quote.accepted_at || !quote.invoice)" small title="Actions" position="right">
                <DropdownItem v-if="!quote.accepted_at" confirm @confirm="acceptQuote" icon="check-circle"
                    >Accept quote</DropdownItem
                >
                <DropdownItem v-if="!quote.invoice" confirm @confirm="deleteQuote" icon="trash-alt" class="text-danger"
                    >Delete</DropdownItem
                >
            </Dropdown>
        </template>
    </Panel>
</template>

<script>
    import {
        Alerts,
        Panel,
        Tabs,
        Tab,
        Sheet,
        Row,
        ID,
        Dropdown,
        DropdownItem,
        Table,
        Datatable,
        Steps,
    } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import QuoteStatus from './status'
    import InvoiceStatus from '@/components/Invoices/status'
    import LineRow from '@/components/layout/LineRow'
    import RawOutput from '@/components/RawOutput'
    import { downloadFile } from '@tech_hexeko/shared/src/utils/query'

    export default {
        name: 'QuotePanel',
        props: {
            id: String,
        },
        data() {
            return {
                loading: true,
                quote: null,
                lines: [],
                linesDetail: [],
                loadingLinesDetail: true,
                loadingGroupedLines: true,
                pagination: {
                    total_pages: 0,
                    current_page: 1,
                },
                invoice: null,
                billingAccount: null,
                search: null,
                stepsArray: ['Created', 'Finalized', 'Accepted', 'Processed'],
            }
        },
        computed: {
            activeSteps: function () {
                if (this.quote.is_processed) {
                    return 4
                }
                if (this.quote.accepted_at) {
                    return 3
                }
                if (this.quote.finalized_at) {
                    return 2
                }
                return 1
            },
        },
        mounted() {
            this.fetchData()
        },
        mixins: [Modals],
        methods: {
            acceptQuote() {
                this.loading = true
                this.$api.billing.quotes
                    .accept(this.id)
                    .then(() => {
                        setTimeout(() => {
                            this.fetchData()
                        }, 3000)
                    })
                    .catch((error) => {
                        let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            deleteQuote() {
                this.$api.billing.quotes
                    .delete(this.id)
                    .then(() => {
                        this.$emit('closePanel', { refresh: true })
                    })
                    .catch((error) => {
                        let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
            },
            fetchData() {
                this.loading = true

                this.$api.billing.quotes.show(this.id, { includes: ['invoice'] }).then((quote) => {
                    this.quote = quote
                    // Fetch lines
                    this.fetchGroupedLines()
                    // Fetch lines detail
                    this.fetchLinesDetail(this.search)
                    // Fetch BA
                    this.fetchBillingAccount(quote.billing_account_id)
                    // Fetch Invoice
                    if (quote.invoice_id) {
                        this.fetchInvoice(quote.invoice_id)
                    }
                    // stop loading
                    this.loading = false
                })
            },
            fetchGroupedLines() {
                this.loadingGroupedLines = true
                this.$api.billing.quotes
                    .getGroupedLines(this.id)
                    .then((response) => {
                        this.lines = response.data
                    })
                    .finally(() => {
                        this.loadingGroupedLines = false
                    })
            },
            fetchLinesDetail(search) {
                this.loadingLinesDetail = true
                this.search = search
                this.$api.billing.quotes
                    .getLines(this.id, {
                        includes: ['user'],
                        per_page: 15,
                        page: this.pagination.current_page,
                        ...(this.search && { search: this.search }),
                    })
                    .then((response) => {
                        this.linesDetail = response.data
                        this.pagination = response.meta.pagination
                    })
                    .finally(() => {
                        this.loadingLinesDetail = false
                    })
            },
            fetchInvoice(id) {
                this.$api.billing.invoices.show(id).then((invoice) => {
                    this.invoice = invoice
                })
            },
            fetchBillingAccount(id) {
                this.$api.billing.billingAccounts.show(id).then((billingAccount) => {
                    this.billingAccount = billingAccount
                })
            },
            handleSelectTab(tab) {
                // Reset search when switching to Lines detail tab if necessary
                if (tab.name === 'Lines detail' && this.search) this.fetchLinesDetail()
            },
            openInvoice(invoice) {
                this.openPanel('InvoicePanel', invoice.id)
            },
            openBillingAccount(account) {
                this.openPanel('BillingAccountPanel', account.id)
            },
            openUser(user) {
                this.openPanel('UserPanel', user.id)
            },
            async downloadQuote(id) {
                this.downloadLoading = id
                try {
                    const getFile = await this.$importerApi.files.downloadAdmin(id)
                    downloadFile(getFile, `${this.quote.id}`)
                } catch (error) {
                    let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                    Alerts.notificationError(errorTxt)
                } finally {
                    this.downloadLoading = false
                }
            },
        },
        components: {
            InvoiceStatus,
            Panel,
            Tabs,
            Tab,
            Sheet,
            RawOutput,
            Row,
            ID,
            Dropdown,
            DropdownItem,
            QuoteStatus,
            Table,
            Datatable,
            Steps,
            LineRow,
        },
    }
</script>
<style lang="css" scoped>
    .progress-container {
        margin: 1rem auto 2.6rem auto;
        width: 82%;
    }
</style>
