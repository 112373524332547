<template>
    <svg viewBox="0 0 29.699 24" fill="#000" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M25.2 3.6a.833.833 0 0 0-.834.834v2.833h-2.833a.833.833 0 0 0 0 1.667h2.833v2.833a.833.833 0 0 0 1.667 0V8.934h2.833a.833.833 0 0 0 0-1.667h-2.833V4.434a.833.833 0 0 0-.834-.833z"
        />
        <path
            d="M12.007 0C9.29 0 7.073 2.497 7.073 5.547s2.217 5.547 4.934 5.547 4.934-2.497 4.934-5.547S14.724 0 12.007 0zm0 2.12c1.698 0 3.06 1.52 3.06 3.427s-1.362 3.428-3.06 3.428c-1.699 0-3.06-1.52-3.06-3.428 0-1.907 1.361-3.428 3.06-3.428zm0 9.91c-2.622 0-5.459.506-7.745 1.51-1.143.503-2.153 1.124-2.92 1.933C.035 17.02-.047 18.7.016 20.475c.137 1.948 1.54 2.853 3.154 3.179 1.06.206 2.447.31 4.325.311 3.132.1 6.322-.045 9.01-.062 1.457-.047 3.002-.023 4.34-.265 1.8-.382 3.066-1.336 3.154-3.163.066-1.796-.167-3.748-1.327-5.002-.766-.81-1.777-1.43-2.92-1.932-2.596-.914-5.362-1.494-7.744-1.512zm0 1.994c2.355 0 4.988.485 6.932 1.34.973.427 1.771.943 2.28 1.48.886 1.047.825 2.256.78 3.553-.029.971-.923 1.16-1.53 1.278-.84.157-2.124.248-3.95.233-3.117-.02-6.29.145-8.962.063-1.33-.057-2.724-.025-3.997-.265-1.027-.153-1.426-.545-1.546-1.31-.004-1.3-.047-2.66.78-3.552.51-.537 1.308-1.053 2.28-1.48 2.325-.798 4.79-1.324 6.933-1.34z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-account-activation',
    }
</script>
