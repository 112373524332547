<template>
    <FormRoot :loading="dataLoading">
        <Field
            :form.sync="form"
            type="boolean"
            name="published"
            placeholder="Offer is published"
            hint="When checked, offer is displayed in stores whatever the availability (if the partner is enabled)."
        ></Field>
        <Field
            :form.sync="form"
            type="component"
            component="Stores"
            multiple
            name="stores"
            hint="Stores where the offer is published"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit"
                >Update</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        props: {
            id: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                fields: {
                    published: null,
                    stores: [],
                },
                messageSuccess: 'The offer has been saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            loadData() {
                return this.$api.offers.show(this.id)
            },
            submit() {
                return this.$api.offers.update(this.id, this.$data.form.data())
            },
        },
    }
</script>
