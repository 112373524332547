<template>
    <svg viewBox="0 0 6.118 7.646" fill="#FFF" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.848 7.646H.27A.27.27 0 0 1 0 7.358L.36 2.32a.27.27 0 0 1 .27-.251h4.857a.27.27 0 0 1 .27.251l.36 5.038a.27.27 0 0 1-.27.288ZM.56 7.106h4.998L5.237 2.61H.882L.56 7.107zM4.32 1.8a.27.27 0 0 1-.27-.27.99.99 0 0 0-1.98 0 .27.27 0 0 1-.54 0 1.53 1.53 0 1 1 3.06 0 .27.27 0 0 1-.27.27z"
        />
        <path
            d="M1.8 3.958a.27.27 0 0 1-.27-.27V1.53a.27.27 0 1 1 .54 0v2.16a.27.27 0 0 1-.27.27Zm2.519 0a.27.27 0 0 1-.27-.27V1.53a.27.27 0 0 1 .54 0v2.16a.27.27 0 0 1-.27.27Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-bag',
    }
</script>
