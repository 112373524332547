<template>
    <Card class="mb-5">
        <p class="m-0">{{ $t('welcome') }}, {{ $store.state.admin.user.first_name }}!</p>
    </Card>
</template>

<script>
    import { Card } from '@tech_hexeko/design-system'

    export default {
        name: 'Dashboard',
        components: {
            Card,
        },
    }
</script>
