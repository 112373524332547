<template>
    <div>
        <Datatable
            name="Credit cards"
            @search="fetchData"
            :pagination.sync="pagination"
            :loading="loading"
            :hover="false"
        >
            <template v-slot:thead>
                <tr>
                    <th>Type</th>
                    <th>Number</th>
                    <th>Brand</th>
                    <th>Expiration</th>
                    <th>Default</th>
                    <th>Added at</th>
                </tr>
            </template>
            <tr v-for="card in cards">
                <td>
                    <Badge class="text-capitalize">{{ card.type }}</Badge>
                </td>
                <td><Icon icon="credit-card" /> •••• {{ card.last4 }}</td>
                <td>{{ card.brand }}</td>
                <td>{{ card.exp_month }} / {{ card.exp_year }}</td>
                <td><Boolean :value="card.is_default_method" /></td>
                <td>{{ card.created_at | formatDate }}</td>
            </tr>
        </Datatable>
    </div>
</template>

<script>
    import { Datatable, Badge, Boolean } from '@tech_hexeko/design-system'

    export default {
        name: 'CreditCards',
        props: {
            user: Number,
        },
        data() {
            return {
                loading: false,
                search: null,
                pagination: {
                    current_page: 1,
                },
                cards: [],
            }
        },
        components: {
            Datatable,
            Badge,
            Boolean,
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.search = search
                this.$api.users
                    .getCreditCards(this.user, {
                        search: this.search,
                    })
                    .then((response) => {
                        this.cards = response.data
                        this.pagination = {
                            total: response.data.length,
                        }
                        this.loading = false
                    })
            },
        },
    }
</script>
