<template>
    <div class="row">
        <Field
            :form.sync="form"
            v-bind="$attrs"
            type="select"
            optionsKeyBy="value"
            optionsLabel="value"
            :name="name"
            placeholder="Timezone"
            :options="timezones"
            hint="Timezone"
        ></Field>
        <div class="col-12">
            <Error v-if="form.errors.has(name)" :error="form.errors.get(name)" class="mb-3" />
        </div>
    </div>
</template>
<script>
    import { Field, Error } from '@tech_hexeko/design-system'
    import timezones from './timezones.json'

    export default {
        props: {
            form: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                name: 'timezone',
                timezones: timezones.reduce((tzs, tz) => {
                    const zones = tz.zones || []
                    return [...tzs, ...zones]
                }, []),
            }
        },
        components: {
            Field,
            Error,
        },
    }
</script>
