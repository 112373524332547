<template>
    <Badge v-if="invoice.canceled_at" color="danger" :large="large" icon="times-circle">Canceled</Badge>
    <Badge v-else-if="invoice.paid_at" color="success" :large="large" icon="check-circle">Paid</Badge>
    <Badge v-else-if="invoice.finalized_at" color="warning" :large="large" icon="exclamation-circle">Due</Badge>
    <Badge v-else color="secondary" :large="large" icon="file">Draft</Badge>
</template>
<script>
    import { Badge } from '@tech_hexeko/design-system'

    export default {
        name: 'InvoiceStatus',
        props: {
            invoice: Object,
            large: Boolean,
        },
        components: {
            Badge,
        },
    }
</script>
