<template>
    <FormRoot>
        <Field
            :form.sync="form"
            type="number"
            name="min"
            placeholder="Min people"
            hint="Enter minimum allowed people"
        ></Field>
        <Field
            :form.sync="form"
            type="number"
            name="max"
            placeholder="Max people"
            hint="Enter maximum allowed people"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit"
                >Update limit</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        props: {
            offer: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                fields: {
                    min: null,
                    max: null,
                },
                messageSuccess: 'The people limit has been saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            submit() {
                return this.$api.offers.setPeopleLimit(this.offer, this.$data.form.data())
            },
        },
    }
</script>
