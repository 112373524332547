<template>
    <Panel :title="panelTitle" :loading="loading" @closePanel="$emit('closePanel')">
        <Tabs v-if="activity">
            <Tab name="Activity" :selected="true">
                <div class="mb-2">
                    <Boolean v-model="activity.published" textOn="Published" textOff="Not published" class="me-1" />
                    <Availability v-model="activity.availability" />
                </div>
                <Sheet>
                    <Row name="Name">{{ activity.name[$store.state.lang.displayLanguage] }}</Row>
                    <Row name="Description" v-if="activity.description[$store.state.lang.displayLanguage]">
                        <div v-html="activity.description[$store.state.lang.displayLanguage]"></div>
                    </Row>
                    <Row name="Types" v-if="activity.types.length > 0">
                        <div v-for="(type, index) in activity.types" :key="index">
                            {{ type.name[$store.state.lang.displayLanguage] }}
                        </div>
                    </Row>
                    <Row name="Partner" v-if="activity.partners.length > 0">
                        <div v-for="(partner, index) in activity.partners" :key="index">
                            <a href="#" @click.prevent="openPartner(partner)">{{
                                partner.name[$store.state.lang.displayLanguage]
                            }}</a>
                        </div>
                    </Row>
                    <Row name="Address" v-if="activity.address">
                        <Address :address="activity.address" />
                    </Row>
                    <Row name="Process" v-if="activity.processes.length > 0">
                        <Process
                            v-for="(process, index) in activity.processes"
                            :process="process"
                            :key="index"
                            class="me-1"
                        />
                    </Row>
                    <Row name="Tags" v-if="activity.tags.length > 0">
                        <Tag v-for="(tag, index) in activity.tags" :key="index" class="me-1">{{
                            tag[$store.state.lang.displayLanguage]
                        }}</Tag>
                    </Row>
                    <Row name="Slug">{{ activity.slugs[$store.state.lang.displayLanguage] }}</Row>
                    <Row name="Created at">{{ activity.created_at | formatDate }}</Row>
                    <Row name="Updated at">{{ activity.updated_at | formatDate }}</Row>
                </Sheet>

                <ID name="Hexeko" :value="activity.id" />
            </Tab>
            <Tab name="Offers" v-if="$store.getters.userHasPermission('managePartners')">
                <Offers :params="{ activity_id: activity.id }" />
            </Tab>
            <Tab name="Types" v-if="$store.getters.userHasPermission('managePartners')">
                <TypesSelector :types="activity.types" @save="typesSave" />
            </Tab>
        </Tabs>
        <template v-slot:actions v-if="$store.getters.userHasPermission('managePartners')">
            <Dropdown small title="Actions" position="right">
                <DropdownItem @click="openModal('updateActivity')" icon="edit">Update activity</DropdownItem>
                <DropdownItem @click="openModal('updateDescription')" icon="images"
                    >Update description and medias</DropdownItem
                >
                <DropdownItem @click="openModal('options')" icon="tags">Tags</DropdownItem>
                <DropdownItem confirm @confirm="deleteActivity()" icon="trash-alt" class="text-danger"
                    >Delete</DropdownItem
                >
            </Dropdown>
            <!-- Update activity -->
            <Modal v-if="isModalOpen('updateActivity')" @close="closeModal('updateActivity')" title="Update activity">
                <UpdateActivity :id="activity.id" @submitted="activitySaved"></UpdateActivity>
            </Modal>
            <!-- Update description -->
            <Modal
                v-if="isModalOpen('updateDescription')"
                @close="closeModal('updateDescription')"
                title="Update description"
            >
                <UpdateDescription :id="activity.id" @submitted="activitySaved"></UpdateDescription>
            </Modal>
            <!-- Options -->
            <Modal v-if="isModalOpen('options')" @close="closeModal('options')" title="Tags">
                <Options :id="activity.id" @submitted="activitySaved"></Options>
            </Modal>
        </template>
    </Panel>
</template>

<script>
    import {
        Panel,
        Tabs,
        Tab,
        Sheet,
        Row,
        Boolean,
        ID,
        Tag,
        Modal,
        Dropdown,
        DropdownItem,
        Alerts,
    } from '@tech_hexeko/design-system'
    import Offers from '@/components/Offers'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import Process from '@/components/layout/Process'
    import Availability from '@/components/layout/Availability'
    import Address from '@/components/Address'
    import UpdateActivity from './UpdateActivity'
    import UpdateDescription from './UpdateDescription'
    import Options from './Options'
    import TypesSelector from '../Types/Selector'

    export default {
        name: 'ActivityPanel',
        props: {
            id: Number,
        },
        data() {
            return {
                loading: true,
                activity: null,
            }
        },
        components: {
            Panel,
            Sheet,
            Row,
            Tabs,
            Tab,
            ID,
            Offers,
            Boolean,
            Address,
            Tag,
            Modal,
            Dropdown,
            DropdownItem,
            Process,
            Availability,
            UpdateActivity,
            UpdateDescription,
            Options,
            TypesSelector,
        },
        mixins: [Modals],
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.loading = true
                this.$api.activities
                    .show(this.id, {
                        includes: [
                            'slugs',
                            'availability',
                            'partners',
                            'types.parent',
                            'address',
                            'contacts',
                            'medias',
                            'first_media',
                            'tags',
                            'processes',
                            'description',
                        ],
                    })
                    .then((response) => {
                        this.activity = response
                        this.loading = false
                    })
            },
            tagAdded() {
                this.closeModal('addTag')
                this.fetchData()
            },
            activitySaved() {
                this.closeModal('updateActivity')
                this.fetchData()
            },
            openPartner(partner) {
                this.openPanel('partnerPanel', partner.id)
            },
            deleteActivity() {
                this.$api.activities.delete(this.id).then(() => {
                    Alerts.notificationSuccess('Activity deleted')
                    this.closePanel({ refresh: true })
                })
            },
            closePanel(args) {
                this.$emit('closePanel', args)
            },
            typesSave(types) {
                this.$api.activities.update(this.id, { types: types }).then((response) => {
                    Alerts.messageSuccess('Types saved')
                })
            },
        },
        computed: {
            panelTitle: function() {
                return this.activity ? this.activity.name[this.$store.state.lang.displayLanguage] : null
            },
        },
    }
</script>
