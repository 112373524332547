<template>
    <div>
        <div v-if="name" class="mb-1 text-xxs text-secondary">{{ name }}</div>
        <Table v-if="address && Object.keys(address).length > 0" class="table-address bg-white rounded mb-0">
            <slot></slot>
            <Row v-if="address.address" name="Address">{{ address.address }}</Row>
            <Row v-else-if="address.street" name="Street">{{ address.street }}</Row>
            <template v-else>
                <Row v-if="address.line1" name="Line1">{{ address.line1 }}</Row>
                <Row v-if="address.line2" name="Line2">{{ address.line2 }}</Row>
            </template>
            <Row v-if="address.zipcode" name="Zipcode">{{ address.zipcode }}</Row>
            <Row v-if="address.city" name="City">{{ address.city }}</Row>
            <Row v-if="address.country" name="Country">{{ address.country }}</Row>
        </Table>
    </div>
</template>

<script>
    import { Row, Table } from '@tech_hexeko/design-system'

    export default {
        name: 'Address',
        components: {
            Row,
            Table,
        },
        props: {
            address: {
                type: Object,
                required: true,
            },
            name: {
                type: String,
                default: null,
            },
        },
    }
</script>

<style type="text/css">
    .table-address td {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
</style>
