<template>
    <FormRoot>
        <Field
            :form.sync="form"
            type="text"
            name="amount"
            placeholder="Amount in €"
            hint="Enter a positive amount in euros"
        ></Field>
        <Field
            :form.sync="form"
            type="datetime"
            name="start_date"
            placeholder="Start date"
            hint="Start of validity (format 2021-01-01 23:10:00)"
        ></Field>
        <Field
            :form.sync="form"
            type="datetime"
            name="expiration_date"
            placeholder="Expiration date"
            hint="End of validity (format 2021-01-01 23:10:00)"
        ></Field>
        <Field
            :form.sync="form"
            type="radio"
            name="main_comment"
            :options="{
                deposit: 'Deposit',
                credit: 'Credit'
            }"
            optionsKeyBy="main_comment"
            optionsLabel="main_comment"
            hint="The type of deposit"
        />
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" @click="onSubmit">Deposit</Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        props: {
            wallet: {
                type: Number,
                required: false,
            },
        },
        data() {
            return {
                fields: {
                    amount: null,
                    start_date: null,
                    expiration_date: null,
                    main_comment: 'deposit',
                },
                messageSuccess: 'The amount has been deposited on wallet',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            submit() {
                return this.$api.wallets.deposit(this.wallet, this.$data.form.data())
            },
        },
    }
</script>
