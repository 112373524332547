<template>
    <amplify-authenticator>
        <Admin class="text-xs" v-if="$store.state.admin.user" />
        <amplify-sign-in slot="sign-in" hide-sign-up>
            <div slot="federated-buttons"></div>
        </amplify-sign-in>
    </amplify-authenticator>
</template>

<script>
    import { onAuthUIStateChange } from '@aws-amplify/ui-components'
    import Admin from './Admin'

    export default {
        name: 'App',
        components: {
            Admin,
        },
        data() {
            return {
                user: undefined,
                authState: undefined,
                unsubscribeAuth: undefined,
            }
        },
        created() {
            this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
                this.authState = authState
                this.user = authData

                // set api token
                if (this.user?.signInUserSession) {
                    let token = this.user.signInUserSession.accessToken.jwtToken
                    this.$api.setToken(token, 'Bearer')
                    this.$importerApi.setToken(token, 'Bearer')
                    this.$exporterApi.setToken(token, 'Bearer')
                    this.$verificationApi.setToken(token, 'Bearer')

                    // fetch user
                    this.$api.me.showProfile().then((response) => {
                        this.$store.commit('SET_USER', response)
                    })
                }
            })
        },
        beforeUnmount() {
            this.unsubscribeAuth()
        },
    }
</script>
