<template>
    <Badge v-if="wallet.deleted_at" color="danger" icon="trash">Trashed</Badge>
    <UIBoolean v-else v-model="wallet.enabled" :color="color" textOn="Enabled" textOff="Disabled" />
</template>

<script>
    import { Boolean as UIBoolean, Badge } from '@tech_hexeko/design-system'

    export default {
        name: 'WalletStatus',
        components: { UIBoolean, Badge },
        props: {
            wallet: {
                type: Object,
                required: true,
            },
        },
        computed: {
            color() {
                return !this.wallet.enabled ? 'danger' : null
            },
        },
    }
</script>
