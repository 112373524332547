<template>
    <Boolean
        v-model="available"
        textOn="Available"
        :textOff="picked ? 'Already picked' : expired ? 'Expired' : 'Unavailable'"
    />
</template>

<script>
    import { Boolean } from '@tech_hexeko/design-system'
    import moment from 'moment'

    export default {
        data() {
            return {
                moment: moment,
            }
        },
        props: {
            voucher: Object,
        },
        components: {
            Boolean,
        },
        computed: {
            available: function() {
                return !this.picked && !this.expired
            },
            picked: function() {
                return this.voucher.picked_at
            },
            expired: function() {
                return moment(this.voucher.expiration_date).isBefore()
            },
        },
    }
</script>
