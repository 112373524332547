<template>
    <div>
        <div id="kyb-container"></div>
    </div>
</template>

<script>
    export default {
        name: 'KYB',
        props: {
            token: String,
            reference: String,
        },
        data() {
            return {
                uiKey: '8Zhn0BfCMLgBeCcLRVsADg==',
            }
        },
        mounted() {
            this.start()
        },
        methods: {
            start() {
                let reference = this.reference
                let token = this.token

                // Initialise the UI library using your ui_key. Replace {{ui_key}} with your own UI Key.
                window.OpcUxSecureClient.init(this.uiKey)

                // Set the user's authentication token
                window.OpcUxSecureClient.associate('Bearer ' + token, function() {
                    console.log('associate success')

                    // Initialise the KYB UI Component
                    window.OpcUxSecureClient.kyb().init(
                        // The selector identifying the container where you want to display the component
                        '#kyb-container',

                        // The KYB reference returned in the previous step
                        { reference: reference },

                        function(messageType, payload) {
                            console.log({ messageType, payload })
                        },
                        // Specify a string of plain text styles to brand the UI elements shown in the KYB flow
                        { customCssStr: {} },

                        // Specify a css file containing styles to brand the UI elements shown in the KYB flow
                        { customCss: {} }
                    )
                })
            },
        },
    }
</script>
