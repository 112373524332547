<template>
    <Panel title="Payout Request" :loading="loading" @closePanel="closePanel">
        <Tabs v-if="payoutRequest">
            <Tab name="Request" :selected="true">
                <div class="mb-2">
                    <PayoutStatus :status="payoutRequest.status" />
                </div>
                <Sheet name="Details">
                    <Row name="Source" v-if="payoutRequest.source">
                        <div>
                            <a
                                v-if="payoutRequest.source.ticket_date"
                                href="#"
                                @click.prevent="openExpenseRequest(payoutRequest.source)"
                            >
                                <Icon icon="undo-alt" /> Expense Request
                            </a>
                            <a
                                v-else-if="payoutRequest.source.name"
                                href="#"
                                @click.prevent="openGroup(payoutRequest.source.id)"
                            >
                                <Icon icon="user" /> {{ payoutRequest.source.name }}
                            </a>
                            <a
                                v-else-if="payoutRequest.source.metadata"
                                href="#"
                                @click.prevent="openAllowance(payoutRequest.source.id)"
                            >
                                <Icon icon="undo-alt" /> Allowance
                            </a>
                        </div>
                    </Row>
                    <Row name="Driver">
                        <span class="text-capitalize">{{ payoutRequest.driver }}</span>
                    </Row>
                    <Row name="Reference">{{ payoutRequest.reference }}</Row>
                </Sheet>
                <Sheet name="Amount">
                    <Row name="Amount">{{ payoutRequest.amount | currency }}</Row>
                </Sheet>
                <Sheet name="Date">
                    <Row name="Created at">{{ payoutRequest.created_at | formatDate }}</Row>
                    <Row name="Updated at">{{ payoutRequest.updated_at | formatDate }}</Row>
                    <Row name="Execution date">{{ payoutRequest.execution_date | formatDate }}</Row>
                </Sheet>
                <ID name="Hexeko" :value="payoutRequest.id" />
                <ID v-if="payoutRequest.external_id" name="External" :value="payoutRequest.external_id" />
            </Tab>

            <!-- Payouts -->
            <Tab name="Payouts" v-if="payoutRequest.payouts">
                <Datatable name="Payouts" :search-class="'d-none'" :hideActions="false">
                    <template v-slot:thead>
                        <tr>
                            <th>ID</th>
                            <th>Amount</th>
                            <th>IBAN</th>
                            <th>Status</th>
                            <th>Updated at</th>
                            <th></th>
                        </tr>
                    </template>
                    <tr v-for="payout in payoutRequest.payouts" :key="payout.id" @click="openPayout(payout.id)">
                        <td>
                            <code>{{ payout.id }}</code>
                        </td>
                        <td>{{ payout.amount | currency }}</td>
                        <td>•••• {{ payout.iban_last_four }}</td>
                        <td><PayoutStatus :status="payout.status" /></td>
                        <td>{{ payoutRequest.updated_at | formatDate }}</td>
                        <td class="text-right"><Icon icon="angle-right" /></td>
                    </tr>
                </Datatable>
            </Tab>

            <!-- History -->
            <Tab name="History">
                <History :statuses="payoutRequest.statuses" v-slot:default="slotProps">
                    <PayoutStatus :status="slotProps.status" />
                </History>
            </Tab>

            <!-- Accounts -->
            <Tab name="Accounts" v-if="payoutRequest.payment_accounts">
                <Sheet name="Target" v-if="payoutRequest.payment_accounts.target">
                    <Row name="IBAN" v-if="payoutRequest.payment_accounts.target.iban">
                        •••• {{ payoutRequest.payment_accounts.target.iban }}
                    </Row>
                    <Row name="BIC" v-if="payoutRequest.payment_accounts.target.bic">
                        {{ payoutRequest.payment_accounts.target.bic }}
                    </Row>
                    <Row name="Holder name" v-if="payoutRequest.payment_accounts.target.holderName">
                        {{ payoutRequest.payment_accounts.target.holderName }}
                    </Row>
                </Sheet>
                <Sheet name="Origin" v-if="payoutRequest.payment_accounts.origin">
                    <Row name="IBAN" v-if="payoutRequest.payment_accounts.origin.iban">
                        •••• {{ payoutRequest.payment_accounts.origin.iban }}
                    </Row>
                    <Row name="BIC" v-if="payoutRequest.payment_accounts.origin.bic">
                        {{ payoutRequest.payment_accounts.origin.bic }}
                    </Row>
                    <Row name="Holder name" v-if="payoutRequest.payment_accounts.origin.holderName">
                        {{ payoutRequest.payment_accounts.origin.holderName }}
                    </Row>
                </Sheet>
            </Tab>
        </Tabs>
    </Panel>
</template>

<script>
    import { Panel, Tabs, Tab, Sheet, Row, ID, Datatable } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import PayoutStatus from '../PayoutStatus'
    import History from '@/components/layout/History'

    export default {
        name: 'PayoutPanel',
        props: {
            id: String,
        },
        data() {
            return {
                loading: true,
                payoutRequest: null,
            }
        },
        components: {
            Panel,
            Sheet,
            Row,
            Tabs,
            Tab,
            ID,
            PayoutStatus,
            History,
            Datatable,
        },
        mixins: [Modals],
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.loading = true
                this.$api.payouts
                    .showPayoutRequest(this.id, {
                        includes: ['statuses', 'source', 'payouts'],
                    })
                    .then((response) => {
                        this.payoutRequest = response
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            closePanel(args) {
                this.$emit('closePanel', args)
            },
            openAllowance(id) {
                let panel = this.openPanel('AllowancePanel', id)
                panel.promise.then((result) => {
                    if (result && result.refresh) this.fetchData(this.search)
                })
            },
            openExpenseRequest(expenseRequest) {
                let panel = this.openPanel('ExpenseRequestPanel', expenseRequest.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
            openPayout(id) {
                this.openPanel('payoutPanel', id)
            },
            openGroup(id) {
                this.openPanel('GroupPanel', id)
            },
        },
        computed: {},
    }
</script>
