<template>
    <svg viewBox="0 0 24 18" fill="#000" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19 14C19.5523 14 20 13.5523 20 13C20 12.4477 19.5523 12 19 12C18.4477 12 18 12.4477 18 13C18 13.5523 18.4477 14 19 14Z"
        />
        <path
            d="M12 12C11.4477 12 11 12.4477 11 13C11 13.5523 11.4477 14 12 14H16C16.5523 14 17 13.5523 17 13C17 12.4477 16.5523 12 16 12H12Z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 0C1.34315 0 0 1.34315 0 3L0 15C0 16.6569 1.34314 18 3 18H21C22.6569 18 24 16.6569 24 15V3C24 1.34315 22.6569 0 21 0L3 0ZM2 15L2 7L22 7V15C22 15.5523 21.5523 16 21 16H3C2.44772 16 2 15.5523 2 15ZM2 5L22 5V3C22 2.44771 21.5523 2 21 2L3 2C2.44772 2 2 2.44772 2 3V5Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-card-license',
    }
</script>
