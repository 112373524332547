<template>
    <FormRoot :loading="dataLoading">
        <Field :form.sync="form" type="component" component="Groups" name="group_id" hint="Select groups"></Field>
        <FormActions>
            <Submit class="float-right btn-danger" :disabled="!form.submittable" @click="onSubmit"
                >Remove users from group</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot } from '@tech_hexeko/design-system'
    import { Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        data() {
            return {
                fields: {
                    group_id: null,
                },
                messageSuccess: 'The group of users have been saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            loadData() {
                return this.$api.groups.get()
            },
        },
    }
</script>
