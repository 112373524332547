<template>
    <Datatable
        name="Offers"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
    >
        <tr v-for="offer in offers" @click="open(offer)" class="cursor-pointer">
            <td>
                <code>{{ offer.id }}</code>
            </td>
            <td>{{ offer.name[$store.state.lang.displayLanguage] }}</td>
            <td>{{ offer.price | currency }}</td>
            <td>{{ offer.duration }}</td>
            <td><Process :process="offer.process" /></td>
            <td><Boolean v-model="offer.published" textOn="Published" textOff="Not published"></Boolean></td>
            <td><Availability v-model="offer.availability" /></td>
        </tr>
        <template v-slot:actions v-if="$store.getters.userHasPermission('managePartners')">
            <Button small @click="openModal('createOffer')" icon="plus">Create offer</Button>
            <Modal v-if="isModalOpen('createOffer')" @close="closeModal('createOffer')" title="Create offer">
                <UpdateOffer :values="params" @submitted="offerSubmitted"></UpdateOffer>
            </Modal>
        </template>
    </Datatable>
</template>

<script>
    import { Datatable, Boolean, Badge, Modal, Button } from '@tech_hexeko/design-system'
    import Process from '@/components/layout/Process'
    import Availability from '@/components/layout/Availability'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import UpdateOffer from './UpdateOffer'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'

    export default {
        name: 'Offers',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        mixins: [Modals],
        data() {
            return {
                loading: true,
                search: null,
                pagination: {
                    current_page: 1,
                },
                offers: [],
            }
        },
        components: {
            Datatable,
            Boolean,
            Badge,
            Process,
            Availability,
            UpdateOffer,
            Modal,
            Button,
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.search = search
                this.$api.activities
                    .getOffers(
                        this.params.activity_id,
                        this.mergeParams({
                            search: this.search,
                            page: this.pagination.current_page,
                            includes: ['availability'],
                        })
                    )
                    .then((response) => {
                        this.offers = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            open(offer) {
                let panel = this.openPanel('offerPanel', offer.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
            offerSubmitted() {
                this.closeModal('createOffer')
                this.fetchData(this.search)
            },
        },
    }
</script>
