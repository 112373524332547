export default {
    approved: {
        name: 'Approved',
        color: 'success',
        icon: 'check-circle',
    },
    declined: {
        name: 'Declined',
        color: 'danger',
        icon: 'stop-circle',
    },
    closed: {
        name: 'Closed',
        color: 'success',
        icon: 'check-circle',
    },
    reversed: {
        name: 'Reversed',
        color: 'secondary',
        icon: 'times-circle',
    },
};
