<template>
    <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.4 3.432v.572a.293.293 0 0 1-.08.202.267.267 0 0 1-.194.084h-.82v.429a.42.42 0 0 1-.41.429H1.503a.42.42 0 0 1-.41-.429V4.29h-.82a.267.267 0 0 1-.193-.084.293.293 0 0 1-.08-.202v-.572c0-.057.016-.112.046-.159a.276.276 0 0 1 .123-.105L8.095.022a.262.262 0 0 1 .21 0l7.925 3.146c.05.021.093.058.123.105.03.047.046.102.046.159ZM15.58 14.3H.82c-.453 0-.82.384-.82.858v.572c0 .076.029.148.08.202.051.054.12.084.193.084h15.853c.072 0 .142-.03.193-.084a.293.293 0 0 0 .08-.202v-.572c0-.474-.367-.858-.82-.858ZM2.732 5.72v6.864h-1.23a.42.42 0 0 0-.41.429v.715h14.213v-.715a.42.42 0 0 0-.41-.43h-1.23V5.72H11.48v6.864H9.293V5.72H7.106v6.864H4.92V5.72H2.733Z"
            fill="#000"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-culture',
    }
</script>
