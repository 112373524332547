export default {
    data: function() {
        return {
            selected: [],
        };
    },
    methods: {
        select(item) {
            this.selected.push(item.id);
        },
        unselect(item) {
            const index = this.selected.indexOf(item.id);
            if (index > -1) {
                this.selected.splice(index, 1);
            }
        },
        toggleSelect(item) {
            if (this.isSelected(item)) {
                this.unselect(item);
            } else {
                this.select(item);
            }
        },
        isSelected(item) {
            return this.selected.includes(item.id);
        },
        allIdsNotSelected() {
            return this.allIds().filter((value) => {
                return this.selected.indexOf(value) === -1;
            });
        },
        allIdsSelected() {
            return this.allIds().filter((value) => {
                return this.selected.indexOf(value) >= 0;
            });
        },
        selectAll() {
            this.selected = this.selected.concat(this.allIdsNotSelected());
        },
        unselectAll() {
            this.selected = this.selected.filter((value) => {
                return this.allIds().indexOf(value) === -1;
            });
        },
        reset() {
            this.selected = [];
        },
        bulkAction(title, callback, allowExport) {
            let panel = this.$showPanel({
                component: 'BulkActionsPanel',
                openOn: 'bottom',
                height: 400,
                props: {
                    allowExport: allowExport,
                    callback: callback,
                    selected: this.selected,
                    title: title,
                },
            });
            return panel.promise;
        },
    },
    computed: {
        metaSelected: function() {
            return {
                noneSelected: this.selected.length === 0,
                selectedLength: this.selected.length,
                pageNotSelectedLength: this.allIdsNotSelected().length,
                pageSelectedLength: this.allIdsSelected().length,
            };
        },
    },
};
