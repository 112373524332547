<template>
    <Datatable
        name="Notifications"
        searchClass="d-none"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hover="false"
        hideActions
    >
        <template v-slot:thead>
            <tr>
                <th>Notification</th>
                <th>Actions</th>
                <th></th>
            </tr>
        </template>
        <tr v-for="(notification, index) in notifications" :key="notification.id" valign="middle">
            <td
                data-bs-toggle="collapse"
                :data-bs-target="'#collapse' + index"
                aria-expanded="false"
                class="collapsed"
                :aria-controls="'collapse' + index"
            >
                <div class="d-flex justify-content-between align-items-center cursor-pointer px-2 py-1">
                    <Notification
                        :date="notification.created_at"
                        :notification="notification"
                        disableIcon
                        :useColor="false"
                    />
                </div>
                <div class="bg-blue collapse px-2" :id="'collapse' + index">
                    <div class="d-flex justify-content-between align-items-center">
                        <RawOutput :item="notification.data" />
                    </div>
                </div>
            </td>
            <td>
                <Button
                    color="danger"
                    is="confirm"
                    @confirm="deleteNotification(notification.id)"
                    title="Are you sure you want to delete this notification?"
                    icon="trash-alt"
                    v-if="$store.getters.userHasPermission('manageNotifications')"
                >
                </Button>
            </td>
            <td
                class="icon-container"
                data-bs-toggle="collapse"
                :data-bs-target="'#collapse' + index"
                aria-expanded="false"
                :aria-controls="'collapse' + index"
            >
                <Icon icon="chevron-up" class="icon" />
            </td>
        </tr>
    </Datatable>
</template>

<script>
    import { Alerts, Datatable } from '@tech_hexeko/design-system'
    import Notification from '@tech_hexeko/ui/src/components/Notification'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import RawOutput from '@/components/RawOutput'
    export default {
        name: 'Notifications',
        components: {
            Datatable,
            Notification,
            RawOutput,
        },
        data() {
            return {
                loading: true,
                notifications: null,
                pagination: {
                    current_page: 1,
                },
            }
        },
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            user: {
                type: Number,
                required: true,
            },
        },
        methods: {
            deleteNotification(id) {
                this.$api.notifications
                    .delete(id)
                    .then(() => {
                        Alerts.messageSuccess('Notification deleted')
                        this.fetchData()
                    })
                    .catch((error) => {
                        let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
            },
            fetchData(search) {
                this.loading = true
                this.$api.users
                    .getNotifications(
                        this.user,
                        this.mergeParams({
                            page: this.pagination.current_page,
                            ...(search && { search: search }),
                        })
                    )
                    .then((response) => {
                        this.notifications = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>

<style type="text/css">
    .notification.bg-white {
        background: transparent !important;
    }
</style>

<style lang="scss" scoped>
    .icon {
        transform: rotate(0deg);
    }
    .collapsed ~ .icon-container .icon {
        transform: rotate(180deg);
    }
</style>
