<template>
    <div
        class="me-1 mb-1 d-inline-block rounded bg-light text-center align-top"
        v-if="!media.deleted"
        style="height: 100px; width: 100px;"
    >
        <div v-if="media.responsive_images" class="position-relative">
            <img :src="media.responsive_images.thumb" class="rounded" width="100" height="100" />
            <a
                href="#"
                tag="a"
                is="confirm"
                @confirm="deleteMedia(media)"
                class="text-white position-absolute top-5 right-10"
                ><Icon icon="times"
            /></a>
        </div>
        <div class="position-relative content-center" v-else>
            <Spinner class="text-muted" />
        </div>
    </div>
</template>

<script>
    import { Spinner } from '@tech_hexeko/design-system'

    export default {
        props: {
            media: Object,
        },
        methods: {
            deleteMedia() {
                this.$emit('delete')
            },
        },
        components: {
            Spinner,
        },
    }
</script>
