<template>
    <Panel
        :title="type ? type.name[$store.state.lang.displayLanguage] : '...'"
        :loading="loading"
        @closePanel="closePanel"
    >
        <Tabs v-if="!loading">
            <Tab name="Type" :selected="true">
                <Boolean v-model="type.is_platform_visible" textOn="Visible on platform" textOff="Invisible on platform" class="mb-2" />
                <Sheet>
                    <Row name="Name">{{ type.name[$store.state.lang.displayLanguage] }}</Row>
                    <Row name="Slug">{{ type.slugs[$store.state.lang.displayLanguage] }}</Row>
                    <Row v-if="type.technical_name" name="Technical name">{{ type.technical_name }}</Row>
                    <Row v-if="type.rules && Object.keys(type.rules).length > 0" name="Legal rules">
                        <RawOutput :item="type.rules" />
                    </Row>
                    <Row name="Market segments">{{ type.market_segments.join(', ') }}</Row>
                    <Row name="Created at">{{ type.created_at | formatDate }}</Row>
                    <Row name="Updated at">{{ type.updated_at | formatDate }}</Row>
                </Sheet>
                <ID name="Hexeko" :value="type.id" class="me-1" />
                <template v-if="$store.getters.userHasPermission('manageTypes')">
                    <Button
                        color="danger"
                        is="confirm"
                        @confirm="deleteType()"
                        title="Are you sure you want to delete ?"
                        ><Icon icon="trash-alt" /> Delete</Button
                    >
                </template>
            </Tab>
            <Tab name="MCC">
                <MCC v-model="form_mccs" @save="saveMccs" />
            </Tab>
        </Tabs>
        <template v-slot:actions v-if="$store.getters.userHasPermission('manageTypes')">
            <Dropdown small title="Actions" position="right">
                <DropdownItem @click="openModal('updateType')" icon="edit">Update type</DropdownItem>
            </Dropdown>
            <!-- Update type -->
            <Modal v-if="isModalOpen('updateType')" @close="closeModal('updateType')" title="Update type">
                <UpdateType :id="type.id" @submitted="typeUpdated"></UpdateType>
            </Modal>
        </template>
    </Panel>
</template>

<script>
    import {
        Panel,
        Tabs,
        Tab,
        Sheet,
        Row,
        ID,
        Dropdown,
        DropdownItem,
        Alerts,
        Alert,
        Button,
        Modal,
        Submit,
        Checkbox,
        Badge,
        Card,
        Boolean,
    } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import UpdateType from './UpdateType'
    import MCC from './MCC'
    import axios from 'axios'
    import RawOutput from '@/components/RawOutput'

    export default {
        name: 'TypePanel',
        props: {
            id: Number,
        },
        data() {
            return {
                loading: true,
                type: null,
                form_mccs: [],
            }
        },
        mounted() {
            this.fetchData()
        },
        mixins: [Modals],
        methods: {
            fetchData() {
                this.loading = true

                // set requests
                const requestType = this.$api.types.show(this.id, { includes: ['rules', 'market_segments'] })
                const requestMccs = this.$api.types.getMccs(this.id, { per_page: 10000, includes: ['rules'] })

                // make requests
                axios.all([requestType, requestMccs]).then(
                    axios.spread((...responses) => {
                        const responseType = responses[0]
                        const responseMccs = responses[1]
                        // set data
                        this.type = responseType
                        this.fillMccs(responseMccs.data)
                        // stop loading
                        this.loading = false
                    })
                )
            },
            fillMccs(mccs) {
                mccs.forEach((mcc) => {
                    this.form_mccs.push(mcc.id)
                })
            },
            saveMccs() {
                this.$api.types
                    .setMccs(this.id, {
                        mccs: this.form_mccs,
                    })
                    .then(() => {
                        Alerts.notificationSuccess('Successfully saved')
                    })
            },
            deleteType() {
                this.$api.types.delete(this.id).then(() => {
                    Alerts.notificationSuccess('Successfully deleted')
                    this.closePanel({ refresh: true })
                })
            },
            closePanel(args) {
                this.$emit('closePanel', args)
            },
            typeUpdated() {
                this.closeModal('updateType')
                this.fetchData()
            },
        },
        components: {
            Panel,
            Tabs,
            Tab,
            Sheet,
            Row,
            ID,
            Dropdown,
            Button,
            UpdateType,
            DropdownItem,
            Modal,
            Submit,
            Checkbox,
            Alert,
            Badge,
            Card,
            MCC,
            RawOutput,
            Boolean,
        },
    }
</script>
