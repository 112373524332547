<template>
    <Badge v-if="status" :color="color" :large="large" :icon="icon">{{ name }}</Badge>
</template>

<script>
    import { Badge } from '@tech_hexeko/design-system'
    import statuses from './statuses'

    export default {
        name: 'ExpenseRequestStatus',
        components: { Badge },
        props: {
            large: {
                type: Boolean,
                default: false,
            },
            status: {
                type: Object,
                default: () => ({}),
                required: true,
            },
        },
        computed: {
            color() {
                return this.filteredStatus ? this.filteredStatus.color : 'light'
            },
            filteredStatus() {
                return statuses[this.status.name]
            },
            icon() {
                return this.filteredStatus ? this.filteredStatus.icon : 'question-circle'
            },
            name() {
                return this.filteredStatus ? this.filteredStatus.name : this.status.name
            },
        },
    }
</script>
