<template>
    <div v-if="wallet.types && wallet.types.length > 0" class="d-flex">
        <div
            v-if="typesFiltered.length > 1"
            class="icons-overlap align-items-center pe-1 w-100"
            :class="wrapperClasses"
        >
            <IconWallet
                v-for="(type, index) in wallet.types"
                :key="index"
                :category="type.technical_name"
                :small="small"
            />
        </div>
        <IconWallet v-else :class="classes" :category="wallet.types[0].technical_name" :small="small" />
    </div>
</template>

<script>
    import IconWallet from '@/components/IconWallet'

    export default {
        name: 'WalletIcons',
        components: {
            IconWallet,
        },
        data() {
            return {
                typesSupported: [
                    'carpooling',
                    'culture',
                    'fuel_expenses',
                    'gift',
                    'holidays',
                    'meal',
                    'mobility',
                    'public_transport_passes',
                    'sport',
                    'sustainable_mobility_package',
                ],
            }
        },
        props: {
            position: {
                type: String,
                default: null,
            },
            small: {
                type: Boolean,
                default: false,
            },
            wallet: {
                type: Object,
                required: true,
                default: () => ({}),
            },
        },
        computed: {
            classes() {
                return {
                    'mx-auto': this.position === 'center',
                }
            },
            typesFiltered() {
                return this.wallet.types.filter((type) => {
                    return this.typesSupported.includes(type.technical_name)
                })
            },
            wrapperClasses() {
                return {
                    'justify-content-center': this.position === 'center',
                    'is--left': this.position === 'left',
                }
            },
        },
    }
</script>

<style type="text/css">
    .icons-overlap {
        display: flex;
        flex-direction: row-reverse;
    }
    .icons-overlap.is--left {
        justify-content: left;
        padding-left: 13px;
    }
    .icons-overlap [class*='-icon'] {
        margin-left: -13px;
    }
</style>
