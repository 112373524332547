<template>
    <svg viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M29.918 13.487a14.974 14.974 0 0 0-4.805-9.502c-.6.56-3.156 3.117-4.316 7.039 4.416.676 7.717 1.886 9.121 2.463ZM4.915 3.957a14.974 14.974 0 0 0-4.833 9.53 35.447 35.447 0 0 1 9.159-2.573C8.046 6.987 5.456 4.452 4.915 3.957ZM24.078 3.125c-.834.822-3.396 3.624-4.566 7.722a39.845 39.845 0 0 0-8.994-.09C9.324 6.659 6.75 3.88 5.952 3.095A14.936 14.936 0 0 1 15 .053c3.408 0 6.558 1.146 9.078 3.072ZM9.562 12.158c-3.069.42-6.3 1.273-9.559 2.758 0 .045-.003.09-.003.136 0 4.473 1.97 8.493 5.088 11.244 4.607-5.475 5.26-10.347 4.474-14.138ZM29.996 14.915c-.627-.286-4.26-1.86-9.511-2.648-.752 3.772-.074 8.594 4.46 13.999C28.042 23.516 30 19.508 30 15.052c0-.046-.003-.091-.004-.137Z"
            fill="#000"
        />
        <path
            d="M23.898 27.117A14.913 14.913 0 0 1 15 30.05c-3.312 0-6.378-1.08-8.862-2.904 4.86-5.79 5.532-11.022 4.692-15.138a38.361 38.361 0 0 1 8.382.09c-.816 4.092-.12 9.282 4.686 15.018Z"
            fill="#000"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-sport-hobbies',
    }
</script>
