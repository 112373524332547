<template>
    <Icon v-if="type" :icon="type === 'group' ? 'building' : 'user'" />
</template>

<script>
    export default {
        props: {
            type: String,
        },
    }
</script>
