<template>
    <Datatable
        name="Stores"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
    >
        <template v-slot:thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>App URL</th>
                <th>Created</th>
            </tr>
        </template>
        <tr v-for="store in stores" @click="openStore(store)">
            <td>
                <code>{{ store.id }}</code>
            </td>
            <td>{{ store.name }}</td>
            <td>{{ store.app_url }}</td>
            <td>{{ store.created_at | formatDate }}</td>
        </tr>
    </Datatable>
</template>

<script>
    import { Datatable, Button, Dropdown, DropdownItem, Boolean, Badge } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'

    export default {
        name: 'Payouts',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                pagination: {
                    current_page: 1,
                },
                stores: [],
            }
        },
        components: {
            Datatable,
            Dropdown,
            DropdownItem,
            Button,
            Boolean,
            Badge,
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.$api.stores
                    .get(
                        this.mergeParams({
                            search: search,
                            page: this.pagination.current_page,
                        })
                    )
                    .then((response) => {
                        this.stores = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            openStore(store) {
                this.openPanel('StorePanel', store.id)
            },
        },
    }
</script>
