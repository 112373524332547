<template>
    <div>
        <div v-if="loading" class="text-muted content-center text-center">
            <Spinner size="2x" />
        </div>
        <template v-else>
            <Tabs :pills="!bookingsCreated" :small="bookingsCreated">
                <!-- Request -->
                <Tab name="Booking request" :selected="true">
                    <!-- Status -->
                    <BookingRequestStatus large :status="request.status" class="mb-1" />

                    <!-- Data -->
                    <Sheet class="mt-1">
                        <Row name="User" v-if="request.user">
                            <div>
                                <a href="#" @click.prevent="openUser(request.user)">{{ request.user.name }}</a>
                            </div>
                            <div>{{ request.user.email }}</div>
                            <div>{{ request.user.birthdate | formatDate('DD/MM/YYYY') }}</div>
                        </Row>
                        <Row name="Offer" v-if="request.offer">
                            <div>
                                <a href="#" @click.prevent="openOffer(request.offer)">{{
                                    request.offer.name[$store.state.lang.displayLanguage]
                                }}</a>
                            </div>
                            <div>{{ request.offer.price | currency }}</div>
                            <div><Process :process="request.offer.process" /></div>
                        </Row>
                        <template v-if="request.config">
                            <Row
                                name="Time slots"
                                v-if="request.config.timeSlots && request.config.timeSlots.length > 0"
                            >
                                <div v-for="(timeSlot, index) in request.config.timeSlots" :key="'timeSlot-' + index">
                                    De {{ moment.unix(timeSlot[0]) | formatDate }} à
                                    {{ moment.unix(timeSlot[1]) | formatDate }}
                                </div>
                            </Row>
                            <Row
                                name="Bookables"
                                v-if="request.config.bookables && request.config.bookables.length > 0"
                            >
                                <div v-for="(bookable, index) in request.config.bookables" :key="'bookable-' + index">
                                    {{ bookable }}
                                </div>
                            </Row>
                            <Row
                                :name="request.payment_intents.length > 1 ? 'Payment intents' : 'Payment intent'"
                                v-if="request && request.payment_intents.length > 0"
                            >
                                <div class="d-flex flex-column">
                                    <a
                                        href="#"
                                        @click.prevent="openPaymentIntent(paymentIntent)"
                                        v-for="(paymentIntent, index) in request.payment_intents"
                                        :key="'paymentIntentLine-' + index"
                                    >
                                        <code>{{ paymentIntent.id }}</code>
                                    </a>
                                </div>
                            </Row>
                            <Row name="Quantity" v-if="request.config.quantity">
                                {{ request.config.quantity }}
                            </Row>
                        </template>
                        <template v-if="request.selected_payment_methods">
                            <Row name="Total">
                                {{ request.selected_payment_methods.amount | currency }}
                            </Row>
                            <Row
                                name="Payment methods"
                                v-if="request.selected_payment_methods.payment_methods.length > 0"
                            >
                                <SelectedPaymentMethod
                                    v-for="(method, index) in request.selected_payment_methods.payment_methods"
                                    :paymentMethod="method"
                                    :key="index"
                                />
                            </Row>
                        </template>
                        <Row name="Bookings" v-if="request.bookings && request.bookings.length > 0">
                            <div v-for="(booking, index) in request.bookings" class="mb-1" :key="'booking-' + index">
                                <a href="#" @click.prevent="openBooking(booking)">Booking {{ booking.id }}</a> -
                                {{ booking.amount | currency }} with status
                                <BookingStatus :status="booking.status"></BookingStatus>
                            </div>
                            <div class="mt-n1"></div>
                        </Row>
                        <Row name="Created at">{{ request.created_at | formatDate }}</Row>
                        <Row name="Last update">{{ request.created_at | formatDate }}</Row>
                    </Sheet>

                    <!-- ID -->
                    <ID name="Hexeko" :value="request.id" />
                </Tab>

                <!-- History -->
                <Tab name="History">
                    <History :statuses="request.statuses" v-slot:default="slotProps">
                        <BookingRequestStatus :status="slotProps.status" />
                    </History>
                </Tab>

                <!-- Transactions -->
                <Tab
                    name="Transactions"
                    v-if="$store.getters.userHasAnyPermission(['showPaymentTransactions', 'managePaymentTransactions'])"
                >
                    <Transactions :params="{ payable_type: 'booking_request', payable_id: request.id }" />
                </Tab>
            </Tabs>
        </template>
    </div>
</template>

<script>
    import {
        Panel,
        Sheet,
        Row,
        Boolean,
        ID,
        Dropdown,
        DropdownItem,
        Address,
        Modal,
        Tabs,
        Tab,
        Table,
        Spinner,
        Button,
        Alerts,
    } from '@tech_hexeko/design-system'
    import BookingRequestStatus from '@/components/BookingRequests/status'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import Process from '@/components/layout/Process'
    import BookingStatus from '@/components/Bookings/status'
    import moment from 'moment'
    import SelectedPaymentMethod from '../layout/SelectedPaymentMethod/index'
    import Transactions from '@/components/PaymentTransactions'
    import History from '@/components/layout/History'
    export default {
        props: {
            id: String,
        },
        data() {
            return {
                loading: true,
                request: null,
                setStatus: null,
                refreshAfterClose: false,
                moment: moment,
                restartLoading: false,
            }
        },
        components: {
            Panel,
            Sheet,
            Row,
            Boolean,
            ID,
            Dropdown,
            DropdownItem,
            Address,
            BookingRequestStatus,
            Modal,
            Process,
            BookingStatus,
            SelectedPaymentMethod,
            Tabs,
            Tab,
            Table,
            Spinner,
            Transactions,
            Button,
            History,
        },
        mixins: [Modals],
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.loading = true
                // Load booking
                this.$api.bookings
                    .showBookingRequest(this.id, {
                        includes: [
                            'user',
                            'offer',
                            'selected_payment_methods',
                            'statuses',
                            'payments_transactions',
                            'payment_intents',
                        ],
                    })
                    .then((request) => {
                        this.request = request
                        this.loading = false
                    })
            },
            openUser(user) {
                this.openPanel('userPanel', user.id)
            },
            openOffer(offer) {
                if (this.$store.getters.userHasPermission('managePartners')) {
                    this.openPanel('offerPanel', offer.id)
                } else {
                    Alerts.notificationError('Missing permission')
                }
            },
            openBooking(booking) {
                this.openPanel('bookingPanel', booking.id)
            },
            restart() {
                this.restartLoading = true
                this.$api.bookings.restart(this.id).then((response) => {
                    setTimeout(() => {
                        this.fetchData()
                        this.restartLoading = false
                    }, 3000)
                })
            },
            openPaymentIntent(intent) {
                this.openPanel('PaymentIntentPanel', intent.id)
            },
        },
        computed: {
            bookingsCreated: function() {
                return this.request && this.request.bookings && this.request.bookings.length > 0
            },
        },
    }
</script>
