<template>
    <Badge :color="color">
        <template v-if="store"> <Icon icon="store" /> {{ store.name }} </template>
        <template v-else>N/A</template>
    </Badge>
</template>

<script>
    import { Badge, Icon } from '@tech_hexeko/design-system'

    export default {
        name: 'Store',
        props: {
            id: Number,
        },
        components: {
            Icon,
            Badge,
        },
        computed: {
            color() {
                return this.store ? 'warning' : 'danger'
            },
            store() {
                return this.$store.getters.getStoreById(this.id)
            },
        },
    }
</script>
