<template>
    <FormRoot :loading="dataLoading">
        <!-- Contract -->
        <FormSectionTitle icon="handshake" title="Contract" />
        <Field
            :form.sync="form"
            type="boolean"
            name="contract_signed"
            placeholder="Contract signed with partner"
        ></Field>
        <template v-if="form.contract_signed">
            <Field
                :form.sync="form"
                type="number"
                name="commission"
                placeholder="Commission"
                hint="Percentage of expenses made at partner retained by Hexeko"
            ></Field>
            <Field :form.sync="form" type="text" name="iban" placeholder="IBAN" hint="IBAN"></Field>
        </template>

        <!-- Integrations -->
        <FormSectionTitle class="mt-4" icon="plug" title="Integrations" />
        <Field
            :form.sync="form"
            type="boolean"
            name="sync_google_maps"
            placeholder="Sync with Google Places"
            hint="Enable data syncing like Opening hours"
        ></Field>
        <Field
            :form.sync="form"
            type="text"
            name="google_maps_id"
            placeholder="Google Places ID"
            hint="Enter Google Places ID"
            v-if="form.sync_google_maps"
        ></Field>

        <FormActions>
            <Submit class="float-right" :loading="form.loading" @click="onSubmit">Update</Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit, Dropzone, InputHint } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import Medias from '@/components/layout/Medias'
    import FormSectionTitle from '@/components/layout/FormSectionTitle'

    export default {
        data() {
            return {
                fields: {
                    sync_google_maps: null,
                    google_maps_id: null,
                    contract_signed: null,
                    iban: null,
                    commission: 0,
                },
                messageSuccess: 'The options has been saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
            Dropzone,
            InputHint,
            Medias,
            FormSectionTitle,
        },
        methods: {
            loadData() {
                return this.$api.partners.show(this.id, { includes: ['external_id'] }).then((partner) => {
                    this.form.fillAsOriginalData({
                        google_maps_id: partner.external_id ? partner.external_id.google_maps_id : null,
                    })
                    return partner
                })
            },
            submit() {
                return this.$api.partners.update(this.id, this.form.notNullData())
            },
        },
    }
</script>
