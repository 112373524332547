export default {
    pending: {
        name: 'Pending',
        color: 'warning',
        icon: 'pause-circle',
    },
    started: {
        name: 'Started',
        color: 'warning',
        icon: 'chevron-circle-right',
    },
    restarted: {
        name: 'Restarted',
        color: 'warning',
        icon: 'chevron-circle-right',
    },
    bookable: {
        name: 'Bookable',
        color: 'warning',
        icon: 'chevron-circle-right',
    },
    cancelled: {
        name: 'Cancelled',
        color: 'danger',
        icon: 'times-circle',
    },
    payment_required_action: {
        name: 'Payment requires action',
        color: 'warning',
        icon: 'exclamation-circle',
    },
    payment_processing: {
        name: 'Payment processing',
        color: 'warning',
        icon: 'play-circle',
    },
    payment_verified: {
        name: 'Payment verified',
        color: 'warning',
        icon: 'chevron-circle-right',
    },
    payment_failed: {
        name: 'Payment failed',
        color: 'danger',
        icon: 'stop-circle',
    },
    payment_successful: {
        name: 'Payment successful',
        color: 'success',
        icon: 'check-circle',
    },
    refund_requested: {
        name: 'Refund requested',
        color: 'dark',
        icon: 'undo-alt',
    },
    refund_successful: {
        name: 'Refund successful',
        color: 'dark',
        icon: 'undo-alt',
    },
    refund_failed: {
        name: 'Refund failed',
        color: 'dark',
        icon: 'undo-alt',
    },
};
