<template>
    <div class="mb-2 text-muted"><Icon :icon="icon" v-if="icon" /> {{ title }}</div>
</template>

<script>
    export default {
        props: {
            title: String,
            icon: String,
        },
    }
</script>
