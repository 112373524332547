<template>
    <div>
        <Datatable
            name="Bookings"
            @search="fetchData"
            :pagination.sync="pagination"
            :loading="loading"
            :hideActions="hideActions"
        >
            <template v-slot:filters>
                <Button
                    class="me-1"
                    @click="filterByStatus(avStatus.status)"
                    :color="status === avStatus.status ? avStatus.color : 'secondary'"
                    small
                    v-for="(avStatus, index) in statuses"
                    :key="index"
                >
                    {{ avStatus.name }}
                </Button>
            </template>
            <template v-slot:actions v-if="$store.getters.userHasPermission('manageBookings')">
                <SelectAllButton
                    :metaSelected="metaSelected"
                    @selectAll="selectAll"
                    @unselectAll="unselectAll"
                    @reset="reset"
                >
                    <DropdownItem icon="check-circle" @click="bulkConfirm">Confirm</DropdownItem>
                    <DropdownItem icon="stop-circle" confirm @confirm="bulkDecline">Decline</DropdownItem>
                    <DropdownItem icon="times-circle" confirm @confirm="bulkCancel">Cancel</DropdownItem>
                </SelectAllButton>
            </template>
            <template v-slot:thead>
                <tr>
                    <th v-if="$store.getters.userHasPermission('manageBookings')"></th>
                    <th>ID</th>
                    <th>Date</th>
                    <th>User</th>
                    <th>Offer</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </template>
            <template v-slot:default>
                <tr
                    v-for="booking in bookings"
                    @click="open(booking)"
                    :class="{ 'cursor-pointer': clickable(booking) }"
                >
                    <td @click.stop="toggleSelect(booking)" v-if="$store.getters.userHasPermission('manageBookings')">
                        <ToggleCheckbox :value="isSelected(booking)" />
                    </td>
                    <td>
                        <code>{{ booking.id }}</code>
                    </td>
                    <td>{{ booking.created_at | formatDate }}</td>
                    <td>
                        <div v-if="booking.booker">{{ booking.booker.name }}</div>
                    </td>
                    <td>
                        <div v-if="booking.product">
                            <div v-if="booking.product.activity">
                                {{ booking.product.activity.name[$store.state.lang.displayLanguage] }}
                            </div>
                            <div>{{ booking.product.name[$store.state.lang.displayLanguage] }}</div>
                        </div>
                        <div class="text-muted" v-for="time_slot in booking.time_slots">
                            {{ $options.filters.dateRangeHumanReadable(time_slot.start, time_slot.end) }}
                        </div>
                    </td>
                    <td>{{ booking.amount | currency }}</td>
                    <td><BookingStatus :status="booking.status" /></td>
                    <td class="text-right"><Icon icon="angle-right" /></td>
                </tr>
            </template>
        </Datatable>
    </div>
</template>

<script>
    import { Datatable, Button, Boolean, Dropdown, DropdownItem, ToggleCheckbox } from '@tech_hexeko/design-system'
    import BookingStatus from '@/components/Bookings/status'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import BookingRequests from '@/components/BookingRequests'
    import SelectAll from '../../mixins/SelectAll'
    import SelectAllButton from '@/components/layout/SelectAllButton'

    export default {
        name: 'Bookings',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                search: null,
                timer: null,
                status: null,
                pagination: {
                    current_page: 1,
                },
                bookings: [],
                statuses: [
                    {
                        name: 'Confirmed',
                        status: 'confirmed',
                        color: 'success',
                    },
                    {
                        name: 'Pending',
                        status: 'pending',
                        color: 'warning',
                    },
                    {
                        name: 'Declined',
                        status: 'declined',
                        color: 'danger',
                    },
                    {
                        name: 'Cancelled',
                        status: 'cancelled',
                        color: 'danger',
                    },
                ],
            }
        },
        components: {
            Dropdown,
            DropdownItem,
            Datatable,
            BookingStatus,
            BookingRequests,
            Button,
            SelectAllButton,
            ToggleCheckbox,
        },
        mixins: [SelectAll],
        mounted() {
            this.enableAutoRefresh()
            this.fetchData().then(() => {
                this.loading = false
            })
        },
        destroyed() {
            this.disableAutoRefresh()
        },
        methods: {
            enableAutoRefresh() {
                this.timer = setInterval(() => {
                    this.fetchData(this.search)
                }, 15 * 1000)
            },
            disableAutoRefresh() {
                clearInterval(this.timer)
            },
            fetchData(search) {
                this.search = search
                let params = this.mergeParams({
                    search: this.search,
                    status: this.status,
                    page: this.pagination.current_page,
                    order_by: 'created_at',
                    sort_by: 'desc',
                })
                return this.$api.bookings.get(params).then((response) => {
                    this.bookings = response.data
                    this.pagination = response.meta.pagination
                })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            open(booking) {
                if (!this.clickable(booking)) return false

                let panel = this.openPanel('BookingPanel', booking.id)
                panel.promise.then((result) => {
                    if (result) {
                        if (result.cancelled) {
                            this.filterByStatus('cancelled')
                        } else if (result.refresh) {
                            this.fetchData(this.search)
                        }
                    }
                })
            },
            clickable(booking) {
                return booking.status !== 'cancelled'
            },
            filterByStatus(status) {
                this.loading = true
                this.status = this.status === status ? null : status
                this.fetchData().then(() => {
                    this.loading = false
                })
            },
            allIds() {
                return this.$options.filters.pluck(this.bookings, 'id')
            },
            bulkConfirm() {
                this.bulkAction('Confirm bookings', (id) => {
                    return this.$api.bookings.confirm(id)
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            bulkDecline() {
                this.bulkAction('Decline bookings', (id) => {
                    return this.$api.bookings.decline(id)
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            bulkCancel() {
                this.bulkAction('Cancel bookings', (id) => {
                    return this.$api.bookings.cancel(id)
                }).then(() => {
                    this.reset()
                    this.fetchData(this.search)
                })
            },
        },
    }
</script>
