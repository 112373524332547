import { Tabs, Tab } from '@tech_hexeko/design-system';
let _ = require('lodash');

export default {
    props: {
        params: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            activeComponent: null,
        };
    },
    components: {
        Tabs,
        Tab,
    },
    mounted() {
        this.loadDefaultTab();
    },
    methods: {
        selected(route, defaultVal) {
            if (!this.embedInTab) {
                return this.$route.matched.some(({ name }) => name === route);
            } else {
                return defaultVal;
            }
        },
        selectTab(tab) {
            if (!this.embedInTab) {
                this.$router.push(tab.$attrs.to).catch(() => {});
            } else {
                let route = this.$router.resolve(tab.$attrs.to);
                this.loadComponent(route.route.matched[1].components.default);
            }
        },
        loadComponent(componentName) {
            this.activeComponent = componentName;
        },
        loadDefaultTab() {
            this.loadComponent(this.defaultTab);
        },
    },
    computed: {
        embedInTab: function() {
            return !!(this.params.user || this.params.partner || this.params.group);
        },
        defaultTab: function() {
            let tabs = this.$refs.embedTabs;
            if (tabs) {
                return tabs.tabs[0];
            }
        },
    },
};
