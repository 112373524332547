<template>
    <tr class="cursor-pointer" @click="$emit('click', type)">
        <td>
            <code>{{ type.id }}</code>
        </td>
        <td :class="{ 'ps-5': child }">{{ type.name[$store.state.lang.displayLanguage] }}</td>
        <td class="text-right"><Icon icon="angle-right" /></td>
    </tr>
</template>

<script>
    export default {
        props: {
            type: Object,
            child: Boolean,
        },
    }
</script>
