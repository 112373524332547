<template>
    <FormRoot>
        <!-- Type -->
        <Field
            :form.sync="form"
            type="radio"
            name="license_type"
            placeholder="Type"
            hint="Type of the license"
            :options="{ group_account: 'Group Account' }"
        ></Field>

        <!-- Billing Accounts -->
        <Field
            :form.sync="form"
            type="radio"
            name="billing_account_id"
            placeholder="Type"
            hint="Choose billing account"
            :options="billingAccounts"
            optionsLabel="name"
            optionsKeyBy="id"
        ></Field>

        <!-- Button -->
        <FormActions>
            <Submit class="float-right" :disabled="!group || !form.submittable" @click="onSubmit"
                >Create license</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        props: {
            group: {
                type: Number,
                required: true,
            },
            billingAccounts: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                fields: {
                    license_type: null,
                    billing_account_id: null,
                },
                messageSuccess: 'The license has been created',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            submit() {
                return this.$api.groups.createLicense(this.group, this.$data.form.notNullData())
            },
        },
    }
</script>
