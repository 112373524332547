<template>
    <Panel title="Payout" :loading="loading" @closePanel="closePanel">
        <Tabs v-if="payout">
            <Tab name="Payout" :selected="true">
                <div class="mb-2">
                    <PayoutStatus :status="payout.status" />
                </div>
                <Sheet name="Details">
                    <Row name="Driver">
                        <span class="text-capitalize">{{ payout.driver }}</span>
                    </Row>
                    <Row name="Amount">{{ payout.amount | currency }}</Row>
                    <Row name="Sum of Payout Requests">{{ payout.payout_requests_total_amount | currency }}</Row>
                </Sheet>
                <Sheet name="Date">
                    <Row name="Created at">{{ payout.created_at | formatDate }}</Row>
                    <Row name="Updated at">{{ payout.updated_at | formatDate }}</Row>
                </Sheet>

                <ID name="Hexeko" :value="payout.id" />
                <ID v-if="payout.external_id" name="External" :value="payout.external_id" />
            </Tab>

            <!-- Requests -->
            <Tab name="Requests">
                <PayoutRequests :params="{ payout_id: payout.id }" />
            </Tab>

            <!-- History -->
            <Tab name="History">
                <History :statuses="payout.statuses" v-slot:default="slotProps">
                    <PayoutStatus :status="slotProps.status" />
                </History>
            </Tab>

            <!-- Accounts -->
            <Tab name="Accounts" v-if="payout.payment_accounts">
                <Sheet name="Target" v-if="payout.payment_accounts.target">
                    <Row name="IBAN" v-if="payout.payment_accounts.target.iban">
                        •••• {{ payout.payment_accounts.target.iban }}
                    </Row>
                    <Row name="BIC" v-if="payout.payment_accounts.target.bic">
                        {{ payout.payment_accounts.target.bic }}
                    </Row>
                    <Row name="Holder name" v-if="payout.payment_accounts.target.holderName">
                        {{ payout.payment_accounts.target.holderName }}
                    </Row>
                </Sheet>
                <Sheet name="Origin" v-if="payout.payment_accounts.origin">
                    <Row name="IBAN" v-if="payout.payment_accounts.origin.iban">
                        •••• {{ payout.payment_accounts.origin.iban }}
                    </Row>
                    <Row name="BIC" v-if="payout.payment_accounts.origin.bic">
                        {{ payout.payment_accounts.origin.bic }}
                    </Row>
                    <Row name="Holder name" v-if="payout.payment_accounts.origin.holderName">
                        {{ payout.payment_accounts.origin.holderName }}
                    </Row>
                </Sheet>
            </Tab>
        </Tabs>
    </Panel>
</template>

<script>
    import { Panel, Tabs, Tab, Sheet, Row, ID, Badge } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import PayoutRequests from '@/components/PayoutRequests'
    import PayoutStatus from '../PayoutStatus'
    import History from '@/components/layout/History'

    export default {
        name: 'PayoutPanel',
        props: {
            id: String,
        },
        data() {
            return {
                loading: true,
                payout: null,
            }
        },
        components: {
            Badge,
            Panel,
            Sheet,
            Row,
            Tabs,
            Tab,
            ID,
            PayoutStatus,
            PayoutRequests,
            History,
        },
        mixins: [Modals],
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.loading = true
                this.$api.payouts
                    .show(this.id, {
                        includes: ['statuses'],
                    })
                    .then((response) => {
                        this.payout = response
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            closePanel(args) {
                this.$emit('closePanel', args)
            },
        },
        computed: {},
    }
</script>
