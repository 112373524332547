<template>
    <Datatable
        name="Products"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
        :hover="false"
    >
        <template v-slot:thead>
            <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Price</th>
                <th v-if="billingAccount"></th>
            </tr>
        </template>
        <tr v-for="(product, index) in products" :key="'product-' + index">
            <td>
                <code>{{ product.slug }}</code>
            </td>
            <td>{{ product.description }}</td>
            <td class="text-uppercase">{{ product.price.amount }} {{ product.price.currency }}</td>
            <td width="20%" v-if="billingAccount">
                <div class="input-group input-group-sm">
                    <input
                        @input="(value) => setPrice(product, value.target.value)"
                        type="text"
                        class="form-control"
                        placeholder="Price in € "
                    />
                    <Button @click="savePrice(product)" :disabled="!prices[product.id]"><Icon icon="save"/></Button>
                </div>
            </td>
        </tr>
    </Datatable>
</template>

<script>
    import { Datatable, Modal, Button, Dropdown, DropdownItem, Boolean, Badge, Input } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'

    export default {
        name: 'Products',
        props: {
            hideActions: Boolean,
            billingAccount: String,
        },
        data() {
            return {
                loading: true,
                pagination: {
                    current_page: 1,
                },
                products: [],
                prices: {},
            }
        },
        components: {
            Datatable,
            Dropdown,
            DropdownItem,
            Modal,
            Button,
            Boolean,
            Badge,
            Input,
        },
        mixins: [Modals],
        mounted() {
            this.loading = true
            this.fetchData()
        },
        methods: {
            fetchData(search) {
                let params = {
                    name: search,
                    page: this.pagination.current_page,
                }
                let searchFunction = this.billingAccount
                    ? this.$api.billing.billingAccounts.getProducts(this.billingAccount, params)
                    : this.$api.billing.products.get(params)

                searchFunction.then((response) => {
                    this.products = response.data
                    this.pagination = response.meta.pagination
                    this.loading = false
                })
            },
            setPrice(product, price) {
                this.$set(this.prices, product.id, price)
            },
            savePrice(product) {
                this.$api.billing.billingAccounts
                    .setProductPrice(this.billingAccount, product.id, {
                        currency: 'eur',
                        amount_decimal: this.prices[product.id],
                    })
                    .then(() => {
                        setTimeout(this.fetchData, 500)
                        this.setPrice(product, 0)
                    })
            },
        },
    }
</script>
