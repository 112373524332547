<template>
    <FormRoot>
        <!-- Source -->
        <Field
            :form.sync="form"
            type="radio"
            placeholder="Source"
            name="source"
            :options="sourceOptions"
            hint="Who is the creditor of the wallet ?"
            @input="form.errors.clear('group')"
            :multiple="false"
        ></Field>

        <!-- Group -->
        <template v-if="form.source === 'group'">
            <template v-if="user">
                <Alert v-if="!groups || groups.length === 0">No groups</Alert>
                <Field
                    v-else
                    :form.sync="form"
                    type="radio"
                    component="Groups"
                    name="group"
                    :options="groups"
                    optionsKeyBy="id"
                    optionsLabel="name"
                    placeholder="Group"
                    hint="Enter the group name"
                ></Field>
            </template>
            <Field
                v-else
                :form.sync="form"
                type="component"
                component="Groups"
                name="group"
                placeholder="Group"
                hint="Which group fund this wallet ?"
                :searchFunction="searchGroups"
            ></Field>
        </template>
        <hr />
        <div class="my-2">Types of wallet</div>
        <TypesSelector :depth="0" @input="chooseTypes" :saveButton="false" />
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" @click="onSubmit">Create wallet</Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit, Alert } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import TypesSelector from '../Types/Selector'

    export default {
        props: {
            user: {
                type: Number,
                required: false,
            },
        },
        data() {
            return {
                fields: {
                    source: null,
                    group: null,
                    types: [],
                },
                groups: [],
                allTypes: [],
                messageSuccess: 'The wallet has been created',
                sourceOptions: {
                    user: 'The user himself',
                    group: 'A group',
                },
            }
        },
        mixins: [Forms],
        components: {
            Alert,
            FormRoot,
            FormActions,
            Submit,
            Field,
            TypesSelector,
        },
        mounted() {
            this.form.source = 'user'
            this.fetchData()
        },
        methods: {
            submit() {
                let data = this.form.source === 'group' ? { group: this.$data.form.group } : {}
                return this.$api.users.createWallet(this.user, {
                    ...data,
                    ...{ types: this.$data.form.types },
                })
            },
            fetchData() {
                this.$api.types.get({ per_page: 100, depth: 1 }).then((types) => {
                    this.allTypes = types.data
                })
            },
            fetchGroups() {
                this.$api.groups.get({ user: this.user }).then((response) => {
                    this.groups = response.data
                })
            },
            searchGroups(query) {
                return this.$api.groups.get({
                    search: query,
                })
            },
            chooseTypes(types) {
                this.form.types = types
            },
        },
        watch: {
            'form.source'(source) {
                if (source === 'group') {
                    this.fetchGroups()
                }
            },
        },
    }
</script>
