<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 20" fill="none">
        <path
            d="M14.87 16.836a3.55 3.55 0 0 0 1.668-1.52 3.598 3.598 0 0 0 .426-2.227L15.548 2.475a2.87 2.87 0 0 0-.942-1.766A2.824 2.824 0 0 0 12.746 0h-8.49a2.824 2.824 0 0 0-1.862.708 2.871 2.871 0 0 0-.944 1.765L.035 13.09c-.102.77.048 1.552.426 2.228a3.55 3.55 0 0 0 1.671 1.52L.216 18.772a.717.717 0 0 0-.009 1.018.704.704 0 0 0 1.01-.009l2.623-2.646h9.32l2.623 2.646a.705.705 0 0 0 1.01.009.717.717 0 0 0-.009-1.018l-1.915-1.937ZM2.636 4.284h11.727l.758 5.712H1.88l.758-5.712Zm1.618 10.71c-.375 0-.735-.15-1-.418a1.434 1.434 0 0 1 0-2.02 1.408 1.408 0 0 1 2 0 1.434 1.434 0 0 1 0 2.02 1.408 1.408 0 0 1-1 .418Zm7.076-1.428c0-.379.15-.742.415-1.01a1.408 1.408 0 0 1 2 0 1.434 1.434 0 0 1 0 2.02 1.408 1.408 0 0 1-2 0 1.434 1.434 0 0 1-.415-1.01Z"
            fill="#000"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-public-transport-passes',
    }
</script>
