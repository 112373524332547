<template>
    <div>
        <div class="mb-3" v-if="file || loading">
            <Spinner v-if="loading" />
            <div v-if="file">
                <a :href="file.path" target="_blank"
                    ><Icon icon="file-alt" /> {{ file.file_name }} ({{ file.human_readable_size }})</a
                >
            </div>
        </div>
        <div class="mb-4">
            <Medias @input="inputFile" :multiple="false" ref="medias" :uploadFunction="uploadFile" />
            <Error v-if="error" :error="error" />
        </div>
        <Submit @click="uploadFile" :disabled="!new_file" :loading="uploading">Update file</Submit>
    </div>
</template>

<script>
    import s3Class from '@tech_hexeko/shared/src/plugins/s3'
    import { Submit, Error, Spinner, Alerts } from '@tech_hexeko/design-system'
    import Medias from '@/components/layout/Medias'
    import { Auth } from 'aws-amplify'

    export default {
        data() {
            return {
                file: null,
                new_file: null,
                error: null,
                loading: false,
                uploading: false,
                s3: null,
            }
        },
        components: {
            Medias,
            Submit,
            Error,
            Spinner,
        },
        props: {
            id: String,
        },
        methods: {
            fetchFile() {
                this.loading = true
                this.$api.expenseRequests.show(this.id, { includes: ['media'] }).then((response) => {
                    this.file = response.media
                    this.loading = false
                })
            },
            inputFile(file) {
                this.new_file = file[0]
                this.error = null
            },
            uploadFile() {
                this.uploading = true
                Auth.currentAuthenticatedUser()
                    .then((cognitoUser) => {
                        // Upload file through Vapor
                        this.s3 = new s3Class(cognitoUser.signInUserSession.accessToken.jwtToken)
                        return this.s3
                            .store(this.new_file.file)
                            .then((response) => {
                                this.$api.medias
                                    .uploadToDrive('s3', {
                                        key: response.key,
                                        name: this.new_file.file.name,
                                    })
                                    .then((media) => {
                                        this.$api.expenseRequests
                                            .addMedia(this.id, {
                                                media: media.id,
                                            })
                                            .then(() => {
                                                this.$emit('submitted')
                                            })
                                            .finally(() => {
                                                this.uploading = false
                                            })
                                    })
                                    .catch((error) => {
                                        this.error = error.response.data.errors.file[0]
                                        this.uploading = false
                                    })
                            })
                            .catch((error) => {
                                this.error = error.response.data.errors.file[0]
                            })
                    })
                    .catch(() => {
                        Alerts.notificationError('You are not logged in')
                        this.uploading = false
                    })
            },
        },
        watch: {
            id: {
                immediate: true,
                handler() {
                    this.fetchFile()
                },
            },
        },
    }
</script>
