<template>
    <FormRoot>
        <Field
            :form.sync="form"
            type="date"
            name="from"
            placeholder="Available from"
            hint="Enter start of availability"
        ></Field>
        <Field
            :form.sync="form"
            type="date"
            name="to"
            placeholder="Available to"
            hint="Enter end of availability"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit"
                >Update range</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        props: {
            offer: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                fields: {
                    from: null,
                    to: null,
                },
                messageSuccess: 'The date range has been saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            submit() {
                return this.$api.offers.setDateRange(this.offer, this.$data.form.data())
            },
        },
    }
</script>
