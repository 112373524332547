var _ = require('lodash');
export default _.merge(
    {},
    {
        fr: {
            ...require('@tech_hexeko/shared/src/lang/fr.json'),
            ...require('./fr'),
        },
        nl: {
            ...require('@tech_hexeko/shared/src/lang/nl.json'),
            ...require('./nl'),
        },
        en: {
            ...require('@tech_hexeko/shared/src/lang/en.json'),
            ...require('./en'),
        },
    }
);
