<template>
    <Badge v-if="status" :color="getStatus.color" :large="false" :icon="getStatus.icon">{{ getStatus.name }}</Badge>
</template>
<script>
    import { Badge } from '@tech_hexeko/design-system'

    export default {
        name: 'PaymentIntentStatus',
        props: {
            status: Object,
        },
        components: {
            Badge,
        },
        computed: {
            getStatus() {
                switch (this.status.name) {
                    case 'payment_required_payment_method':
                        return {
                            name: 'Required payment method',
                            icon: 'check-circle',
                            color: 'warning',
                        }
                    case 'payment_required_action':
                        return {
                            name: 'Required action',
                            icon: 'check-circle',
                            color: 'warning',
                        }
                    case 'payment_processing':
                        return {
                            name: 'Processing',
                            icon: 'check-circle',
                            color: 'warning',
                        }
                    case 'payment_verified':
                        return {
                            name: 'Payment verified',
                            icon: 'check-circle',
                            color: 'success',
                        }
                    case 'payment_successful':
                        return {
                            name: 'Payment successful',
                            icon: 'check-circle',
                            color: 'success',
                        }
                    case 'payment_failed':
                        return {
                            name: 'Payment failed',
                            icon: 'check-circle',
                            color: 'danger',
                        }
                    case 'refund_successful':
                        return {
                            name: 'Refund successful',
                            icon: 'check-circle',
                            color: 'success',
                        }
                    case 'refund_failed':
                        return {
                            name: 'Refund failed',
                            icon: 'check-circle',
                            color: 'danger',
                        }
                    case 'switch_payment_methods_successful':
                        return {
                            name: 'Switch successful',
                            icon: 'check-circle',
                            color: 'success',
                        }
                    case 'switch_payment_methods_failed':
                        return {
                            name: 'Switch failed',
                            icon: 'check-circle',
                            color: 'danger',
                        }
                    default:
                        return {
                            name: 'Default',
                            icon: 'check-circle',
                            color: 'primary',
                        }
                }
            },
        },
    }
</script>
