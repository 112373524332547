<template>
    <svg viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M32.561 6.885h-7.95V4.392c0-2.414-1.84-4.392-4.084-4.392H16c-2.245 0-4.085 1.978-4.085 4.392v2.493H4.002C2.235 6.885.8 8.428.8 10.327v19.23C.8 31.458 2.235 33 4.002 33h28.596c1.767 0 3.202-1.543 3.202-3.442v-19.23c-.037-1.9-1.472-3.443-3.239-3.443ZM15.153 4.392c0-.514.405-.95.884-.95h4.527c.478 0 .883.436.883.95v2.493h-6.294V4.392Zm13.029 16.342a5.641 5.641 0 0 1-3.791 1.306l-4.306-.198-2.245 5.262c-.331.752-.92 1.267-1.62 1.425-.294.079-.551-.198-.515-.515l.737-6.37-5.705-.277-.736 1.939c-.22.554-.7.91-1.251.91h-.332c-.257 0-.441-.238-.368-.515l.663-3.125c.11-.475.11-.95 0-1.425l-.7-3.047c-.036-.277.148-.514.369-.514h.33c.553 0 1.031.356 1.252.91l.736 1.939 5.705-.277-.736-6.37a.455.455 0 0 1 .515-.515c.7.198 1.325.712 1.62 1.424l2.244 5.263 4.306-.198c1.362-.04 2.724.396 3.791 1.306.552.475.552 1.266.037 1.662Z"
            fill="#000"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-holidays',
    }
</script>
