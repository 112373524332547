<template>
    <Badge v-if="value.status" color="success"> <Icon icon="store" /> Available </Badge>
    <Badge v-else color="secondary"> <Icon icon="store-alt-slash" /> {{ reason }} </Badge>
</template>

<script>
    import { Badge } from '@tech_hexeko/design-system'

    export default {
        props: {
            value: {
                type: Object,
                required: true,
            },
        },
        components: {
            Badge,
        },
        computed: {
            reason: function() {
                switch (this.value.reason) {
                    case 'not_yet_available':
                        return 'Not yet available'
                    case 'no_more_available':
                        return 'No more available'
                    case 'no_more_seats_available':
                        return 'No more seats available'
                    case 'no_more_time_slots_available':
                        return 'No more time slots available'
                    default:
                        return this.value.reason
                }
            },
        },
    }
</script>
