<template>
    <FormRoot :loading="dataLoading">
        <Alert v-if="paymentMethods && paymentMethods.length === 0" color="warning" icon="exclamation-circle">
            No payment methods selected by the user for this payment intent
        </Alert>
        <template v-else>
            <!-- Alert -->
            <Alert color="warning" icon="exclamation-triangle"
                >When you add a payment, be sure that money has been received, otherwise the payment intent will be
                offered.</Alert
            >

            <!-- Payment Method -->
            <div
                class="border p-2 mb-1 rounded"
                v-for="(payment_method, index) in paymentMethods"
                @click.prevent="togglePaymentMethod(payment_method)"
                :class="isSelected(payment_method) ? 'border-primary bg-light' : 'border-grey'"
                :key="'paymentMethod-' + index"
            >
                <SelectedPaymentMethod
                    :paymentMethod="payment_method"
                    :clickable="false"
                    :smallIcons="true"
                    role="button"
                />
            </div>
            <Error v-if="form.errors.has('payment_method')" :error="form.errors.get('payment_method')" />
            <InputHint class="mb-2">Select the payment method used for this transaction</InputHint>

            <!-- External ID -->
            <Field
                :form.sync="form"
                type="text"
                name="external_id"
                placeholder="External ID"
                hint="Enter the ID of the transaction given by payment provider"
            ></Field>

            <!-- Actions -->
            <FormActions>
                <Submit class="float-right" :disabled="isSubmitDisabled" :loading="form.loading" @click="onSubmit"
                    >Add a transaction</Submit
                >
            </FormActions>
        </template>
    </FormRoot>
</template>

<script>
    import { Alert, Error, FormRoot, Field, FormActions, InputHint, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import SelectedPaymentMethod from '../layout/SelectedPaymentMethod'

    export default {
        components: {
            Alert,
            Error,
            FormRoot,
            FormActions,
            Field,
            InputHint,
            Submit,
            SelectedPaymentMethod,
        },
        mixins: [Forms],
        data() {
            return {
                fields: {
                    payment_method: null,
                    external_id: null,
                },
                messageSuccess: 'The transaction has been added',
                paymentMethods: null,
            }
        },
        computed: {
            isSubmitDisabled() {
                return (
                    !this.form.payment_method ||
                    !this.form.external_id ||
                    (this.form.external_id && !this.form.external_id.trim()) ||
                    !this.form.submittable
                )
            },
        },
        methods: {
            submit() {
                return this.$api.paymentIntents.addPaymentTransactions(this.id, this.form.data())
            },
            loadData() {
                return this.$api.paymentIntents
                    .show(this.id, { includes: ['selected_payment_methods'] })
                    .then((response) => {
                        this.paymentMethods = response.selected_payment_methods.payment_methods
                        return response
                    })
                    .finally(() => {
                        this.dataLoading = false
                    })
            },
            togglePaymentMethod(payment_method) {
                if (this.isSelected(payment_method)) {
                    this.unselectPaymentMethod()
                } else {
                    this.selectPaymentMethod(payment_method)
                }
            },
            selectPaymentMethod(payment_method) {
                this.setPaymentMethod(payment_method.payment_method.id)
            },
            unselectPaymentMethod() {
                this.setPaymentMethod(null)
            },
            setPaymentMethod(val) {
                this.form.payment_method = val
                this.form.keyUp()
                this.form.errors.clear('payment_method')
            },
            isSelected(payment_method) {
                return this.form.payment_method === payment_method.payment_method.id
            },
        },
    }
</script>
