<template>
    <FormRoot :loading="dataLoading">
        <!-- MID -->
        <Card title="MID's" noPadding>
            <div v-for="(mid, index) in form.mids" class="row align-items-center mb-2">
                <div class="col-11">
                    <Input v-model="form.mids[index]" placeholder="Merchant ID" class="border" />
                </div>
                <div class="col-1 align-items-center">
                    <a href="#" @click.prevent="removeMid(index)" class="align-items-center"><Icon icon="times"/></a>
                </div>
            </div>
            <Alert v-if="form.mids.length === 0">No MID's defined</Alert>
            <Button @click="addMid" class="me-1" color="secondary"><Icon icon="plus" /> Add MID</Button>
        </Card>
        <!-- Countries -->
        <Card title="Countries" class="mt-4" noPadding>
            <Alert icon="check" color="success" class="mb-2">
                <span v-if="form.countries.length === 0">
                    All countries allowed if none checked
                </span>
                <span v-else>{{ implodedCountries }} allowed</span>
            </Alert>
            <Checkbox
                v-model="form.countries"
                :modelValue="country"
                v-for="country in countries"
                :key="country"
                class="mb-1"
                >{{ country }}</Checkbox
            >
        </Card>
        <div class="mt-2">
            <Submit :disabled="form.loading" @click="onSubmit">Save</Submit>
        </div>
    </FormRoot>
</template>

<script>
    import {
        FormRoot,
        Field,
        InputHint,
        Card,
        Input,
        Submit,
        Button,
        Alert,
        Checkbox,
    } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    let _ = require('lodash')

    export default {
        data() {
            return {
                countries: ['BE', 'FR'],
                fields: {
                    mids: [],
                    countries: [],
                },
                messageSuccess: 'The issuing settings have been saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            Field,
            InputHint,
            Card,
            Input,
            Submit,
            Button,
            Alert,
            Checkbox,
        },
        methods: {
            loadData() {
                return this.$api.partners.show(this.id, { includes: ['countries'] }).then((partner) => {
                    return {
                        mids: partner.mids ? partner.mids : [],
                        countries: partner.countries ? partner.countries : [],
                    }
                })
            },
            submit() {
                return this.$api.partners.update(this.id, this.$data.form.data())
            },
            addMid() {
                this.form.mids.push(null)
            },
            removeMid(index) {
                this.form.mids.splice(index, 1)
            },
        },
        computed: {
            implodedCountries: function() {
                return _.join(this.form.countries, ', ')
            },
        },
    }
</script>
