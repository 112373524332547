<template>
    <Badge v-if="group.external_id && group.external_id.lead_id" :large="large">Lead<span v-if="suffix"> {{ suffix }}</span></Badge>
    <Badge v-else :large="large" color="secondary">Manual<span v-if="suffix"> {{ suffix }}</span></Badge>
</template>
<script>
    import { Badge } from '@tech_hexeko/design-system'

    export default {
        name: 'GroupSource',
        props: {
            group: Object,
            large: Boolean,
            suffix: String
        },
        components: {
            Badge,
        },
    }
</script>
