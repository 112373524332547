export default {
    requested: {
        name: 'Requested',
        color: 'secondary',
        icon: 'pause-circle',
    },
    created: {
        name: 'Created',
        color: 'secondary',
        icon: 'pause-circle',
    },
    pending: {
        name: 'Pending',
        color: 'warning',
        icon: 'question-circle',
    },
    need_rectification: {
        name: 'Waiting rectification',
        color: 'dark',
        icon: 'exclamation-circle',
    },
    rectified: {
        name: 'Rectified',
        color: 'secondary',
        icon: 'pause-circle',
    },
    auto_confirm_successful: {
        name: 'Auto-confirmed',
        color: 'success',
        icon: 'robot',
    },
    auto_confirm_failed: {
        name: 'Auto-confirmed failed',
        color: 'danger',
        icon: 'robot',
    },
    cancelled: {
        name: 'Cancelled',
        color: 'danger',
        icon: 'times-circle',
    },
    confirmed: {
        name: 'Confirmed',
        color: 'success',
        icon: 'chevron-circle-right',
    },
    declined: {
        name: 'Declined',
        color: 'danger',
        icon: 'stop-circle',
    },
    deleted: {
        name: 'Deleted',
        color: 'danger',
        icon: 'trash',
    },
    debit_successful: {
        name: 'Debit successful',
        color: 'success',
        icon: 'chevron-circle-right',
    },
    debit_failed: {
        name: 'Payment failed',
        color: 'danger',
        icon: 'exclamation-circle',
    },
    debit_pending: {
        name: 'Debit pending',
        color: 'warning',
        icon: 'question-circle',
    },
    retry_to_debit: {
        name: 'Retrying to debit',
        color: 'secondary',
        icon: 'redo',
    },
    payout_successful: {
        name: 'Payout successful',
        color: 'success',
        icon: 'check-circle',
    },
    payout_failed: {
        name: 'Payout failed',
        color: 'danger',
        icon: 'exclamation-circle',
    },
};
