<template>
    <FormRoot>
        <Field
            :form.sync="form"
            name="amount"
            placeholder="Amount in €"
            hint="Enter a positive amount in euros"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" @click="onSubmit">Withdraw</Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        props: {
            wallet: {
                type: Number,
                required: false,
            },
        },
        data() {
            return {
                fields: {
                    amount: null,
                },
                messageSuccess: 'The amount has been withdrawed from wallet',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            submit() {
                return this.$api.wallets.withdraw(this.wallet, this.$data.form.data())
            },
        },
    }
</script>
