<template>
    <Badge v-if="quote.accepted_at" color="success" :large="large" icon="check-circle">Accepted</Badge>
    <Badge v-else-if="!quote.finalized_at && !quote.accepted_at" color="secondary" :large="large" icon="file-contract"
        >Draft</Badge
    >
    <Badge v-else color="warning" :large="large" icon="file-contract">Quote</Badge>
</template>
<script>
    import { Badge } from '@tech_hexeko/design-system'

    export default {
        name: 'QuoteStatus',
        props: {
            quote: Object,
            large: Boolean,
        },
        components: {
            Badge,
        },
    }
</script>
