<template>
    <Panel :title="title" @closePanel="closePanel">
        <Progress :total="selected.length" :count="done.length" color="success" class="mb-2" />
        <Button color="primary" @click="start" icon="play-circle" :loading="anyLoading" :disabled="allDone"
            >Start for {{ remaining.length }} remaining</Button
        >
        <Button
            v-if="allowExport"
            class="ms-2"
            color="primary"
            @click="exportData"
            icon="download"
            :loading="anyLoading"
            :disabled="!allDone && !enableDownload"
            >Export data</Button
        >
        <ul class="list-group mt-2">
            <li
                class="list-group-item border-0"
                v-for="(id, index) in selected"
                :key="index"
                :class="
                    typeof results[id] === 'undefined'
                        ? 'list-group-item-secondary'
                        : results[id].success
                        ? 'list-group-item-success'
                        : 'list-group-item-danger'
                "
            >
                <template v-if="results[id]">
                    <div v-if="results[id].success">
                        <Icon icon="check-circle" /> <strong>Success for #{{ id }}</strong> {{ results[id].message }}
                    </div>
                    <div v-else>
                        <Icon icon="times-circle" /> <strong>Failed for #{{ id }}</strong> {{ results[id].message }}
                    </div>
                </template>
                <template v-else>
                    <Spinner v-if="loading.includes(id)" /><Icon icon="pause-circle" v-else /> Pending for #{{ id }}
                </template>
            </li>
        </ul>
    </Panel>
</template>

<script>
    import { Button, Panel, Progress, Spinner } from '@tech_hexeko/design-system'

    export default {
        name: 'BulkActionsPanel',
        components: {
            Button,
            Panel,
            Progress,
            Spinner,
        },
        props: {
            allowExport: {
                type: Boolean,
                default: false,
            },
            callback: Function,
            selected: Array,
            title: String,
        },
        data() {
            return {
                done: [],
                enableDownload: false,
                file: null,
                fileURL: null,
                loading: [],
                results: {},
            }
        },
        computed: {
            remaining: function() {
                return this.selected.filter((id) => {
                    return !this.done.includes(id)
                })
            },
            allDone: function() {
                return this.remaining.length === 0
            },
            anyLoading: function() {
                return this.loading.length > 0
            },
        },
        methods: {
            closePanel(args) {
                this.$emit('closePanel', args)
            },
            exportData() {
                const link = document.createElement('a')
                link.href = this.fileURL
                link.setAttribute('download', 'export-bulk-data-' + Date.now() + '.csv')
                document.body.appendChild(link)
                link.click()
            },
            start() {
                this.remaining.forEach((id, index) => {
                    setTimeout(() => {
                        this.startLoading(id)
                        this.callback(id)
                            .then((response) => {
                                this.results[id] = {
                                    message: response && response.data ? response.data : 'Success',
                                    success: true,
                                }
                            })
                            .catch((error) => {
                                this.results[id] = {
                                    message:
                                        error && error.response && error.response.data
                                            ? error.response.data.message
                                            : 'An error occurred',
                                    success: false,
                                }
                            })
                            .finally(() => {
                                this.done.push(id)
                                this.stopLoading(id)
                            })
                    }, index * 200)
                })
            },
            startLoading(id) {
                this.loading.push(id)
            },
            stopLoading(id) {
                const index = this.loading.indexOf(id)
                if (index > -1) {
                    this.loading.splice(index, 1)
                }
            },
            writeFile() {
                if (Object.keys(this.results).length < 1) return
                this.file = 'id,message\n'
                const ids = Object.keys(this.results)
                ids.forEach((id) => {
                    this.file += `${id},${this.results[id].message}\n`
                })
                this.fileURL = window.URL.createObjectURL(new Blob([this.file], { type: 'text/csv;charset=utf-8,' }))
                this.enableDownload = true
            },
        },
        watch: {
            allDone: {
                handler(allDone) {
                    if (allDone) this.writeFile()
                },
            },
        },
    }
</script>
