<template>
    <FormRoot>
        <Field
            :form.sync="form"
            type="component"
            component="Users"
            name="user_id"
            :searchFunction="fetchUsers"
            hint="Select a new administrator (existing user)"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit"
                >Add</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        props: {
            partner: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                fields: {
                    user_id: null,
                },
                messageSuccess: 'The administrator has been added',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            submit() {
                return this.$api.partners.giveAccessToPartner(this.partner, this.$data.form.data())
            },
            fetchUsers(search) {
                return this.$api.users.get({
                    search: search,
                })
            },
        },
    }
</script>
