<template>
    <FormRoot>
        <!-- Group -->
        <template v-if="user">
            <Alert v-if="groups.length === 0">This user has no groups</Alert>
            <Field
                v-else
                :form.sync="form"
                type="radio"
                name="group_id"
                :options="groups"
                optionsKeyBy="id"
                optionsLabel="name"
                placeholder="Group"
                hint="Which group emit this license?"
            />
        </template>
        <template v-else>
            <Field
                :form.sync="form"
                type="component"
                component="Groups"
                name="group_id"
                placeholder="Group"
                hint="Which group emit this license?"
                :searchFunction="searchGroups"
            />
        </template>

        <Spinner v-if="loading" />
        <template v-else-if="form.group_id">
            <template v-if="billingAccounts && billingAccounts.length > 0">
                <!-- Billing Accounts -->
                <Field
                    :form.sync="form"
                    type="radio"
                    name="billing_account_id"
                    placeholder="Type"
                    hint="Choose billing account"
                    :options="billingAccounts"
                    optionsKeyBy="id"
                    optionsLabel="name"
                    class="mt-3"
                ></Field>

                <!-- Type -->
                <Field
                    :form.sync="form"
                    type="radio"
                    name="license_type"
                    placeholder="Type"
                    hint="Type of the license"
                    :options="{
                        virtual_card: 'Virtual card',
                        physical_card: 'Physical card',
                        user_account: 'Activation fees',
                    }"
                    class="mt-3"
                ></Field>

                <!-- Button -->
                <FormActions>
                    <Submit class="float-right" :disabled="!form.submittable" @click="onSubmit">Create license</Submit>
                </FormActions>
            </template>
            <Alert v-else>A group billing account is required before creating a license</Alert>
        </template>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit, Alert, Alerts, Spinner } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        props: {
            user: {
                type: Number,
                required: false,
            },
        },
        data() {
            return {
                loading: true,
                groups: [],
                fields: {
                    group_id: null,
                    license_type: null,
                    billing_account_id: null,
                },
                billingAccounts: null,
                messageSuccess: 'The license has been created',
            }
        },
        mounted() {
            this.fetchGroups()
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
            Alert,
            Spinner,
        },
        methods: {
            submit() {
                return this.$api.groups.createUserLicense(
                    this.$data.form.group_id,
                    this.user,
                    this.$data.form.notNullData()
                )
            },
            fetchGroups() {
                this.loading = true
                this.$api.groups
                    .get({ user: this.user })
                    .then((response) => {
                        this.groups = response.data
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response && error.response.data
                                ? error.response.data.message
                                : 'An error occurred when fetching groups'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            searchGroups(query) {
                return this.$api.groups.get({
                    search: query,
                })
            },
            getBillingAccounts(group_id) {
                this.loading = true
                this.$api.groups
                    .getBillingAccounts(group_id)
                    .then((response) => {
                        this.billingAccounts = response.data
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response && error.response.data
                                ? error.response.data.message
                                : 'An error occurred when searching for linked billing accounts'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
        },
        watch: {
            'form.group_id': {
                handler(group_id) {
                    if (group_id) this.getBillingAccounts(group_id)
                },
            },
        },
    }
</script>
