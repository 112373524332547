<template>
    <FormRoot :loading="dataLoading">
        <Field
            :form.sync="form"
            type="boolean"
            name="phone_call_required"
            placeholder="Phone call required"
            hint="When checked, a screen inviting user to call the partner is displayed during the booking. The phone call should be made by the user to manually check the availability of the offer."
        ></Field>
        <Field
            :form.sync="form"
            type="boolean"
            name="auto_validation"
            placeholder="Auto validation"
            hint="When checked, booking is automatically confirmed. No confirmation is required by the partner."
        ></Field>
        <Field
            :form.sync="form"
            type="boolean"
            name="payout_after_booking"
            placeholder="Payout after booking"
            hint="If checked, the amount of the booked offer will be paid out to the partner after the booking."
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit"
                >Update</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        props: {
            id: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                fields: {
                    phone_call_required: false,
                    auto_validation: false,
                    payout_after_booking: false,
                },
                messageSuccess: 'The offer has been saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            loadData() {
                return this.$api.offers.show(this.id)
            },
            submit() {
                return this.$api.offers.update(this.id, this.$data.form.data())
            },
        },
    }
</script>
