<template>
    <FormRoot :loading="dataLoading">
        <Field
            :form.sync="form"
            type="text"
            name="name"
            placeholder="Type name"
            hint="Name of the type"
            translate
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit"
                >Save type</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        data() {
            return {
                fields: {
                    name: null,
                },
                messageSuccess: 'Type saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            loadData() {
                return this.$api.types.show(this.id)
            },
            submit() {
                if (this.id) {
                    return this.$api.types.update(this.id, this.$data.form.data())
                } else {
                    return this.$api.types.create(this.$data.form.data())
                }
            },
        },
    }
</script>
