<template>
    <Panel :title="panelTitle" :loading="loading" @closePanel="closePanel">
        <Tabs v-if="offer">
            <Tab name="Offer" :selected="true">
                <div class="mb-2">
                    <Boolean v-model="offer.published" textOn="Published" textOff="Not published" class="me-1" />
                    <Boolean
                        v-model="offer.auto_validation"
                        textOn="Auto validation"
                        textOff="Manual validation"
                        class="me-1"
                    />
                    <Boolean
                        v-model="!offer.phone_call_required"
                        textOn="No phone call"
                        textOff="Phone call required"
                    />
                </div>
                <Sheet>
                    <Row name="Name">{{ offer.name[$store.state.lang.displayLanguage] }}</Row>
                    <Row name="Activity" v-if="offer.activity"
                        ><a href="#" @click.prevent="openActivity(offer.activity)">{{
                            offer.activity.name[$store.state.lang.displayLanguage]
                        }}</a></Row
                    >
                    <Row name="Description" v-if="offer.description[$store.state.lang.displayLanguage]">
                        <div v-html="offer.description[$store.state.lang.displayLanguage]"></div>
                    </Row>
                    <Row name="Price">{{ offer.price | currency }}</Row>
                    <Row name="VAT rate (%)">{{ offer.vat_rate }} %</Row>
                    <Row name="Original price" v-if="offer.price_original">{{ offer.price_original | currency }}</Row>
                    <Row name="Duration" v-if="offer.duration">{{ offer.duration }}</Row>
                    <Row name="Process"><Process :process="offer.process"/></Row>
                    <Row name="Stores">
                        <Store :id="store.id" :key="index" v-for="(store, index) in offer.stores" class="me-1"></Store>
                    </Row>
                    <Row name="Tags" v-if="offer.tags.length > 0">
                        <Tag v-for="(tag, index) in offer.tags" :key="index">{{ tag.en }}</Tag>
                    </Row>
                    <Row name="Availability">
                        <Availability v-model="offer.availability"></Availability>
                    </Row>
                    <Row name="Date range">
                        <div>Available from: {{ dateRange.from ? dateRange.from : 'Always' }}</div>
                        <div>Available to: {{ dateRange.to ? dateRange.to : 'Always' }}</div>
                    </Row>
                    <Row name="People limit">
                        <div>Min. people: {{ peopleLimit.min ? peopleLimit.min : 'None' }}</div>
                        <div>Max. people: {{ peopleLimit.max ? peopleLimit.max : 'Unlimited' }}</div>
                    </Row>
                    <Row name="Time slots source">{{ timeSlotsSource.source | capitalize }}</Row>
                    <Row name="Payout after booking"><Boolean v-model="offer.payout_after_booking"/></Row>
                    <Row name="Created at">{{ offer.created_at | formatDate }}</Row>
                    <Row name="Updated at">{{ offer.updated_at | formatDate }}</Row>
                </Sheet>

                <ID name="Hexeko" :value="offer.id" />
            </Tab>
        </Tabs>

        <template v-slot:actions v-if="$store.getters.userHasPermission('managePartners')">
            <Dropdown small title="Actions" position="right">
                <DropdownItem @click="openModal('updateOffer')" icon="edit">Update offer</DropdownItem>
                <DropdownItem @click="openModal('processOptions')" icon="cogs">Process options</DropdownItem>
                <DropdownItem
                    v-if="voucherList"
                    confirm
                    @confirm="unlinkVoucherList"
                    title="Unlink voucher list ?"
                    icon="link"
                    >Vouchers {{ voucherList.name }}</DropdownItem
                >
                <DropdownItem v-if="!voucherList" @click="openModal('linkVoucherList')" icon="unlink"
                    >No vouchers sended</DropdownItem
                >
                <DropdownItem @click="openModal('updateDateRange')" icon="calendar-alt"
                    >Update availability range</DropdownItem
                >
                <DropdownItem @click="openModal('updatePeopleLimit')" icon="people-arrows"
                    >Update people limit</DropdownItem
                >
                <DropdownItem @click="openModal('publish')" icon="bullhorn">Publish / Unpublish</DropdownItem>
                <DropdownItem confirm @confirm="deleteOffer" icon="trash-alt" class="text-danger">Delete</DropdownItem>
            </Dropdown>
            <!-- Update offer -->
            <Modal v-if="isModalOpen('updateOffer')" @close="closeModal('updateOffer')" title="Update offer">
                <UpdateOffer :id="offer.id" @submitted="offerUpdated"></UpdateOffer>
            </Modal>
            <!-- Process options -->
            <Modal v-if="isModalOpen('processOptions')" @close="closeModal('processOptions')" title="Process options">
                <ProcessOptions :id="offer.id" @submitted="offerUpdated"></ProcessOptions>
            </Modal>
            <!-- Publish / Unpublish -->
            <Modal v-if="isModalOpen('publish')" @close="closeModal('publish')" title="Publish / Unpublish">
                <Publish :id="offer.id" @submitted="offerUpdated"></Publish>
            </Modal>
            <!-- Update date range -->
            <Modal
                v-if="isModalOpen('updateDateRange')"
                @close="closeModal('updateDateRange')"
                title="Update availability range"
            >
                <UpdateDateRange
                    :offer="offer.id"
                    :values="{ from: dateRange.from, to: dateRange.to }"
                    @submitted="offerUpdated"
                ></UpdateDateRange>
            </Modal>
            <!-- Update people limit -->
            <Modal
                v-if="isModalOpen('updatePeopleLimit')"
                @close="closeModal('updatePeopleLimit')"
                title="Update people limit"
            >
                <UpdatePeopleLimit
                    :offer="offer.id"
                    :values="{ min: peopleLimit.min, max: peopleLimit.max }"
                    @submitted="offerUpdated"
                ></UpdatePeopleLimit>
            </Modal>
            <!-- Voucher Lists -->
            <Modal
                v-if="isModalOpen('linkVoucherList')"
                @close="closeModal('linkVoucherList')"
                title="Link voucher list to offer"
            >
                <SetVoucherList :offer="offer.id" @submitted="voucherListSetted" />
            </Modal>
        </template>
    </Panel>
</template>

<script>
    import {
        Panel,
        Tabs,
        Tab,
        Sheet,
        Row,
        Address,
        Boolean,
        Button,
        ID,
        Badge,
        Modal,
        Alerts,
        Dropdown,
        DropdownItem,
        Tag,
    } from '@tech_hexeko/design-system'
    import Process from '@/components/layout/Process'
    import Store from '@/components/layout/Store'
    import Availability from '@/components/layout/Availability'
    import Offers from '@/components/Offers'
    import axios from 'axios'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import SetVoucherList from './SetVoucherList'
    import UpdateDateRange from './UpdateDateRange'
    import UpdatePeopleLimit from './UpdatePeopleLimit'
    import UpdateOffer from './UpdateOffer'
    import ProcessOptions from './ProcessOptions'
    import Publish from './Publish'

    export default {
        name: 'OfferPanel',
        props: {
            id: Number,
        },
        data() {
            return {
                loading: true,
                offer: null,
                voucherList: null,
                dateRange: null,
                peopleLimit: null,
                timeSlotsSource: null,
            }
        },
        components: {
            SetVoucherList,
            Panel,
            Sheet,
            Row,
            Tabs,
            Tab,
            Tag,
            ID,
            Offers,
            Boolean,
            Availability,
            Address,
            Badge,
            Store,
            Modal,
            Button,
            Process,
            Dropdown,
            DropdownItem,
            UpdateDateRange,
            UpdatePeopleLimit,
            UpdateOffer,
            ProcessOptions,
            Publish,
        },
        mixins: [Modals],
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.loading = true
                // set requests
                const requestOffer = this.$api.offers.show(this.id, { includes: ['activity'] })
                const requestVoucherList = this.$api.offers.getVoucherList(this.id)
                const requestDateRange = this.$api.offers.getDateRange(this.id)
                const requestPeopleLimit = this.$api.offers.getPeopleLimit(this.id)
                const requestTimeSlotsSource = this.$api.offers.getTimeSlotSource(this.id)
                // make requests
                axios
                    .all([
                        requestOffer,
                        requestVoucherList,
                        requestDateRange,
                        requestPeopleLimit,
                        requestTimeSlotsSource,
                    ])
                    .then(
                        axios.spread((...responses) => {
                            const responseOffer = responses[0]
                            const responseVoucherList = responses[1]
                            const responseDateRange = responses[2]
                            const responsePeopleLimit = responses[3]
                            const responseTimeSlotsSource = responses[4]
                            // set data
                            this.offer = responseOffer
                            this.voucherList = responseVoucherList
                            this.dateRange = responseDateRange
                            this.peopleLimit = responsePeopleLimit
                            this.timeSlotsSource = responseTimeSlotsSource
                            // stop loading
                            this.loading = false
                        })
                    )
            },
            unlinkVoucherList() {
                this.$api.offers
                    .setVoucherList(this.id, {
                        voucher_list_id: null,
                    })
                    .then((response) => {
                        Alerts.notificationSuccess('Voucher link unlinked')
                        this.fetchData()
                    })
            },
            voucherListSetted() {
                this.closeModal('linkVoucherList')
                this.fetchData()
            },
            tagAdded() {
                this.closeModal('addTag')
                this.fetchData()
            },
            offerUpdated(offer) {
                this.closeModal('updateOffer')
                this.closeModal('processOptions')
                this.closeModal('publish')
                this.closeModal('updateDateRange')
                this.closeModal('updatePeopleLimit')
                if (!offer.id) {
                    this.goToOffer(this.id)
                } else if (offer.id === this.id) {
                    this.fetchData()
                } else {
                    this.goToOffer(offer.id)
                }
            },
            goToOffer(id) {
                this.closePanel({ refresh: true })
                this.openPanel('OfferPanel', id)
            },
            deleteOffer() {
                this.$api.offers.delete(this.id).then((response) => {
                    Alerts.notificationSuccess('Offer deleted')
                    this.closePanel({ refresh: true })
                })
            },
            closePanel(args) {
                this.$emit('closePanel', args)
            },
            openActivity(activity) {
                this.openPanel('activityPanel', activity.id)
            },
        },
        computed: {
            panelTitle: function() {
                return this.offer ? this.offer.name[this.$store.state.lang.displayLanguage] : null
            },
        },
    }
</script>
