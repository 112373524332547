<template>
    <div>
        <Tabs @selectTab="selectTab">
            <Tab
                name="Stores"
                :selected="selected('stores', true)"
                v-if="$store.getters.userHasAnyPermission(['showStores', 'manageStores'])"
            />
            <Tab
                name="Roles"
                :selected="selected('roles')"
                v-if="$store.getters.userHasAnyPermission(['showPrivileges', 'managePrivileges'])"
            />
        </Tabs>
        <router-view></router-view>
    </div>
</template>

<script>
    import { Tabs, Tab } from '@tech_hexeko/design-system'
    import TabsMixin from '../../mixins/TabsMixin'

    export default {
        components: {
            Tabs,
            Tab,
        },
        mixins: [TabsMixin],
        methods: {
            selectTab(tab) {
                if (tab.name === 'Stores') {
                    this.$router.push({ name: 'stores' })
                } else if (tab.name === 'Roles') {
                    this.$router.push({ name: 'roles' })
                }
            },
        },
    }
</script>
