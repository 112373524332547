<template>
    <Datatable
        name="Activities"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
    >
        <tr v-for="(activity, index) in activities" @click="open(activity)" class="cursor-pointer" :key="index">
            <td>
                <code>{{ activity.id }}</code>
            </td>
            <td>
                <div>{{ activity.name[$store.state.lang.displayLanguage] }}</div>
                <Boolean v-model="activity.published" textOn="Published" textOff="Not published" class="me-1" />
                <Availability v-model="activity.availability" />
            </td>
            <td>
                <div v-for="(type, index) in activity.types" :key="index">
                    {{ type.name[$store.state.lang.displayLanguage] }}
                </div>
            </td>
            <td><Address :address="activity.address" v-if="activity.address" /></td>
            <td class="text-right"><Icon icon="angle-right" /></td>
        </tr>
        <template v-slot:actions v-if="$store.getters.userHasPermission('managePartners')">
            <Button small @click="openModal('createActivity')" icon="plus">Create activity</Button>
            <Modal v-if="isModalOpen('createActivity')" @close="closeModal('createActivity')" title="Create activity">
                <UpdateActivity :values="params" @submitted="activitySubmitted"></UpdateActivity>
            </Modal>
        </template>
    </Datatable>
</template>

<script>
    import { Datatable, Address, Boolean, Button, Modal } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import Availability from '@/components/layout/Availability'
    import UpdateActivity from './UpdateActivity'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'

    export default {
        name: 'Activities',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                search: null,
                pagination: {
                    current_page: 1,
                },
                activities: [],
            }
        },
        components: {
            Datatable,
            Address,
            Boolean,
            Availability,
            UpdateActivity,
            Button,
            Modal,
        },
        mounted() {
            this.fetchData()
        },
        mixins: [Modals],
        methods: {
            fetchData(search) {
                this.loading = true
                this.search = search
                this.$api.activities
                    .get(
                        this.mergeParams({
                            search: this.search,
                            page: this.pagination.current_page,
                            includes: ['availability', 'first_media', 'address'],
                        })
                    )
                    .then((response) => {
                        this.activities = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            open(activity) {
                let panel = this.openPanel('ActivityPanel', activity.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
            activitySubmitted() {
                this.closeModal('createActivity')
                this.fetchData(this.search)
            },
        },
    }
</script>
