<template>
    <Datatable
        name="Payout Requests"
        @search="fetchData"
        :search-class="hideSearch"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
    >
        <template v-slot:filters>
            <DateRange time @setFromDate="searchFromDate" @setToDate="searchToDate" />
            <Button
                class="me-1"
                @click="toggleFilterWithStatus"
                :color="withStatus ? 'success' : withStatus === false ? 'danger' : 'secondary'"
                small
            >
                With status
            </Button>
            <Dropdown class="me-1 mb-1" color="secondary" title="Filter by status" small>
                <li v-for="prStatus in statuses" :key="prStatus.type">
                    <a
                        href="#"
                        class="dropdown-item text-xs"
                        :class="status === prStatus.type ? 'bg-primary text-white' : 'bg-white'"
                        @click.prevent="filterByStatus(prStatus)"
                    >
                        {{ prStatus.name }}
                    </a>
                </li>
            </Dropdown>
            <Dropdown class="me-1 mb-1" color="secondary" title="Filter by driver" small>
                <li v-for="pDriver in drivers" :key="pDriver.type">
                    <a
                        href="#"
                        class="dropdown-item text-xs"
                        :class="driver === pDriver.type ? 'bg-primary text-white' : 'bg-white'"
                        @click.prevent="filterByDriver(pDriver)"
                    >
                        {{ pDriver.name }}
                    </a>
                </li>
            </Dropdown>
        </template>
        <template v-slot:thead>
            <tr>
                <th>ID</th>
                <th>Source</th>
                <th>Amount</th>
                <th>IBAN</th>
                <th>Status</th>
                <th>Execution date</th>
                <th>Created at</th>
                <th></th>
            </tr>
        </template>
        <tr v-for="request in payoutRequests" :key="request.id" @click="open(request.id)">
            <td>
                <code>{{ request.id }}</code>
            </td>
            <td>
                <div v-if="request.source">
                    <a v-if="request.source.ticket_date"><Icon icon="undo-alt" /> Expense Request</a>
                    <span v-else-if="request.source.name"><Icon icon="user" /> {{ request.source.name }}</span>
                    <span v-else-if="request.source.metadata"><Icon icon="undo-alt" /> Allowance</span>
                </div>
            </td>
            <td>{{ request.amount | currency }}</td>
            <td>•••• {{ request.iban_last_four }}</td>
            <td><PayoutStatus :status="request.status" /></td>
            <td>{{ request.execution_date | formatDate }}</td>
            <td>{{ request.created_at | formatDate }}</td>
            <td class="text-right"><Icon icon="angle-right" /></td>
        </tr>
    </Datatable>
</template>

<script>
    import { Datatable, Button, DateRange, Dropdown } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import PayoutStatus from '../PayoutStatus'
    import moment from 'moment'
    import { formatBoolean } from '@/utils/query'

    export default {
        name: 'PayoutRequests',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                date_from: null,
                date_to: null,
                loading: true,
                pagination: {
                    current_page: 1,
                },
                payoutRequests: [],
                search: null,
                withStatus: null,
                status: null,
                statuses: [
                    { type: 'performed', name: 'Performed' },
                    { type: 'pending', name: 'Pending' },
                    { type: 'failed', name: 'Failed' },
                    { type: 'canceled', name: 'Canceled' },
                    { type: 'processing', name: 'Processing' },
                ],
                driver: null,
                drivers: [
                    { type: 'ponto', name: 'Ponto' },
                    { type: 'numeral', name: 'Numeral' },
                ],
            }
        },
        components: {
            Datatable,
            DateRange,
            Dropdown,
            Button,
            PayoutStatus,
        },
        mounted() {
            this.fetchData()
        },
        computed: {
            hideSearch() {
                return this.params?.payout_id ? 'd-none' : ''
            },
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.getPromises(search)
                    .then((response) => {
                        this.payoutRequests = response.data
                        this.pagination = response.meta.pagination
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            getPromises(search) {
                const params = this.mergeParams({
                    date_from: this.date_from || undefined,
                    date_to: this.date_to || undefined,
                    with_status: formatBoolean(this.withStatus),
                    statuses: [this.status],
                    driver: this.driver ? this.driver : undefined,
                    includes: ['source'],
                    page: this.pagination.current_page,
                    search: search,
                })
                if (this.params.payout_id) {
                    return this.$api.payouts.getPayoutRequestsFromPayout(this.params.payout_id, params)
                }
                else {
                    return this.$api.payouts.getPayoutRequests(params)
                }
            },
            toggleFilterWithStatus() {
                if (this.withStatus === null) this.withStatus = true
                else if (this.withStatus === true) this.withStatus = false
                else this.withStatus = null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            filterByStatus(status) {
                this.status = this.status === status.type ? null : status.type
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            filterByDriver(driver) {
                this.driver = this.driver === driver.type ? null : driver.type
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            isDate(val) {
                return moment(val, 'YYYY-MM-DD').isValid()
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            searchFromDate(val) {
                this.date_from = this.isDate(val) ? val : null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            searchToDate(val) {
                this.date_to = this.isDate(val) ? val : null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            open(id) {
                let panel = this.openPanel('payoutRequestPanel', id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
        },
    }
</script>
