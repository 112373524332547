<template>
    <Datatable
        name="Payouts"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
    >
        <template v-slot:filters>
            <DateRange time @setFromDate="searchFromDate" @setToDate="searchToDate" />
            <Dropdown class="me-1 mb-1" color="secondary" title="Filter by status" small>
                <li v-for="pStatus in statuses" :key="pStatus.type">
                    <a
                        href="#"
                        class="dropdown-item text-xs"
                        :class="status === pStatus.type ? 'bg-primary text-white' : 'bg-white'"
                        @click.prevent="filterByStatus(pStatus)"
                    >
                        {{ pStatus.name }}
                    </a>
                </li>
            </Dropdown>
            <Dropdown class="me-1 mb-1" color="secondary" title="Filter by driver" small>
                <li v-for="pDriver in drivers" :key="pDriver.type">
                    <a
                        href="#"
                        class="dropdown-item text-xs"
                        :class="driver === pDriver.type ? 'bg-primary text-white' : 'bg-white'"
                        @click.prevent="filterByDriver(pDriver)"
                    >
                        {{ pDriver.name }}
                    </a>
                </li>
            </Dropdown>
        </template>
        <template v-slot:thead>
            <tr>
                <th>ID</th>
                <th>Amount</th>
                <th>IBAN</th>
                <th>Date</th>
                <th>Status</th>
                <th>External ID</th>
                <th></th>
            </tr>
        </template>
        <tr v-for="(payout, index) in payouts" @click="open(payout.id)" :key="index">
            <td>
                <code>{{ payout.id }}</code>
            </td>
            <td>{{ payout.amount | currency }}</td>
            <td>{{ payout.iban }}</td>
            <td>{{ payout.created_at | formatDate }}</td>
            <td>
                <PayoutStatus :status="payout.status" />
            </td>
            <td>
                <code class="text-muted">
                    <span v-if="payout.external_id">{{ payout.external_id }}</span>
                    <span v-else> Unknown</span>
                </code>
            </td>
            <td class="text-right"><Icon icon="angle-right" /></td>
        </tr>
    </Datatable>
</template>

<script>
    import { Datatable, DateRange, Dropdown } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import PayoutStatus from '../PayoutStatus'
    import moment from 'moment'

    export default {
        name: 'Payouts',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                date_from: null,
                date_to: null,
                loading: true,
                pagination: {
                    current_page: 1,
                },
                payouts: [],
                search: null,
                status: null,
                statuses: [
                    { type: 'paid', name: 'Paid' },
                    { type: 'pending', name: 'Pending' },
                    { type: 'canceled', name: 'Canceled' },
                    { type: 'failed', name: 'Failed' },
                    { type: 'received', name: 'Received' },
                    { type: 'received_and_processed', name: 'Received and processed' },
                ],
                driver: null,
                drivers: [
                    { type: 'ponto', name: 'Ponto' },
                    { type: 'numeral', name: 'Numeral' },
                ],
            }
        },
        components: {
            Datatable,
            DateRange,
            Dropdown,
            PayoutStatus,
        },
        mixins: [Modals],
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.search = search
                this.$api.payouts
                    .get(
                        this.mergeParams({
                            date_from: this.date_from || undefined,
                            date_to: this.date_to || undefined,
                            includes: ['iban'],
                            page: this.pagination.current_page,
                            statuses: this.status ? [this.status] : undefined,
                            driver: this.driver ? this.driver : undefined,
                            search: search,
                        })
                    )
                    .then((response) => {
                        this.payouts = response.data
                        this.pagination = response.meta.pagination
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            isDate(val) {
                return moment(val, 'YYYY-MM-DD').isValid()
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            searchFromDate(val) {
                this.date_from = this.isDate(val) ? val : null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            searchToDate(val) {
                this.date_to = this.isDate(val) ? val : null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            open(id) {
                let panel = this.openPanel('payoutPanel', id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
            filterByStatus(status) {
                this.status = this.status === status.type ? null : status.type
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            filterByDriver(driver) {
                this.driver = this.driver === driver.type ? null : driver.type
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
        },
    }
</script>
