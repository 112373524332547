<template>
    <Datatable
        name="Billing Accounts"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
    >
        <template v-slot:thead>
            <tr>
                <th>Name</th>
                <th>E-mail</th>
                <th>City</th>
            </tr>
        </template>
        <tr v-for="account in accounts" @click="open(account)">
            <td>{{ account.name }}</td>
            <td>{{ account.email }}</td>
            <td>{{ account.city }} {{ account.country_iso }}</td>
            <td class="text-right"><Icon icon="angle-right" /></td>
        </tr>
        <template v-slot:actions v-if="$store.getters.userHasPermission('manageBillingAccounts')">
            <Button small @click="openModal('createBillingAccount')" icon="plus">Create billing account</Button>
            <Modal
                v-if="isModalOpen('createBillingAccount')"
                @close="closeModal('createBillingAccount')"
                title="Create billing account"
            >
                <UpdateBillingAccount @submitted="billingAccountSubmitted"></UpdateBillingAccount>
            </Modal>
        </template>
    </Datatable>
</template>

<script>
    import { Datatable, Modal, Button, Dropdown, DropdownItem, Boolean, Badge } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import UpdateBillingAccount from '@/components/BillingAccounts/UpdateBillingAccount'

    export default {
        name: 'BillingAccounts',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                pagination: {
                    current_page: 1,
                },
                accounts: [],
                search: null,
            }
        },
        components: {
            Datatable,
            Dropdown,
            DropdownItem,
            Modal,
            Button,
            Boolean,
            Badge,
            UpdateBillingAccount,
        },
        mixins: [Modals],
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.search = search
                this.$api.billing.billingAccounts
                    .get(
                        this.mergeParams({
                            search: search ? search : undefined,
                            page: this.pagination.current_page,
                        })
                    )
                    .then((response) => {
                        this.accounts = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            open(account) {
                let panel = this.openPanel('BillingAccountPanel', account.id)
                panel.promise.then((result) => {
                    if (result) {
                        this.fetchData(this.search)
                    }
                })
            },
            billingAccountSubmitted() {
                this.closeModal('createBillingAccount')
                setTimeout(() => {
                    this.fetchData()
                }, 1000)
            },
        },
    }
</script>
