export default {
    allowance: {
        icon: 'route',
        name: 'Allowance',
    },
    booking_request: {
        icon: 'shopping-basket',
        name: 'Booking request',
    },
    card_transaction: {
        icon: 'credit-card',
        name: 'Card transaction',
    },
    expense_request: {
        icon: 'ticket-alt',
        name: 'Expense request',
    },
};
