<template>
    <FormRoot v-if="card">
        <!-- Data -->
        <Field
            :form.sync="form"
            type="radio"
            name="reason"
            :options="{
                lost: 'Lost',
                stolen: 'Stolen',
            }"
            optionsKeyBy="name"
            optionsLabel="name"
            hint="The reason for the replacement"
        />
        <FieldsCard
            v-if="isPhysical"
            :form.sync="form"
            :allowRandomPin="false"
            @encryptPin="handleEncryptPin"
            @generatePin="handleGeneratePin"
            @resetEncryptedPin="resetEncryptedPin"
            @resetPin="handleResetPin"
        />

        <!-- Actions -->
        <Alert color="info" icon="info-circle">The card will be canceled and a new one will be created.</Alert>
        <FormActions>
            <Submit :disabled="disableSubmit" :loading="form.loading" @click="onSubmit">Replace card</Submit>
        </FormActions>
    </FormRoot>
    <Alert v-else color="warning" icon="exclamation-triangle">No card ID was provided.</Alert>
</template>

<script>
    import { Alert, Field, FormActions, FormRoot, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import Generator from '@/mixins/Generator'
    import FieldsCard from '@/components/layout/FieldsCard'

    export default {
        name: 'ReplaceCard',
        components: {
            Alert,
            Field,
            FieldsCard,
            FormActions,
            FormRoot,
            Submit,
        },
        mixins: [Forms, Generator],
        data() {
            return {
                fields: {
                    encrypted_pin: null,
                    pin: null,
                    reason: null,
                    shipping_address_source: null,
                    shipping_delivery_service: null,
                    shipping_delivery_type: null,
                },
                messageSuccess: 'The card has been replaced',
            }
        },
        props: {
            card: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
        },
        computed: {
            disableSubmit() {
                return !this.form.reason || (this.isPhysical && !this.form.encrypted_pin)
            },
            isPhysical() {
                return this.type === 'physical'
            },
            payload() {
                const { reason, ...physicalPayload } = this.$data.form.data()
                return {
                    reason,
                    ...(this.isPhysical ? physicalPayload : {}),
                }
            },
        },
        methods: {
            handleEncryptPin() {
                this.encryptPin(this.form.pin).then((encrypted_pin) => {
                    this.form.encrypted_pin = encrypted_pin
                })
            },
            handleGeneratePin() {
                this.resetEncryptedPin()
                this.form.pin = this.generatePin()
            },
            handleResetPin() {
                this.form.pin = null
                this.resetEncryptedPin()
            },
            resetEncryptedPin() {
                this.form.encrypted_pin = null
            },
            submit() {
                let data = this.payload
                if (data) delete data.pin
                return this.$api.cards.replace(this.card, data)
            },
        },
    }
</script>
