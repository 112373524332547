<template>
    <Panel title="Payment Method" :loading="loading" @closePanel="closePanel">
        <Tabs v-if="!loading">
            <Tab name="Payment method" :selected="true">
                <PaymentMethodItem class="mb-1" :hideActions="true" :paymentMethod="paymentMethod" />
                <Sheet>
                    <Row name="Driver">{{ paymentMethod.driver }}</Row>
                    <Row name="Identifier">
                        <span>{{ paymentMethod.identifier }}</span>
                        <a
                            href="#"
                            @click.prevent="openWallet(paymentMethod.identifier)"
                            v-if="paymentMethod.driver === 'wallet'"
                            class="ms-1"
                            ><Icon icon="external-link-alt" /> Open wallet</a
                        >
                    </Row>
                    <Row name="Name" v-if="paymentMethod.source && paymentMethod.source.name">{{
                        paymentMethod.source.name
                    }}</Row>
                    <Row name="Balance">{{ paymentMethod.balance | currency }}</Row>
                    <Row name="Expiration date" v-if="paymentMethod.expiration_date">{{
                        paymentMethod.expiration_date | formatDate
                    }}</Row>
                    <Row name="Brand" v-if="paymentMethod.brand">{{ paymentMethod.brand }}</Row>
                    <Row name="Last four digits" v-if="paymentMethod.last_four">{{ paymentMethod.last_four }}</Row>
                    <Row name="Minimum amount payable">{{ paymentMethod.min_amount_can_pay | currency }}</Row>
                    <Row name="Payment authorized by supplier">
                        <Boolean v-model="isAuthorizedBySupplier" textOn="Authorized" textOff="Not authorized" />
                    </Row>
                    <Row name="User" v-if="paymentMethod.user">
                        <a href="#" @click.prevent="openUser(paymentMethod.user)"
                            >{{ paymentMethod.user.first_name }} {{ paymentMethod.user.last_name }}</a
                        >
                    </Row>
                </Sheet>
                <ID name="Hexeko" :value="paymentMethod.id" />
            </Tab>
            <Tab
                name="Transactions"
                v-if="$store.getters.userHasAnyPermission(['showPaymentTransactions', 'managePaymentTransactions'])"
            >
                <Transactions :params="{ payment_method: paymentMethod.id }" />
            </Tab>
        </Tabs>
    </Panel>
</template>

<script>
    import {
        Tabs,
        Tab,
        Panel,
        Sheet,
        Row,
        Boolean,
        Modal,
        Badge,
        ID,
        Dropdown,
        DropdownItem,
    } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import Transactions from '@/components/PaymentTransactions'
    import PaymentMethodItem from '@tech_hexeko/hexeko-user-account/src/components/PaymentMethods/PaymentMethodItem'

    export default {
        name: 'PaymentMethodPanel',
        mixins: [Modals],
        components: {
            Panel,
            Sheet,
            Row,
            Boolean,
            Tabs,
            Tab,
            Modal,
            Badge,
            Transactions,
            PaymentMethodItem,
            ID,
            Dropdown,
            DropdownItem,
        },
        props: {
            id: String,
        },
        data() {
            return {
                loading: true,
                paymentMethod: null,
            }
        },
        computed: {
            isAuthorizedBySupplier() {
                if (!this.paymentMethod) return false
                if (this.paymentMethod.payment_authorized_by_supplier === null) return true
                return this.paymentMethod.payment_authorized_by_supplier
            },
        },
        methods: {
            fetchData() {
                this.loading = true
                return this.$api.paymentMethods
                    .show(this.id, {
                        includes: ['user', 'source', 'source.types'],
                    })
                    .then((response) => {
                        this.paymentMethod = response
                        this.loading = false
                    })
            },
            openUser(user) {
                this.openPanel('userPanel', user.id)
            },
            closePanel(refresh) {
                this.$emit('closePanel', {
                    refresh: refresh,
                })
            },
            openWallet(id) {
                this.openPanel('walletPanel', id)
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
