<template>
    <Badge color="secondary"> <Icon icon="project-diagram" /> {{ processLabel }} </Badge>
</template>

<script>
    import { Badge, Icon } from '@tech_hexeko/design-system'

    export default {
        name: 'Store',
        props: {
            process: String,
        },
        components: {
            Icon,
            Badge,
        },
        computed: {
            processLabel: function() {
                const processes = {
                    automatic_booking: 'Automatic Booking',
                    booking: 'Booking',
                    voucher: 'Automatic voucher',
                    manual_voucher: 'Voucher',
                }
                return processes[this.process] ? processes[this.process] : this.process
            },
        },
    }
</script>
