<template>
    <Datatable
        name="Roles"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
    >
        <template v-slot:thead>
            <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Name</th>
            </tr>
        </template>
        <tr v-for="role in roles" @click="openRole(role)">
            <td>
                <code>{{ role.id }}</code>
            </td>
            <td>{{ role.created_at | formatDate }}</td>
            <td>{{ role.name }}</td>
        </tr>
    </Datatable>
</template>

<script>
    import { Datatable, Button, Boolean } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'

    export default {
        name: 'Payouts',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                pagination: {
                    current_page: 1,
                },
                roles: [],
            }
        },
        components: {
            Datatable,
            Button,
            Boolean,
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.$api.roles
                    .get(
                        this.mergeParams({
                            search: search,
                            page: this.pagination.current_page,
                        })
                    )
                    .then((response) => {
                        this.roles = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            openRole(role) {
                this.openPanel('RolePanel', role.id)
            },
        },
    }
</script>
