<template>
    <FormRoot :loading="dataLoading">
        <Field :form.sync="form" type="text" name="name" placeholder="Group name" hint="Name of the group"></Field>
        <Field
            :form.sync="form"
            type="component"
            component="Stores"
            name="stores"
            hint="Select stores available for group"
            multiple
        ></Field>
        <div class="row">
            <div class="col-6">
                <Field
                    :form.sync="form"
                    type="phone"
                    name="contacts.phone"
                    placeholder="Phone"
                    hint="Main phone contact"
                ></Field>
            </div>
            <div class="col-6">
                <Field
                    :form.sync="form"
                    type="text"
                    name="contacts.email"
                    placeholder="E-mail"
                    hint="Main e-mail address"
                ></Field>
            </div>
        </div>
        <Field
            :form.sync="form"
            type="component"
            component="Users"
            name="representative_id"
            hint="Select a representative (existing user)"
            @remove="removeRepresentative"
        ></Field>
        <Field :form.sync="form" type="radio" name="cards_brand" :options="cardBrands" hint="Cards brand" />
        <Field
            :form.sync="form"
            type="radio"
            name="issuing_funding_system"
            :options="fundingSystems"
            hint="Issuing funding system"
        />
        <Field
            v-if="!!marketSegments && marketSegments.length > 0"
            :form.sync="form"
            type="radio"
            name="market_segment"
            :options="marketSegments"
            optionsKeyBy="technical_name"
            optionsLabel="name"
            hint="Market segment"
        ></Field>
        <Field :form.sync="form" type="text" name="vat_number" placeholder="VAT number" hint="VAT number"></Field>
        <Field
            :form.sync="form"
            type="text"
            name="registration_number"
            placeholder="Registration number"
            hint="Registration number"
        ></Field>
        <Field
            :form.sync="form"
            type="text"
            name="iban"
            placeholder="IBAN"
            hint="Issuer IBAN for funding wallets"
        ></Field>
        <Field
            :form.sync="form"
            type="country"
            name="registration_country"
            placeholder="Registration country"
            hint="ISO code. Example: BE"
        ></Field>
        <hr class="my-4" />
        <FieldAddress :form.sync="form" name="address" hasLine2></FieldAddress>
        <hr class="my-4" />
        <FieldTimezone :form.sync="form" />
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" @click="onSubmit">Save group</Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot } from '@tech_hexeko/design-system'
    import { Alerts, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import FieldAddress from '@/components/layout/FieldAddress'
    import FieldTimezone from '@/components/layout/FieldTimezone'

    export default {
        data() {
            return {
                fields: {
                    name: null,
                    contacts: {
                        phone: null,
                        email: null,
                    },
                    registration_number: null,
                    iban: null,
                    registration_country: null,
                    vat_number: null,
                    address: {
                        line1: null,
                        line2: null,
                        zipcode: null,
                        city: null,
                        country: null,
                    },
                    stores: [],
                    market_segment: null,
                    representative_id: null,
                    timezone: null,
                    cards_brand: null,
                    issuing_funding_system: 'individual',
                },
                marketSegments: [],
                cardBrands: ['VISA', 'MASTERCARD'],
                fundingSystems: ['individual', 'global'],
                messageSuccess: 'Group saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
            FieldAddress,
            FieldTimezone,
        },
        methods: {
            fetchMarketSegments() {
                this.$api.groups
                    .getMarketSegments()
                    .then((response) => {
                        this.marketSegments = response.data
                    })
                    .catch((error) => {
                        let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
            },
            loadData() {
                return this.$api.groups
                    .show(this.id, { includes: ['access_stores', 'representative', 'issuing', 'iban'] })
                    .then((group) => {
                        if (group.market_segment?.technical_name)
                            group.market_segment = group.market_segment.technical_name
                        if (group.address?.zipcode) group.address.zipcode = group.address.zipcode.toString()
                        if (group.issuing && group.issuing.funding_system)
                            group.issuing_funding_system = group.issuing.funding_system
                        this.$set(group, 'stores', group.access_stores)
                        this.form.fillAsOriginalData({
                            representative_id: group.representative,
                        })
                        return group
                    })
            },
            removeRepresentative() {
                this.$set(this.form, 'representative_id', null)
            },
            submit() {
                let data = this.$data.form.data()
                if (!data.timezone) delete data.timezone
                if (this.id) {
                    return this.$api.groups.update(this.id, data)
                } else {
                    return this.$api.groups.create(this.$data.form.notNullData())
                }
            },
        },
        beforeMount() {
            this.fetchMarketSegments()
        },
    }
</script>
