<template>
    <footer class="pb-4">
        <ul class="nav">
            <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'dashboard' }">Hexeko Admin</router-link>
            </li>
            <li class="nav-item">
                <a href="https://data.hexeko.com" target="_blank" class="nav-link">
                    <Icon icon="external-link-alt" /> Data
                </a>
            </li>
            <li class="nav-item">
                <div class="nav-link disabled">Logged in as {{ $store.state.admin.user.name }}</div>
            </li>
            <li class="nav-item">
                <a href="#" @click.prevent="logout" class="nav-link">Logout</a>
            </li>
        </ul>
    </footer>
</template>

<script>
    import { Auth } from 'aws-amplify'

    export default {
        name: 'Footer',
        methods: {
            async logout() {
                try {
                    await Auth.signOut({ global: true })
                } catch (error) {
                    console.log('error signing out: ', error)
                }
            },
        },
    }
</script>
