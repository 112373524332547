<template>
    <Panel title="Store" :loading="loading" @closePanel="$emit('closePanel')">
        <Tabs v-if="!loading">
            <Tab name="Store" :selected="true">
                <Sheet name="Store">
                    <Row name="Name">{{ store.name }}</Row>
                    <Row name="Branding" v-if="store.branding">{{ store.branding }}</Row>
                    <Row name="Apple Pay"
                        ><Boolean v-model="store.apple_pay_enabled" textOn="Enabled" textOff="Disabled"
                    /></Row>
                    <Row name="Created at">{{ store.created_at | formatDate }}</Row>
                    <Row name="Updated at">{{ store.updated_at | formatDate }}</Row>
                </Sheet>
                <Sheet name="URL's">
                    <Row name="App">{{ store.app_url }}</Row>
                    <Row name="Manager">{{ store.manager_url }}</Row>
                    <Row name="Landing">{{ store.landing_url }}</Row>
                </Sheet>
                <Sheet name="Fees">
                    <Row name="Expense request fee">{{ store.expense_request_fees | currency }}</Row>
                    <Row name="Min amount of expense requests">{{ store.expense_request_min_amount | currency }}</Row>
                </Sheet>
                <ID name="Hexeko" :value="store.id" />
            </Tab>
        </Tabs>

        <template v-slot:actions v-if="$store.getters.userHasPermission('manageStores')">
            <!-- Actions -->
            <Dropdown small icon="cogs" title="Configuration" position="right">
                <DropdownItem @click="openModal('configDefault')" icon="store">General</DropdownItem>
                <DropdownItem @click="openModal('configMail')" icon="envelope">E-mail</DropdownItem>
            </Dropdown>

            <!-- Configuration -->
            <Modal
                v-if="isModalOpen('configDefault')"
                @close="closeModal('configDefault')"
                title="General configuration"
            >
                <Configuration :id="store.id" @submitted="configSubmitted" />
            </Modal>

            <!-- E-mail -->
            <Modal v-if="isModalOpen('configMail')" @close="closeModal('configMail')" title="E-mail configuration">
                <MailConfig :id="store.id" namespace="mail" @submitted="configSubmitted" />
            </Modal>
        </template>
    </Panel>
</template>

<script>
    import {
        Alerts,
        Panel,
        Tabs,
        Tab,
        Sheet,
        Row,
        ID,
        Modal,
        Dropdown,
        DropdownItem,
        Boolean,
    } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import Configuration from './Configuration'
    import MailConfig from './MailConfig'

    export default {
        name: 'StorePanel',
        props: {
            id: Number,
        },
        data() {
            return {
                loading: true,
                store: null,
                config: null,
            }
        },
        mounted() {
            this.fetchData()
        },
        mixins: [Modals],
        methods: {
            fetchData() {
                this.loading = true
                this.$api.stores
                    .show(this.id)
                    .then((response) => {
                        this.store = response
                    })
                    .catch((error) => {
                        if (error && error.response) Alerts.notificationError(error.response.data.message)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            configSubmitted() {
                this.closeModal('config')
                this.fetchData()
            },
        },
        components: {
            MailConfig,
            Panel,
            Tabs,
            Tab,
            Sheet,
            Row,
            ID,
            Modal,
            Dropdown,
            DropdownItem,
            Configuration,
            Boolean,
        },
    }
</script>
