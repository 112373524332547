<template>
    <div>
        <!-- Shipping address -->
        <Field
            :form.sync="form"
            type="radio"
            name="shipping_address_source"
            placeholder="Shipping address"
            hint="Where will the card be delivered?"
            :options="{
                user: 'User address',
                group: 'Group address',
                default: 'Default address (ex: Hexeko Office)',
            }"
        />
        <!-- Delivery type -->
        <Field
            :form.sync="form"
            type="radio"
            name="shipping_delivery_type"
            placeholder="Shipping delivery type"
            :options="{ bulk: 'Bulk', individual: 'Individual' }"
            hint="What kind of delivery type do you want?"
        />
        <!-- Delivery service -->
        <Field
            :form.sync="form"
            type="radio"
            name="shipping_delivery_service"
            placeholder="Shipping delivery service"
            hint="What kind of delivery service do you want?"
            :options="deliveryServiceOptions"
        />
        <!-- PIN -->
        <Field
            :form.sync="form"
            :class="{ 'visually-hidden': isRandomPIN }"
            type="text"
            name="pin"
            placeholder="****"
            hint="Set a PIN code for your card (4 digits)"
        />
        <!-- Encrypted PIN -->
        <Field :form.sync="form" class="visually-hidden" type="text" name="encrypted_pin" />
        <!-- Random PIN -->
        <Checkbox v-if="allowRandomPin" v-model="form.random_pin">Assign random PIN</Checkbox>
    </div>
</template>

<script>
    import { Checkbox, Field } from '@tech_hexeko/design-system'
    import Generator from '@/mixins/Generator'

    export default {
        name: 'FieldsCard',
        mixins: [Generator],
        components: {
            Checkbox,
            Field,
        },
        data() {
            return {
                isRandomPIN: false,
            }
        },
        props: {
            allowRandomPin: {
                type: Boolean,
                default: true,
            },
            form: {
                type: Object,
                required: true,
            },
        },
        computed: {
            deliveryServiceOptions() {
                return {
                    priority: 'Priority',
                    ...(this.form.shipping_delivery_type !== 'bulk' && { express: 'Express' }),
                    ...(this.form.shipping_delivery_type !== 'bulk' && { standard: 'Standard' }),
                }
            },
        },
        watch: {
            'form.pin': {
                handler(pin) {
                    if (!pin) {
                        this.$emit('resetEncryptedPin')
                        return
                    }
                    pin.length >= 4 ? this.$emit('encryptPin') : this.$emit('resetEncryptedPin')
                },
            },
            'form.random_pin': {
                immediate: true,
                handler(isRandom) {
                    if (this.allowRandomPin) {
                        isRandom ? this.$emit('generatePin') : this.$emit('resetPin')
                        this.isRandomPIN = isRandom
                    }
                },
            },
            'form.shipping_delivery_type': {
                immediate: true,
                handler() {
                    if (this.form.shipping_delivery_type === 'bulk') {
                        this.form.shipping_delivery_service = 'priority'
                    }
                },
            },
        },
    }
</script>
