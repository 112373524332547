<template>
    <span v-if="iconComponent" class="wallet-icon" :class="styleCategory">
        <component :is="iconComponent" />
    </span>
</template>

<script>
    import IconAccountActivation from './IconAccountActivation'
    import IconBag from './IconBag'
    import IconCardLicense from './IconCardLicense'
    import IconCarpooling from './IconCarpooling'
    import IconClosing from './IconClosing'
    import IconCulture from './IconCulture'
    import IconDeposit from './IconDeposit'
    import IconFuelExpenses from './IconFuelExpenses'
    import IconGift from './IconGift'
    import IconHolidays from './IconHolidays'
    import IconMeal from './IconMeal'
    import IconMobility from './IconMobility'
    import IconPublicTransportPasses from './IconPublicTransportPasses'
    import IconQuotableActivation from './IconQuotableActivation'
    import IconRefund from './IconRefund'
    import IconSport from './IconSport'
    import IconSportHobbies from './IconSportHobbies'
    import IconSustainableMobilityPackage from './IconSustainableMobilityPackage'
    import IconTelework from './IconTelework'
    import IconWithdraw from './IconWithdraw'

    export default {
        name: 'IconWallet',
        components: {
            IconAccountActivation,
            IconBag,
            IconCardLicense,
            IconCarpooling,
            IconClosing,
            IconCulture,
            IconDeposit,
            IconFuelExpenses,
            IconGift,
            IconHolidays,
            IconMeal,
            IconMobility,
            IconPublicTransportPasses,
            IconQuotableActivation,
            IconRefund,
            IconSport,
            IconSportHobbies,
            IconSustainableMobilityPackage,
            IconTelework,
            IconWithdraw,
        },
        data() {
            return {
                subtypesGift: [
                    'employee_christmas',
                    'children_christmas',
                    'birth_adoption',
                    'marriage_pacs',
                    'retirement',
                    'mother_day',
                    'father_day',
                    'sainte_catherine',
                    'saint_nicolas',
                    'back_to_school',
                ],
                supportedCategories: [
                    'account_activation',
                    'card_license',
                    'carpooling',
                    'closing',
                    'culture',
                    'deposit',
                    'fuel_expenses',
                    'gift',
                    'holidays',
                    'meal',
                    'mobility',
                    'public_transport_passes',
                    'quotable_activation',
                    'refund',
                    'sport',
                    'sport-hobbies',
                    'sustainable_mobility_package',
                    'telework',
                    'withdraw',
                ],
            }
        },
        props: {
            category: {
                type: String,
                required: true,
            },
            small: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            styleCategory() {
                let classes = []

                // Small
                if (this.small) classes.push('is--small')

                // Detect supported category or use default
                let catBuffer = ''
                if (!this.category) {
                    catBuffer = 'is--void'
                } else {
                    if (this.subtypesGift.includes(this.category)) {
                        catBuffer = 'is--gift'
                    } else {
                        catBuffer = this.supportedCategories.includes(this.category)
                            ? `is--${this.category}`
                            : 'is--void'
                    }
                }
                classes.push(catBuffer)

                return classes
            },
            iconComponent() {
                if (!this.category) return 'icon-bag'
                if (this.subtypesGift.includes(this.category)) return 'icon-gift'
                let cat = this.category.replaceAll('_', '-')
                return this.supportedCategories.includes(this.category) ? `icon-${cat}` : 'icon-bag'
            },
        },
    }
</script>
