<template>
    <Badge :color="ss.color" :large="large" :icon="ss.icon">{{ ss.name }}</Badge>
</template>
<script>
    import { Badge } from '@tech_hexeko/design-system'
    import statuses from './statuses'

    export default {
        name: 'BookingStatus',
        props: {
            status: String,
            large: Boolean,
        },
        computed: {
            ss: function() {
                return statuses[this.status]
            },
        },
        components: {
            Badge,
        },
    }
</script>
