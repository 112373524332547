import messagesFr from '@tech_hexeko/ui/src/lang/fr.json';
import messagesEn from '@tech_hexeko/ui/src/lang/en.json';
import messagesNl from '@tech_hexeko/ui/src/lang/nl.json';
var _ = require('lodash');

export default _.merge(
    {},
    {
        fr: {
            ui: {
                notifications: messagesFr.ui.notifications,
                timestamp: messagesFr.ui.timestamp,
                transaction: messagesFr.ui.transaction,
            },
        },
        nl: {
            ui: {
                notifications: messagesNl.ui.notifications,
                timestamp: messagesNl.ui.timestamp,
                transaction: messagesNl.ui.transaction,
            },
        },
        en: {
            ui: {
                notifications: messagesEn.ui.notifications,
                timestamp: messagesEn.ui.timestamp,
                transaction: messagesEn.ui.transaction,
            },
        },
    }
);
