<template>
    <FormRoot :loading="dataLoading">
        <v-jsoneditor
            v-model="form.mail_config"
            :options="{ mode: 'code', mainMenuBar: false, navigationBar: false, statusBar: false }"
            :plus="false"
            height="400px"
        />
        <Error v-if="form.errors.message" :error="form.errors.errors" />
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit"
                >Update</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit, Error } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import VJsoneditor from 'v-jsoneditor'

    export default {
        props: {
            id: Number,
            namespace: {
                type: String,
                default: 'default',
            },
        },
        data() {
            return {
                fields: {
                    mail_config: {},
                },
                messageSuccess: 'The configuration has been saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
            VJsoneditor,
            Error,
        },
        methods: {
            loadData() {
                return this.$api.stores.show(this.id, { includes: ['mail_config'] }).then((store) => {
                    this.form.fillAsOriginalData({
                        mail_config: store.mail_config,
                    })
                })
            },
            submit() {
                return this.$api.stores.update(this.id, this.form.data())
            },
        },
        watch: {
            'form.mail_config': {
                deep: true,
                handler: function() {
                    this.form.errors.clear()
                    this.form.keyUp()
                },
            },
        },
    }
</script>
