<template>
    <Panel title="Voucher" :loading="loading" @closePanel="$emit('closePanel')">
        <Tabs v-if="!loading">
            <Tab name="Voucher" :selected="true">
                <div class="mb-2">
                    <VoucherStatus :voucher="voucher"></VoucherStatus>
                </div>
                <Sheet>
                    <Row name="Code" v-if="voucher.code_last4">#########{{ voucher.code_last4 }}</Row>
                    <Row name="Comment" v-if="voucher.comment">{{ voucher.comment }}</Row>
                    <Row name="Value" v-if="voucher.value">{{ voucher.value | currency }}</Row>
                    <Row name="Expiration date">{{ voucher.expiration_date | formatDate }}</Row>
                    <Row name="List" v-if="voucher.voucher_list"
                        ><a href="#" @click.prevent="openList(voucher.voucher_list)">{{
                            voucher.voucher_list.name
                        }}</a></Row
                    >
                    <Row name="Picked by" v-if="voucher.picker"
                        >Booking
                        <a href="#" @click.prevent="openBooking(voucher.picker)">{{ voucher.picker.id }}</a> the
                        {{ voucher.picked_at | formatDate }}</Row
                    >
                    <Row name="Created at">{{ voucher.created_at | formatDate }}</Row>
                    <Row name="Updated at">{{ voucher.updated_at | formatDate }}</Row>
                </Sheet>
                <ID name="Hexeko" :value="voucher.id" />
            </Tab>
            <Tab name="Offers">
                <div class="alert alert-warning">Soon available</div>
            </Tab>
        </Tabs>
        <template v-slot:actions v-if="$store.getters.userHasPermission('manageVouchers') && voucher">
            <Button v-if="voucher.picker" is="confirm" @confirm="sendToPicker()" color="light" icon="paper-plane" small
                >Send to picker</Button
            >
            <Button
                v-if="!voucher.picked_at"
                is="confirm"
                @confirm="deleteVoucher()"
                class="ms-1"
                color="danger"
                icon="trash-alt"
                small
                >Delete voucher</Button
            >
        </template>
    </Panel>
</template>

<script>
    import { Panel, Tabs, Tab, Sheet, Row, ID, Alerts, Button } from '@tech_hexeko/design-system'
    import VoucherStatus from './VoucherStatus'

    export default {
        name: 'VoucherPanel',
        components: {
            Panel,
            Tabs,
            Tab,
            Sheet,
            Row,
            ID,
            VoucherStatus,
            Button,
        },
        props: {
            id: Number,
        },
        data() {
            return {
                loading: true,
                list: {},
                voucher: null,
            }
        },
        methods: {
            closePanel(args) {
                this.$emit('closePanel', args)
            },
            deleteVoucher() {
                this.$api.vouchers.delete(this.id).then(() => {
                    Alerts.notificationSuccess('Voucher deleted')
                    this.closePanel({ refresh: true })
                })
            },
            fetchData() {
                this.$api.vouchers.show(this.id).then((response) => {
                    // set data
                    this.voucher = response
                    // stop loading
                    this.loading = false
                })
            },
            openList(voucherList) {
                this.openPanel('VoucherListPanel', voucherList.id)
            },
            openBooking(booking) {
                this.openPanel('BookingPanel', booking.id)
            },
            sendToPicker() {
                this.$api.vouchers.sendToPicker(this.id).then(() => {
                    Alerts.notificationSuccess('Successfully send to picker')
                })
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
