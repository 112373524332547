<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 17" width="28" height="17" fill="none">
        <path
            fill="#000"
            fill-rule="evenodd"
            d="M12.451 1.36h2.51a.778.778 0 0 0 .79-.766V0h-3.646a.805.805 0 0 0-.334.072.69.69 0 0 0-.343.29L7.756 6.54A5.715 5.715 0 0 0 5.6 6.12C2.51 6.12 0 8.558 0 11.56 0 14.562 2.51 17 5.6 17s5.6-2.438 5.6-5.44c0-1.774-.876-3.35-2.23-4.343l.803-1.352 3.574 6.013a.69.69 0 0 0 .32.28.71.71 0 0 0 .333.082h2.843C17.188 14.922 19.546 17 22.4 17c3.09 0 5.6-2.438 5.6-5.44 0-3.002-2.51-5.44-5.6-5.44-.77 0-1.504.152-2.172.426l-.854-1.438.476-.801h.945a.778.778 0 0 0 .789-.766v-.594h-3.645a.778.778 0 0 0-.789.766v.594h1.084l-3.865 6.503a.762.762 0 0 0-.02.034l-3.767-6.339 1.87-3.145Zm8.484 6.375c.457-.165.95-.255 1.465-.255 2.318 0 4.2 1.828 4.2 4.08 0 2.251-1.882 4.08-4.2 4.08-2.08 0-3.808-1.472-4.142-3.4h4.143a.732.732 0 0 0 .156-.017h.002a.71.71 0 0 0 .445-.319.66.66 0 0 0 .096-.342v-.003a.651.651 0 0 0-.02-.16v-.003a.66.66 0 0 0-.075-.18l-2.07-3.481Zm-12.672.67A4.028 4.028 0 0 1 9.8 11.56c0 2.251-1.882 4.08-4.2 4.08-2.318 0-4.2-1.829-4.2-4.08 0-2.252 1.882-4.08 4.2-4.08.51 0 .998.088 1.45.25l-2.114 3.556a.67.67 0 0 0 .256.928.712.712 0 0 0 .956-.248l2.115-3.56Zm8.58 2.475h-.9l2.623-4.412.45.758a5.408 5.408 0 0 0-2.172 3.654Zm2.88-2.464 1.465 2.463h-2.93a4.053 4.053 0 0 1 1.466-2.463Z"
            clip-rule="evenodd"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-sustainable-mobility-package',
    }
</script>
