<template>
    <FormRoot :loading="dataLoading">
        <Field
            :form.sync="form"
            type="text"
            name="name"
            :translate="true"
            placeholder="Name"
            hint="Name of the activity"
        ></Field>
        <FieldAddress :form.sync="form" name="address" hasLine2></FieldAddress>
        <div class="row">
            <div class="col-6">
                <Field :form.sync="form" type="phone" name="contacts.phone" placeholder="Phone" hint="Phone"></Field>
            </div>
            <div class="col-6">
                <Field :form.sync="form" type="text" name="contacts.email" placeholder="E-mail" hint="E-mail"></Field>
            </div>
        </div>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit"
                >Update</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import FieldAddress from '@/components/layout/FieldAddress'

    export default {
        data() {
            return {
                fields: {
                    name: null,
                    address: {
                        line1: null,
                        line2: null,
                        zipcode: null,
                        city: null,
                        country: null,
                    },
                    contacts: {
                        phone: null,
                        email: null,
                    },
                    partners: [],
                },
                messageSuccess: 'The activity has been saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
            FieldAddress,
        },
        mounted() {
            this.loadDefaultAddress()
        },
        methods: {
            loadDefaultAddress() {
                if (this.values.partners && this.values.partners.length > 0) {
                    let partner = this.values.partners[0]
                    this.$api.partners.show(partner).then((partner) => {
                        this.form.fillAsOriginalData({
                            address: partner.address,
                        })
                    })
                }
            },
            loadData() {
                return this.$api.activities
                    .show(this.id, {
                        includes: [
                            'types.parent',
                            'address',
                            'contacts',
                            'medias',
                            'first_media',
                            'tags',
                            'processes',
                            'description',
                        ],
                    })
                    .then((activity) => {
                        if (activity.address?.zipcode) activity.address.zipcode = activity.address.zipcode.toString()
                        return activity
                    })
            },
            submit() {
                if (this.id) {
                    return this.$api.activities.update(this.id, this.$data.form.notNullData(['address', 'contacts']))
                } else {
                    return this.$api.activities.create(this.$data.form.notNullData())
                }
            },
        },
    }
</script>
