<template>
    <Badge v-if="expense.is_refund" color="danger">Refund</Badge>
    <Badge v-else color="success">Payment</Badge>
</template>
<script>
    import { Badge } from '@tech_hexeko/design-system'

    export default {
        name: 'ExpenseStatus',
        props: {
            expense: {
                type: Object,
                default: () => ({}),
            },
        },
        components: {
            Badge,
        },
    }
</script>
