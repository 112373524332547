<template>
    <FormRoot v-if="user.groups">
        <Field
            :form.sync="form"
            type="radio"
            name="group"
            :options="user.groups"
            optionsKeyBy="id"
            optionsLabel="name"
            placeholder="Group"
            hint="Which group emit this welcome mail?"
            v-if="user.groups.length > 0"
        />

        <div>
            <Checkbox :value="fields.forceWelcomeMail" @input="fields.forceWelcomeMail = !fields.forceWelcomeMail" small
                >Forced</Checkbox
            >
            <small class="d-block form-text text-muted">
                The welcome mail will be sent even if the user has already received it
            </small>
        </div>

        <FormActions>
            <Submit v-if="!isSent" class="float-right" :disabled="formDisabled" @click="onSubmit"
                >Send welcome mail</Submit
            >
        </FormActions>
    </FormRoot>
    <Alert v-else color="danger">User have not groups</Alert>
</template>

<script>
    import { Alert, Field, FormActions, FormRoot, Submit, Checkbox } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        name: 'WelcomeMail',
        components: {
            Alert,
            Checkbox,
            Field,
            FormActions,
            FormRoot,
            Submit,
        },
        mixins: [Forms],
        props: {
            user: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                fields: { group: null, forceWelcomeMail: false },
                isSent: false,
                messageSuccess: 'The welcome email has been sent',
            }
        },
        methods: {
            submit() {
                this.isSent = true
                if (this.fields.forceWelcomeMail) {
                    return this.$api.users.resendWelcomeMail(this.user.id, {
                        group: this.form.group,
                    })
                } else {
                    return this.$api.users.sendWelcomeMail(this.user.id, {
                        group: this.form.group,
                    })
                }
            },
        },
        mounted() {
            if (this.user.groups.length === 1) {
                this.form.group = this.user.groups[0].id
            }
        },
        computed: {
            formDisabled: function() {
                return this.isSent || (this.user.groups.length > 0 && !this.form.group)
            },
        },
    }
</script>
