<template>
    <FormRoot :loading="dataLoading">
        <Field
            :form.sync="form"
            type="text"
            name="root_name"
            placeholder="Root name"
            hint="Last name of responsible"
        ></Field>
        <Field
            :form.sync="form"
            type="text"
            name="root_surname"
            placeholder="Root surname"
            hint="First name of responsible"
        ></Field>
        <Field
            :form.sync="form"
            type="text"
            name="root_email"
            placeholder="Root e-mail"
            hint="E-mail of responsible"
        ></Field>
        <Field
            :form.sync="form"
            type="text"
            name="root_company_position"
            placeholder="Root company position"
            hint="Company position of responsible"
        ></Field>
        <Field
            :form.sync="form"
            type="phone"
            name="root_mobile"
            placeholder="Root mobile"
            hint="Mobile of responsible"
        ></Field>
        <Field
            :form.sync="form"
            type="date"
            name="root_birthday"
            placeholder="Date of birth"
            hint="Date of birth of the user"
        ></Field>
        <FieldAddress :form.sync="form" name="root_address" hasLine2></FieldAddress>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" @click="onSubmit">Save responsible</Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot } from '@tech_hexeko/design-system'
    import { Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import FieldAddress from '@/components/layout/FieldAddress'
    import moment from 'moment'

    export default {
        data() {
            return {
                fields: {
                    root_name: null,
                    root_surname: null,
                    root_email: null,
                    root_company_position: null,
                    root_mobile: null,
                    root_birthday: null,
                    root_address: {
                        line1: null,
                        line2: null,
                        zipcode: null,
                        city: null,
                        country: null,
                    },
                },
                messageSuccess: 'Responsible saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
            FieldAddress,
        },
        methods: {
            loadData() {
                return this.$api.groups.show(this.id).then((group) => {
                    if (group.root_birthday) {
                        group.root_birthday = moment(group.root_birthday).format('YYYY-MM-DD')
                    }
                    if (group.root_address?.zipcode) {
                        group.root_address.zipcode = group.root_address.zipcode.toString()
                    }
                    return group
                })
            },
            submit() {
                if (this.id) {
                    return this.$api.groups.update(this.id, this.$data.form.notNullData())
                } else {
                    return this.$api.groups.create(this.$data.form.notNullData())
                }
            },
        },
    }
</script>
