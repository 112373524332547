<template>
    <Boolean v-if="card" :value="isActive" :textOn="statusLabel" :textOff="statusLabel" :color="customColor" />
</template>

<script>
    import { Boolean } from '@tech_hexeko/design-system'

    export default {
        name: 'CardStatus',
        components: {
            Boolean,
        },
        props: {
            card: {
                type: Object,
                default: () => ({}),
                required: true,
            },
        },
        computed: {
            isActive() {
                return !this.card.canceled && !this.card.freezed
            },
            statusLabel() {
                if (this.isActive) return 'Active'
                if (this.card.canceled) return 'Cancelled'
                if (this.card.freezed) return 'Frozen'
                return 'Other'
            },
            customColor() {
                if (this.card.freezed) return 'danger'
                return null
            },
        },
    }
</script>
