<template>
    <span>
        <Badge v-if="wallet.creditable_type === 'user'" color="info" icon="user">Personal</Badge>
        <Badge v-if="wallet.creditable_type === 'group'" color="warning" icon="building">Group</Badge>
    </span>
</template>

<script>
    import { Badge } from '@tech_hexeko/design-system'

    export default {
        props: {
            wallet: Object,
        },
        components: {
            Badge,
        },
    }
</script>
