<template>
    <div v-if="payableObject" :class="{ 'cursor-pointer text-primary': clickable }" @click.prevent="openPayable">
        <Icon :icon="payableObject.icon" /> {{ payableObject.name }}
    </div>
</template>

<script>
    import Payables from './payables'

    export default {
        name: 'Payable',
        props: {
            clickable: Boolean,
            payable: Object,
            type: String,
        },
        data() {
            return {
                payables: Payables,
            }
        },
        computed: {
            payableObject() {
                return this.payables[this.type]
            },
        },
        methods: {
            openPayable() {
                if (!this.clickable) return false
                switch (this.type) {
                    case 'allowance':
                        this.openPanel('AllowancePanel', this.payable.id)
                        break
                    case 'booking_request':
                        this.openPanel('BookingRequestPanel', this.payable.id)
                        break
                    case 'card_transaction':
                        this.openPanel('CardTransactionPanel', this.payable.id)
                        break
                    case 'expense_request':
                        this.openPanel('ExpenseRequestPanel', this.payable.id)
                        break
                }
            },
        },
    }
</script>
