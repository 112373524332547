<template>
    <div>
        <Tabs :pills="!embedInTab" :small="embedInTab" @selectTab="selectTab" ref="embedTabs">
            <Tab
                name="Expenses"
                :selected="selected('expenses', true)"
                :to="{ name: 'expenses' }"
                v-if="$store.getters.userHasAnyPermission(['showExpenses', 'manageExpenses'])"
            />
            <Tab
                name="Requests"
                :selected="selected('expense-requests')"
                :to="{ name: 'expense-requests' }"
                v-if="$store.getters.userHasAnyPermission(['showExpenseRequests', 'manageExpenseRequests'])"
            />
            <Tab
                name="Allowances"
                :selected="selected('allowances')"
                :to="{ name: 'allowances' }"
                v-if="showAllowances && $store.getters.userHasAnyPermission(['showAllowances', 'manageAllowances'])"
            />
            <Tab
                name="Payment intents"
                :selected="selected('payment-intents')"
                :to="{ name: 'payment-intents' }"
                v-if="showPaymentIntents && $store.getters.userHasAnyPermission(['showAllowances', 'manageAllowances'])"
            />
            <Tab
                name="Payment transactions"
                :selected="selected('payment-transactions')"
                :to="{ name: 'payment-transactions' }"
                v-if="
                    showPaymentTransactions && $store.getters.userHasAnyPermission(['showExpenses', 'manageExpenses'])
                "
            />
        </Tabs>
        <router-view :params="params" v-if="!embedInTab" />
        <template v-else-if="activeComponent">
            <component :params="params" :is="activeComponent.name" />
        </template>
    </div>
</template>

<script>
    import TabsMixin from '../../../mixins/TabsMixin'
    import Expenses from '../../Expenses'
    import ExpenseRequests from '../../ExpenseRequests'
    import CardAuthorizations from '../../CardAuthorizations'
    import CardTransactions from '../../CardTransactions'
    import Allowances from '../../Allowances'
    import PaymentIntents from '../../PaymentIntents'
    import PaymentTransactions from '../../PaymentTransactions'

    export default {
        components: {
            Expenses,
            ExpenseRequests,
            CardAuthorizations,
            CardTransactions,
            Allowances,
            PaymentIntents,
            PaymentTransactions,
        },
        mixins: [TabsMixin],
        props: {
            showAllowances: {
                type: Boolean,
                default: true,
            },
            showPaymentIntents: {
                type: Boolean,
                default: true,
            },
            showPaymentTransactions: {
                type: Boolean,
                default: true,
            },
        },
    }
</script>
