<template>
    <div>
        <Tabs :pills="!embedInTab" :small="embedInTab" @selectTab="selectTab" ref="embedTabs">
            <Tab
                name="Quotes"
                :selected="selected('quotes', true)"
                :to="{ name: 'quotes' }"
                v-if="$store.getters.userHasAnyPermission(['viewAnyQuotes', 'manageQuotes'])"
            />
            <Tab
                name="Invoices"
                :selected="selected('invoices')"
                :to="{ name: 'invoices' }"
                v-if="$store.getters.userHasAnyPermission(['viewAnyInvoices', 'manageInvoices'])"
            />
            <Tab
                name="Credit notes"
                :selected="selected('credit-notes')"
                :to="{ name: 'credit-notes' }"
                v-if="$store.getters.userHasAnyPermission(['viewAnyInvoices', 'manageInvoices'])"
            />
            <Tab
                name="Billing accounts"
                :selected="selected('accounts')"
                :to="{ name: 'accounts' }"
                v-if="$store.getters.userHasAnyPermission(['viewAnyBillingAccounts', 'manageBillingAccounts'])"
            />
            <Tab
                name="Products"
                :selected="selected('products')"
                :to="{ name: 'products' }"
                v-if="$store.getters.userHasAnyPermission(['viewAnyProducts', 'manageProducts'])"
            />
        </Tabs>
        <router-view v-if="!embedInTab" />
        <template v-else-if="activeComponent">
            <component :params="params" :is="activeComponent.name" />
        </template>
    </div>
</template>

<script>
    import TabsMixin from '../../../mixins/TabsMixin'

    export default {
        mixins: [TabsMixin],
    }
</script>
