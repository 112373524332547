import Vue from 'vue';
import VueSlideoutPanel from 'vue2-slideout-panel';
Vue.use(VueSlideoutPanel);

export default {
    methods: {
        openPanel(component, id) {
            // Open panel
            return this.$showPanel({
                component: component,
                width: 1000,
                openOn: 'right',
                props: {
                    id: id,
                },
            });
        },
    },
};
