<template>
    <div>
        <Spinner v-if="loading" />
        <template v-else>
            <Alert color="warning" v-if="all_mccs.length === 0">
                No merchant category code available
            </Alert>
            <template v-else>
                <Button small color="light" @click="selectAll" icon="check-square" class="me-1">Select all</Button>
                <Button small color="light" @click="unselectAll" :icon="['far', 'square']">Unselect all</Button>
                <Card class="mt-1 mb-3" color="light">
                    <Checkbox
                        v-model="form_mccs"
                        :modelValue="mcc.id"
                        v-for="(mcc, index) in all_mccs"
                        :key="index"
                        class="mb-1"
                        small
                    >
                        {{ mcc.name }}
                        <div class="d-inline-block mx-2 text-xxs text-muted">{{ mcc.code }}</div>
                        <Badge small color="secondary">{{ mcc.slug }}</Badge></Checkbox
                    >
                </Card>
                <Submit @click="save" :disabled="loading">Save</Submit>
            </template>
        </template>
    </div>
</template>

<script>
    import { Alert, Button, Checkbox, Badge, Card, Spinner, Submit } from '@tech_hexeko/design-system'

    export default {
        name: 'MCC',
        props: {
            value: Array,
        },
        data() {
            return {
                loading: true,
                all_mccs: [],
                form_mccs: [],
            }
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.loading = true
                this.$api.mccs
                    .get({ per_page: 10000 })
                    .then((response) => {
                        this.all_mccs = response.data
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            selectAll() {
                this.all_mccs.forEach((mcc) => {
                    this.form_mccs.push(mcc.id)
                })
            },
            unselectAll() {
                this.form_mccs = []
            },
            save() {
                this.$emit('save', this.form_mccs)
            },
        },
        watch: {
            value: {
                deep: true,
                immediate: true,
                handler: function() {
                    this.form_mccs = this.value
                },
            },
            form_mccs: {
                deep: true,
                immediate: true,
                handler: function() {
                    this.$emit('input', this.form_mccs)
                },
            },
        },
        components: {
            Button,
            Checkbox,
            Alert,
            Badge,
            Card,
            Spinner,
            Submit,
        },
    }
</script>
