<template>
    <Datatable
        name="Transactions"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
    >
        <template v-slot:thead>
            <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Payment Method</th>
                <th>Amount</th>
                <th></th>
            </tr>
        </template>
        <tr
            valign="middle"
            @click="openTransactionPanel(transaction)"
            v-for="(transaction, index) in transactions"
            :key="'transaction-' + index"
        >
            <td>
                <code>{{ transaction.id }}</code>
            </td>
            <td>
                <div>{{ transaction.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</div>
            </td>
            <td>
                <SelectedPaymentMethod
                    :paymentMethod="{ payment_method: transaction.payment_method }"
                    :clickable="false"
                    smallIcons
                />
            </td>
            <td>{{ transaction.amount | currency }}</td>
            <td class="text-end"><Icon icon="angle-right" /></td>
        </tr>
    </Datatable>
</template>

<script>
    import { Boolean, Datatable } from '@tech_hexeko/design-system'
    import SelectedPaymentMethod from '@/components/layout/SelectedPaymentMethod'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'

    export default {
        name: 'PaymentTransactions',
        components: {
            Datatable,
            SelectedPaymentMethod,
        },
        props: {
            hideActions: Boolean,
            params: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                loading: true,
                pagination: { current_page: 1 },
                search: null,
                transactions: [],
            }
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.search = search
                this.$api.paymentTransactions
                    .get(
                        this.mergeParams({
                            search: this.search,
                            page: this.pagination.current_page,
                            includes: ['payer', 'payment_intent.payable', 'payment_method.source'],
                        })
                    )
                    .then((response) => {
                        this.transactions = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
            },
            mergeParams(params) {
                if (this.params.user) this.params.payer = this.params.user
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            openTransactionPanel(transaction) {
                let panel = this.openPanel('PaymentTransactionPanel', transaction.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
