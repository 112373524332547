<template>
    <div>
        <Datatable
            name="Partners"
            @search="fetchData"
            :pagination.sync="pagination"
            :loading="loading"
            :hideActions="hideActions"
        >
            <template v-slot:filters>
                <Button
                    class="me-1"
                    @click="toggleFilter('suggested')"
                    :color="suggested === null ? 'secondary' : suggested ? 'success' : 'danger'"
                    small
                    >Suggested</Button
                >
                <Button
                    class="me-1"
                    @click="toggleFilter('enabled')"
                    :color="enabled === null ? 'secondary' : enabled ? 'success' : 'danger'"
                    small
                    >Enabled</Button
                >
                <Dropdown v-if="countries" class="me-1" color="secondary" title="Filter by country" small>
                    <li v-for="(country, index) in countries" :key="index">
                        <a
                            href="#"
                            class="dropdown-item text-xs"
                            :class="selectedCountry.includes(country) ? 'bg-primary text-white' : 'bg-white'"
                            @click.prevent="filterByCountry(country)"
                            >{{ country }}</a
                        >
                    </li>
                </Dropdown>
                <Dropdown
                    v-if="types"
                    class="dropdown-menu-container has--overflow me-1"
                    color="secondary"
                    :title="titleFilterByTypes"
                    small
                >
                    <template v-for="(type, index) in types">
                        <li :key="`type-${index}`">
                            <a
                                href="#"
                                class="dropdown-item text-xs fw-semibold"
                                :class="selectedTypes.includes(type.id) ? 'bg-primary text-white' : 'bg-white'"
                                @click.prevent="filterByType(type.id)"
                                >{{ type.name[$i18n.locale] }}</a
                            >
                        </li>
                        <template v-if="type.children && type.children.length > 0">
                            <!-- Subtypes -->
                            <li v-for="(subType, subIndex) in type.children" :key="`type-${index}-subtype-${subIndex}`">
                                <a
                                    href="#"
                                    class="dropdown-item text-xs"
                                    :class="selectedTypes.includes(subType.id) ? 'bg-primary text-white' : 'bg-white'"
                                    @click.prevent="filterByType(subType.id)"
                                    >&mdash; {{ subType.name[$i18n.locale] }}</a
                                >
                            </li>
                        </template>
                    </template>
                </Dropdown>
            </template>
            <template v-slot:thead>
                <tr>
                    <th v-if="$store.getters.userHasPermission('managePartners')"></th>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Name</th>
                    <th>City</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </template>
            <tr
                v-for="(partner, index) in partners"
                @click="open(partner)"
                class="cursor-pointer"
                :key="'partner-' + index"
            >
                <td @click.stop="toggleSelect(partner)" v-if="$store.getters.userHasPermission('managePartners')">
                    <ToggleCheckbox :value="isSelected(partner)" />
                </td>
                <td>
                    <code>{{ partner.id }}</code>
                </td>
                <td>{{ partner.created_at | formatDate }}</td>
                <td>{{ partner.name[$store.state.lang.displayLanguage] }}</td>
                <td>
                    <template v-if="partner.address"
                        >{{ partner.address.city }}
                        <template v-if="partner.address.country">({{ partner.address.country }})</template></template
                    >
                </td>
                <td><Boolean v-model="partner.enabled" textOn="Enabled" textOff="Disabled"></Boolean></td>
                <td class="text-right"><Icon icon="angle-right" /></td>
            </tr>
            <template v-slot:actions v-if="$store.getters.userHasPermission('managePartners')">
                <SelectAllButton
                    :metaSelected="metaSelected"
                    @selectAll="selectAll"
                    @unselectAll="unselectAll"
                    @reset="reset"
                >
                    <DropdownItem icon="certificate" @click="openModal('updatePartnersTypes')"
                        >Update types</DropdownItem
                    >
                    <DropdownItem icon="check-circle" @click="bulkEnable">Enable</DropdownItem>
                    <DropdownItem icon="times-circle" confirm @confirm="bulkDisable">Disable</DropdownItem>
                </SelectAllButton>
                <Button small @click="openModal('createPartner')" icon="plus">Create partner</Button>

                <!-- Modal Create -->
                <Modal v-if="isModalOpen('createPartner')" @close="closeModal('createPartner')" title="Create partner">
                    <UpdatePartner :values="params" @submitted="partnerCreated"></UpdatePartner>
                </Modal>

                <!-- Modal Update Partner Types -->
                <Modal
                    v-if="isModalOpen('updatePartnersTypes')"
                    @close="closeModal('updatePartnersTypes')"
                    title="Update Types"
                >
                    <TypesSelector @save="BulkTypesSave" />
                </Modal>
            </template>
        </Datatable>
    </div>
</template>

<script>
    import {
        Datatable,
        Boolean,
        Button,
        Modal,
        ToggleCheckbox,
        Dropdown,
        DropdownItem,
    } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import UpdatePartner from './UpdatePartner'
    import SelectAll from '../../mixins/SelectAll'
    import SelectAllButton from '@/components/layout/SelectAllButton'
    import TypesSelector from '../Types/Selector'
    export default {
        name: 'Partners',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                search: null,
                pagination: {
                    current_page: 1,
                },
                partners: [],
                suggested: null,
                enabled: null,
                countries: ['FR', 'BE'],
                types: null,
                selectedCountry: [],
                selectedTypes: [],
            }
        },
        components: {
            Datatable,
            Boolean,
            UpdatePartner,
            Button,
            Modal,
            SelectAllButton,
            ToggleCheckbox,
            Dropdown,
            DropdownItem,
            TypesSelector,
        },
        mixins: [Modals, SelectAll],
        computed: {
            titleFilterByTypes() {
                let title = 'Filter by types'
                if (this.selectedTypes && this.selectedTypes.length > 0) title += ` (${this.selectedTypes.length})`
                return title
            },
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.search = search
                let params = this.mergeParams({
                    search: this.search,
                    page: this.pagination.current_page,
                    includes: ['address'],
                    suggested: this.suggested,
                    enabled: this.enabled,
                    countries: this.selectedCountry.length > 0 ? this.selectedCountry : undefined,
                    types: this.selectedTypes.length > 0 ? this.selectedTypes : undefined,
                })
                this.$api.partners
                    .get(params)
                    .then((response) => {
                        this.partners = response.data
                        this.pagination = response.meta.pagination
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            fetchTypes() {
                this.$api.types.get({ depth: 1 }).then((response) => {
                    this.types = response.data
                })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            open(partner) {
                let panel = this.openPanel('partnerPanel', partner.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
            partnerCreated() {
                this.closeModal('createPartner')
                this.fetchData(this.search)
            },
            allIds() {
                return this.$options.filters.pluck(this.partners, 'id')
            },
            bulkEnable() {
                this.bulkAction('Enable partners', (id) => {
                    return this.$api.partners.enable(id)
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            bulkDisable() {
                this.bulkAction('Disable partners', (id) => {
                    return this.$api.partners.disable(id)
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            toggleFilter(variable) {
                let value = this[variable] === null ? 1 : this[variable] === 1 ? 0 : null
                this.$set(this, variable, value)
                this.pagination.current_page = 1
                this.fetchData()
            },
            BulkTypesSave(types) {
                this.closeModal('updatePartnersTypes')
                this.bulkAction('Update partners types', (id) => {
                    return this.$api.partners.update(id, { types: types })
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            filterByCountry(countryCode) {
                if (this.selectedCountry.includes(countryCode)) {
                    this.selectedCountry.splice(this.selectedCountry.indexOf(countryCode), 1)
                } else {
                    this.selectedCountry = [countryCode]
                }
                this.fetchData(this.search)
            },
            filterByType(typeId) {
                if (this.selectedTypes.includes(typeId)) {
                    this.selectedTypes.splice(this.selectedTypes.indexOf(typeId), 1)
                } else {
                    this.selectedTypes.push(typeId)
                }
                this.fetchData(this.search)
            },
        },
        mounted() {
            this.fetchTypes()
            this.fetchData()
        },
    }
</script>

<style lang="scss">
    .dropdown-menu-container {
        &.has--overflow {
            ul.dropdown-menu {
                max-height: 600px;
                overflow: auto;
            }
        }
    }
</style>
