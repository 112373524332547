<template>
    <FormRoot>
        <!-- Dropzone -->
        <Dropzone v-model="files"></Dropzone>
        <InputHint class="mb-4">Click to select files or drag-and-drop from your computed</InputHint>
        <!-- Form -->
        <Field
            :form.sync="form"
            type="text"
            name="value"
            placeholder="Value in €"
            hint="Enter a positive amount in euros"
        ></Field>
        <Field
            :form.sync="form"
            type="datetime"
            name="expiration_date"
            placeholder="Expiration date"
            hint="End of validity (format 2021-01-01 23:10:00)"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="formDisabled" @click="uploadVouchers">Upload vouchers</Submit>
        </FormActions>
        <progress :max="queueCount" :value="progress" v-if="loading"> {{ progress }}%</progress>
    </FormRoot>
</template>

<script>
    import { FormRoot, Dropzone, Field, FormActions, InputHint, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    let _ = require('lodash')

    export default {
        props: {
            list: Number,
        },
        data() {
            return {
                files: [],
                startUpload: false,
                fields: {
                    value: null,
                    expiration_date: null,
                },
                queueCount: 0,
                progress: 0,
                loading: false,
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
            Dropzone,
            InputHint,
        },
        methods: {
            uploadVouchers() {
                // Start progress
                this.loading = true
                this.progress = 0
                this.queueCount = _.clone(this.filesToUpload.length)

                // Create requests
                let requests = []
                _.forEach(this.filesToUpload, async (file) => {
                    requests.push(this.createVoucher(file))
                })

                // Start requests
                Promise.all(requests)
                    .then((values) => {
                        let filesUploaded = _.filter(values, function(file) {
                            return file.success
                        })
                        if (filesUploaded.length === this.queueCount) {
                            this.$emit('finished')
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            createVoucher(file) {
                return new Promise((resolve, reject) => {
                    // Reset result
                    file.error = false

                    // Create form data
                    const formData = new FormData()
                    formData.append('attach_file', file.file)
                    formData.append('value', this.form.value)
                    formData.append('expiration_date', this.form.expiration_date)

                    // Set config
                    let config = {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }

                    // Create voucher
                    this.$api.voucherLists
                        .createVoucher(this.list, formData, config)
                        .then(() => {
                            file.success = true
                            this.progress++
                            resolve(file)
                        })
                        .catch((error) => {
                            let errors = error.response.data.errors
                            file.error = _(errors)
                                .values()
                                .flatten()
                                .value()
                            console.log(file.error)
                            reject(file)
                        })
                })
            },
        },
        computed: {
            formDisabled: function() {
                return !this.form.submittable || this.loading || this.files.length === 0
            },
            filesToUpload: function() {
                return _.filter(this.files, function(file) {
                    return !file.success
                })
            },
        },
    }
</script>
