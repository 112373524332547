<template>
    <svg viewBox="0 0 1200 1200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m1057.2 516-56.398-296.4c-9.602-54-57.602-93.602-112.8-93.602h-554.4c-55.199 0-102 39.602-112.8 93.602L165.603 516h-2.398c-33.602 4.8-92.398 43.199-92.398 117.6L70.803 888h82.8v88.801c0 109.2 153.6 108 153.6 0L307.2 888h607.2v88.801c0 108 154.8 109.2 154.8 0V888h82.8V634.8c-1.198-74.402-60-114-94.8-118.8zm-822 206.4c-31.199 0-57.602-25.199-56.398-56.398 0-31.199 25.199-57.602 56.398-56.398 31.199 0 56.398 25.199 56.398 56.398 1.203 31.199-25.199 56.398-56.398 56.398zm654-206.4-4.8-25.199c-6-33.602-22.802-56.398-46.802-69.602-10.8-6-21.602-9.602-33.602-12-9.601-2.398-19.199-2.398-28.8-2.398-9.602 0-19.2 1.199-28.802 2.398-12 2.398-24 7.2-33.602 12-24 13.199-40.8 36-46.8 69.602L661.197 516h-105.6l-4.8-25.199c-6-33.602-22.802-56.398-46.802-69.602-10.8-6-21.602-9.602-33.602-12-9.601-2.398-19.199-2.398-28.8-2.398s-19.2 1.199-28.802 2.398c-12 2.398-24 7.2-33.602 12-24 13.199-40.8 36-46.8 69.602L326.396 516h-84l48-255.6c7.2-39.602 42-67.199 81.602-67.199h480c39.602 0 74.398 28.801 81.602 67.199l48 255.6zm99.602 206.4c-31.199 0-56.398-25.199-57.602-56.398 0-31.199 25.199-57.602 57.602-56.398 31.199 0 56.398 25.199 56.398 56.398s-26.398 56.398-56.398 56.398zM512.402 318c0 39.602-32.398 72-72 72s-72-32.398-72-72 32.398-72 72-72 72 31.199 72 72zm334.8 0c0 39.602-32.398 72-72 72s-72-32.398-72-72 32.398-72 72-72 72 31.199 72 72z"
            fill="#000"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-carpooling',
    }
</script>
