<template>
    <div>
        <Spinner v-if="loading" />
        <Alert v-else-if="error" color="warning" icon="exclamation-circle">{{ error }}</Alert>
        <Card noPadding v-else>
            <Table striped borderless>
                <template v-for="typeSelector in allTypes">
                    <tr>
                        <td>
                            <Checkbox v-model="selectedTypes" :modelValue="typeSelector.id" @input="emit">{{
                                typeSelector.name[$store.state.lang.displayLanguage]
                            }}</Checkbox>
                        </td>
                    </tr>
                    <tr v-for="children in typeSelector.children">
                        <td class="ps-5">
                            <Checkbox v-model="selectedTypes" :modelValue="children.id" @input="emit">{{
                                children.name[$store.state.lang.displayLanguage]
                            }}</Checkbox>
                        </td>
                    </tr>
                </template>
            </Table>
        </Card>
        <div class="mt-3" v-if="saveButton">
            <Button icon="check" color="success" @click="save">Save</Button>
        </div>
    </div>
</template>
<script>
    import { Table, Checkbox, Card, Button, Spinner, Alert } from '@tech_hexeko/design-system'
    let _ = require('lodash')

    export default {
        props: {
            types: Array,
            depth: {
                type: Number,
                default: 1,
            },
            saveButton: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                loading: true,
                loadingType: false,
                allTypes: [],
                selectedTypes: [],
                error: null,
            }
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.loading = true
                this.$api.types
                    .get({ per_page: 100, depth: this.depth })
                    .then((types) => {
                        this.allTypes = types.data
                        this.error = null
                    })
                    .catch((error) => {
                        this.error = error.response.data.message
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            save() {
                this.$emit('save', this.selectedTypes)
            },
            emit() {
                this.$emit('input', this.selectedTypes)
            },
        },
        components: {
            Table,
            Checkbox,
            Card,
            Button,
            Spinner,
            Alert,
        },
        watch: {
            types: {
                deep: true,
                immediate: true,
                handler: function(val) {
                    if (val) {
                        this.selectedTypes = _.map(this.types, 'id')
                    }
                },
            },
        },
    }
</script>
