<template>
    <FormRoot :loading="dataLoading">
        <Field
            :form.sync="form"
            type="text"
            name="first_name"
            placeholder="First name"
            hint="First name of the user"
        ></Field>
        <Field
            :form.sync="form"
            type="text"
            name="last_name"
            placeholder="Last name"
            hint="Last name of the user"
        ></Field>
        <Field :form.sync="form" type="text" name="email" placeholder="E-mail" hint="E-mail of the user"></Field>
        <Field
            :form.sync="form"
            type="date"
            name="birthdate"
            placeholder="Date of birth"
            hint="Date of birth of the user"
        ></Field>
        <FieldAddress :form.sync="form" name="address" hasLine2></FieldAddress>
        <Field :form.sync="form" type="phone" name="phone" placeholder="Phone" hint="Phone"></Field>
        <Field
            :form.sync="form"
            type="radio"
            name="locale"
            :options="$store.state.lang.availableLanguages"
            hint="Locale of the user (ex: en)"
        ></Field>
        <FieldTimezone :form.sync="form" />
        <Field
            :form.sync="form"
            type="radio"
            name="store_id"
            :options="$store.state.admin.stores"
            optionsKeyBy="id"
            optionsLabel="name"
            hint="The store open to the user"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" @click="onSubmit">Save user</Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot } from '@tech_hexeko/design-system'
    import { Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import FieldAddress from '@/components/layout/FieldAddress'
    import FieldTimezone from '@/components/layout/FieldTimezone'
    import moment from 'moment'

    export default {
        data() {
            return {
                fields: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    birthdate: null,
                    address: {
                        line1: null,
                        line2: null,
                        zipcode: null,
                        city: null,
                        country: null,
                    },
                    phone: null,
                    locale: null,
                    store_id: null,
                    timezone: null,
                },
                messageSuccess: 'The user has been saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
            FieldAddress,
            FieldTimezone,
        },
        methods: {
            loadData() {
                return this.$api.users.show(this.id).then((user) => {
                    if (user.birthdate) {
                        user.birthdate = moment(user.birthdate).format('YYYY-MM-DD')
                    }
                    if (user.address?.zipcode) {
                        user.address.zipcode = user.address.zipcode.toString()
                    }
                    return user
                })
            },
            submit() {
                if (this.id) {
                    return this.$api.users.update(this.id, this.$data.form.notNullData(['phone']))
                } else {
                    return this.$api.users.create(this.$data.form.notNullData())
                }
            },
        },
    }
</script>
