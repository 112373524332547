<template>
    <FormRoot>
        <div class="mb-4">
            <p>
                This will change the status from <strong>{{ doc.status }}</strong> to <strong>{{ status }}</strong
                >.
            </p>
            <ul>
                <li v-if="doc && doc.type">
                    Document type: <strong>{{ doc.type }}</strong>
                </li>
            </ul>
        </div>
        <Field
            v-if="needReason"
            :form.sync="form"
            type="textarea"
            name="reason"
            hint="Explanation of new status to the user"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="isSubmitDisabled" :loading="form.loading" @click="onSubmit"
                >Set status</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        mixins: [Forms],
        components: {
            FormActions,
            FormRoot,
            Field,
            Submit,
        },
        data() {
            return {
                fields: { reason: '', status: this.status },
                messageSuccess: 'The status has been set',
            }
        },
        props: {
            doc: {
                type: Object,
                required: true,
            },
            status: {
                type: String,
                required: true,
                validator: (value) => {
                    return ['succeeded', 'in_progress', 'failed', 'manually_verified'].includes(value)
                },
            },
        },
        computed: {
            isSubmitDisabled() {
                return this.needReason && (!this.form.reason || !this.form.reason.trim())
            },
            needReason() {
                return this.status === 'failed'
            },
        },
        methods: {
            submit() {
                return this.$verificationApi.verifications.updateDocumentStatus(this.doc.id, this.$data.form.data())
            },
        },
    }
</script>
