<template>
    <nav class="navbar navbar-expand-lg navbar-light navbar-main bg-light">
        <div class="container-fluid">
            <router-link class="navbar-brand" :to="{ name: 'dashboard' }">
                <Logo logomark width="30" />
            </router-link>
            <button class="navbar-toggler" type="button" @click="openedNav = !openedNav">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav" :class="{ show: openedNav }">
                <ul class="navbar-nav mt-1">
                    <li class="nav-item ms-3" v-if="$store.getters.userHasAnyPermission(['showUsers', 'manageUsers'])">
                        <router-link class="nav-link" :to="{ name: 'users' }">Users</router-link>
                    </li>
                    <li class="nav-item ms-3" v-if="$store.getters.userHasAnyPermission(['showCards', 'manageCards'])">
                        <router-link class="nav-link" :to="{ name: 'cards' }">Cards</router-link>
                    </li>
                    <li
                        class="nav-item ms-3"
                        v-if="$store.getters.userHasAnyPermission(['showExpenseRequests', 'manageExpenseRequests'])"
                    >
                        <router-link class="nav-link" :to="{ name: 'expenses' }">Expenses</router-link>
                    </li>
                    <li
                        class="nav-item ms-3"
                        v-if="$store.getters.userHasAnyPermission(['showBookings', 'manageBookings'])"
                    >
                        <router-link class="nav-link" :to="{ name: 'bookings' }">Bookings</router-link>
                    </li>
                    <li
                        class="nav-item ms-3"
                        v-if="$store.getters.userHasAnyPermission(['showPartners', 'managePartners'])"
                    >
                        <router-link class="nav-link" :to="{ name: 'partners' }">Partners</router-link>
                    </li>
                    <li
                        class="nav-item ms-3"
                        v-if="$store.getters.userHasAnyPermission(['showVouchers', 'manageVouchers'])"
                    >
                        <router-link class="nav-link" :to="{ name: 'vouchers' }">Vouchers</router-link>
                    </li>
                    <li
                        class="nav-item ms-3"
                        v-if="$store.getters.userHasAnyPermission(['showPayouts', 'managePayouts'])"
                    >
                        <router-link class="nav-link" :to="{ name: 'payouts' }">Payouts</router-link>
                    </li>
                    <li
                        class="nav-item dropdown ms-3"
                        v-if="
                            $store.getters.userHasAnyPermission([
                                'viewAnyBillingAccounts',
                                'manageBillingAccounts',
                                'viewAnyQuotes',
                                'manageQuotes',
                                'viewAnyInvoices',
                                'manageInvoices',
                                'viewAnyProducts',
                                'manageProducts',
                            ])
                        "
                    >
                        <a href="#" class="nav-link dropdown-toggle" @click.prevent="toggleTab('billing')">
                            Billing
                        </a>
                        <ul
                            class="dropdown-menu d-block text-xs"
                            aria-labelledby="billingDropdown"
                            v-if="tabIsShown('billing')"
                        >
                            <li>
                                <router-link
                                    class="nav-link px-2"
                                    :to="{ name: 'quotes' }"
                                    v-if="$store.getters.userHasAnyPermission(['viewAnyQuotes', 'manageQuotes'])"
                                    ><Icon icon="file-contract" /> Quotes</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    class="nav-link px-2"
                                    :to="{ name: 'invoices' }"
                                    v-if="$store.getters.userHasAnyPermission(['viewAnyInvoices', 'manageInvoices'])"
                                    ><Icon icon="file-invoice" /> Invoices</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    class="nav-link px-2"
                                    :to="{ name: 'credit-notes' }"
                                    v-if="$store.getters.userHasAnyPermission(['viewAnyInvoices', 'manageInvoices'])"
                                    ><Icon icon="file-invoice" /> Credit notes</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    class="nav-link px-2"
                                    :to="{ name: 'accounts' }"
                                    v-if="
                                        $store.getters.userHasAnyPermission([
                                            'viewAnyBillingAccounts',
                                            'manageBillingAccounts',
                                        ])
                                    "
                                    ><Icon icon="building" /> Accounts</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    class="nav-link px-2"
                                    :to="{ name: 'products' }"
                                    v-if="$store.getters.userHasAnyPermission(['viewAnyProducts', 'manageProducts'])"
                                    ><Icon icon="shopping-cart" /> Products</router-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li
                        class="nav-item dropdown ms-3"
                        v-if="
                            $store.getters.userHasAnyPermission([
                                'showStores',
                                'manageStores',
                                'showPrivileges',
                                'managePrivileges',
                            ])
                        "
                    >
                        <a href="#" class="nav-link dropdown-toggle" @click.prevent="toggleTab('config')">
                            Configuration
                        </a>
                        <ul class="dropdown-menu d-block text-xs" v-if="tabIsShown('config')">
                            <li>
                                <router-link
                                    class="nav-link px-2"
                                    :to="{ name: 'stores' }"
                                    v-if="$store.getters.userHasAnyPermission(['showStores', 'manageStores'])"
                                    ><Icon icon="store" /> Stores</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    class="nav-link px-2"
                                    :to="{ name: 'roles' }"
                                    v-if="$store.getters.userHasAnyPermission(['showPrivileges', 'managePrivileges'])"
                                    ><Icon icon="shield-alt" /> Roles</router-link
                                >
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    import { Logo } from '@tech_hexeko/design-system'
    import { router } from '@tech_hexeko/base-front'

    export default {
        name: 'Navbar',
        data() {
            return {
                openedNav: false,
                tab: null,
            }
        },
        components: {
            Logo,
        },
        mounted() {
            router.beforeEach((to, from, next) => {
                this.tab = null
                next()
            })
        },
        methods: {
            toggleTab(tab) {
                this.tab = this.tabIsShown(tab) ? null : tab
            },
            tabIsShown(tab) {
                return this.tab === tab
            },
        },
    }
</script>

<style>
    .navbar-toggler,
    .navbar-toggler:focus {
        border: none !important;
        box-shadow: none !important;
    }
</style>
