<template>
    <div v-if="slug">
        <component v-if="componentName" :is="componentName" class="me-1" :width="size" :height="size" />
        <small class="d-inline-block text-uppercase text-muted">{{ slug }}</small>
    </div>
</template>

<script>
    import { IconMasterCard, IconVisa } from '@tech_hexeko/design-system/src/components/Icons'

    export default {
        name: 'Brand',
        components: {
            'icon-mastercard': IconMasterCard,
            IconVisa,
        },
        props: {
            size: {
                type: Number,
                default: 26,
            },
            slug: {
                type: String,
                required: true,
            },
        },
        computed: {
            componentName() {
                return this.slugFormatted ? `icon-${this.slugFormatted}` : ''
            },
            slugFormatted() {
                return this.slug ? this.slug.toLowerCase() : ''
            },
        },
    }
</script>
