<template>
    <div>
        <div v-if="loading" class="content-center text-center">
            <Spinner size="2x" class="text-muted" />
        </div>
        <div v-else>
            <Stat :value="balance" money decimals legend="Total balance" class="mb-3" icon="euro-sign"></Stat>
            <UserPaymentMethods :payment_methods="payment_methods" @click="open" showWithoutBalance />
        </div>
    </div>
</template>

<script>
    import UserPaymentMethods from '@tech_hexeko/hexeko-user-account/src/components/PaymentMethods'
    import { Boolean, Badge, Spinner, Table, Stat } from '@tech_hexeko/design-system'

    export default {
        name: 'PaymentMethods',
        components: {
            UserPaymentMethods,
            Badge,
            Spinner,
            Table,
            Stat,
        },
        props: {
            user: Number,
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                balance: null,
                payment_methods: [],
            }
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.loading = true
                this.$api.users
                    .getPaymentMethods(this.user, {
                        includes: [
                            'payment_methods.source.types.slugs',
                            'payment_methods.source.balances',
                            'payment_methods.source.total_deposit',
                        ],
                    })
                    .then((response) => {
                        this.balance = response.balance
                        this.payment_methods = response.payment_methods
                        this.loading = false
                    })
            },
            open(paymentMethod) {
                this.openPanel('PaymentMethodPanel', paymentMethod.id)
            },
        },
    }
</script>
