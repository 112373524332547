export default {
    group_account: {
        name: 'Group account',
    },
    physical_card: {
        name: 'Physical card',
    },
    user_account: {
        name: 'User account',
    },
    virtual_card: {
        name: 'Virtual card',
    },
    wallet_activation: {
        name: 'Wallet activation',
    },
};
