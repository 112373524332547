<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 390 390">
        <path
            d="M301.443 111.553V88.235h40.321V0H48.235v88.235h40.321v23.318c0 28.988 16.106 55.049 42.034 68.013L161.459 195l-30.868 15.434c-25.928 12.964-42.034 39.025-42.034 68.013v23.318H48.235V390h293.529v-88.235h-40.321v-23.318c0-28.988-16.106-55.049-42.034-68.013L228.541 195l30.868-15.434c25.928-12.964 42.034-39.025 42.034-68.013zM78.235 30h233.529v28.235H78.235V30zm233.53 330H78.235v-28.235h233.529V360zm-65.772-122.733c15.698 7.849 25.45 23.628 25.45 41.18v23.318H118.557v-23.318c0-17.551 9.752-33.331 25.45-41.18L195 211.771l50.993 25.496zm0-84.534L195 178.229l-50.993-25.497c-15.698-7.849-25.45-23.628-25.45-41.18V88.235h152.887v23.318c-.001 17.551-9.753 33.331-25.451 41.18z"
            fill="#000"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-closing',
    }
</script>
