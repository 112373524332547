export default {
    in_progress: {
        name: 'In progress',
        color: 'warning',
        icon: 'hourglass-half',
        slug: 'in_progress',
    },
    processed: {
        name: 'Processed',
        color: 'success',
        icon: 'check-circle',
        slug: 'processed',
    },
    scheduled: {
        name: 'Scheduled',
        color: 'secondary',
        icon: 'calendar-alt',
        slug: 'scheduled',
    },
    declined: {
        name: 'Declined',
        color: 'danger',
        icon: 'stop-circle',
        slug: 'declined',
    },
    auto_confirm_successful: {
        name: 'Auto confirm successful',
        color: 'success',
        icon: 'robot',
        slug: 'auto_confirm_successful',
    },
    cancelled: {
        name: 'Cancelled',
        color: 'danger',
        icon: 'times-circle',
        slug: 'cancelled',
    },
    confirmed: {
        name: 'Confirmed',
        color: 'success',
        icon: 'chevron-circle-right',
        slug: 'confirmed',
    },
    pending: {
        name: 'Pending',
        color: 'warning',
        icon: 'question-circle',
        slug: 'pending',
    },
    created: {
        name: 'Created',
        color: 'secondary',
        icon: 'pause-circle',
        slug: 'created',
    },
    partially_processed: {
        name: 'Partially processed',
        color: 'warning',
        icon: 'check-circle',
        slug: 'partially_processed',
    },
};
