<template>
    <Panel :title="title" :loading="loading" @closePanel="$emit('closePanel', { refresh: false })">
        <Tabs v-if="invoice">
            <!-- Invoice -->
            <Tab :name="title" :selected="true">
                <div class="mb-2">
                    <InvoiceStatus :invoice="invoice" />
                    <Badge v-if="quote && quote.processed_at" class="ms-1" color="success" icon="check-circle"
                        >Processed</Badge
                    >
                    <Alert
                        v-if="!!quote && !quote.processed_at && !!invoice && invoice.paid_at"
                        icon="info-circle"
                        color="warning"
                        class="mt-2"
                        >This invoice has been paid but hasn't been processed. You always must process invoices
                        manually, otherwise user’s wallets won’t be deposited.</Alert
                    >
                </div>
                <div class="progress-container" v-if="!invoice.canceled_at">
                    <Steps :steps="stepsArray" :active="activeSteps" />
                </div>

                <Sheet name="Details" class="mb-2">
                    <Row name="Date">{{ invoice.finalized_at | formatDate }}</Row>
                    <Row name="Date of draft">{{ invoice.created_at | formatDate }}</Row>
                    <Row name="Reference" v-if="invoice.reference">{{ invoice.reference }}</Row>
                    <Row name="Total">{{ invoice.total | currency }}</Row>
                    <Row name="Tax amount included" v-if="invoice.tax_amount_included">{{
                        invoice.tax_amount_included | currency
                    }}</Row>
                    <Row name="Fees amount included" v-if="invoice.fees_amount_included">{{
                        invoice.fees_amount_included | currency
                    }}</Row>
                    <Row name="Paid at" v-if="invoice.paid_at">{{ invoice.paid_at | formatDate }}</Row>
                    <Row name="Automatic payment" v-if="invoice.has_automatic_payment_enabled">
                        <Icon
                            icon="check-circle"
                            v-if="invoice.has_automatic_payment_enabled"
                            color="var(--bs-success)"
                        />
                        <Icon icon="times-circle" v-else color="var(--bs-danger)" />
                    </Row>
                    <Row v-if="invoice.metadata" name="Metadata">
                        <RawOutput :item="invoice.metadata" />
                    </Row>
                    <Row name="Canceled at" v-if="invoice.canceled_at">{{ invoice.canceled_at | formatDate }}</Row>
                    <Row name="Payment status">
                        <PaymentStatus :status="invoice.payment_status" />
                    </Row>
                    <Row name="Credit note" v-if="invoice.credit_note">
                        <a href="#" class="text-link" @click.prevent="openCreditNotePanel(invoice.credit_note.id)">{{
                            invoice.credit_note.id
                        }}</a>
                    </Row>
                </Sheet>

                <Sheet name="Billing account" v-if="billingAccount">
                    <Row name="Name"
                        ><a href="#" @click.prevent="openBillingAccount(billingAccount)">{{
                            billingAccount.name
                        }}</a></Row
                    >
                    <Row name="City">{{ billingAccount.city }} {{ billingAccount.country_iso }}</Row>
                </Sheet>

                <Sheet name="Quote" v-if="quote">
                    <Row name="Reference"
                        ><a href="#" @click.prevent="openQuote(quote)">{{ quote.reference }}</a></Row
                    >
                    <Row name="Total">{{ quote.amount_total | currency }}</Row>
                    <Row name="Accepted at">{{ quote.accepted_at | formatDate }}</Row>
                    <template v-if="!!quote.processing_started_at">
                        <Row name="Processed started at">{{
                            quote.processing_started_at | formatDate('DD/MM/YYYY HH:mm:ss')
                        }}</Row>
                        <Row name="Processed finished at">
                            <Spinner v-if="!quote.processing_finished_at" />
                            <span v-else>{{ quote.processing_finished_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                        </Row>
                    </template>
                </Sheet>
                <ID name="Hexeko" :value="invoice.id" />
            </Tab>

            <!-- Lines -->
            <Tab name="Lines">
                <Table striped borderless :loading="loadingLines">
                    <template v-slot:thead>
                        <tr>
                            <th width="60%" colspan="2">Product</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Amount</th>
                        </tr>
                    </template>
                    <LineRow
                        v-for="(line, index) in lines"
                        :quantity="1"
                        :icon="line.type"
                        :title="line.product ? line.product.description : line.description"
                        :amount="line.amount"
                        :amount_unit="line.amount"
                        :key="'line-' + index"
                    >
                        <code class="text-muted" v-if="line.product">{{ line.product.slug }}</code>
                    </LineRow>
                </Table>
            </Tab>
        </Tabs>

        <template v-slot:actions v-if="$store.getters.userHasPermission('manageInvoices') && invoice">
            <!-- Actions -->
            <Button
                @click="downloadInvoice"
                color="secondary"
                small
                icon="download"
                :loading="downloadLoading"
                class="ms-1"
                >Download</Button
            >
            <Button @click="sendByMail" color="secondary" small icon="envelope" class="ms-1">Send by mail</Button>
            <template v-if="isNotFinalized">
                <Button
                    is="confirm"
                    @confirm="finalizeInvoice"
                    small
                    color="success"
                    icon="check-circle"
                    :loading="finalizeLoading"
                    class="ms-1"
                    >Finalize</Button
                >
            </template>
            <template v-else-if="!invoice.canceled_at">
                <Button
                    is="confirm"
                    v-if="!!quote"
                    @confirm="processQuote"
                    small
                    color="secondary"
                    icon="play-circle"
                    :loading="processLoading"
                    class="ms-1"
                    :disabled="quote.processing_started_at"
                    >{{
                        quote.processing_started_at && quote.processing_finished_at
                            ? 'Processed'
                            : quote.processing_started_at
                            ? 'Processing...'
                            : 'Process'
                    }}</Button
                >
                <Button
                    is="confirm"
                    v-if="!isPaid"
                    @confirm="setPaid"
                    small
                    color="success"
                    icon="check-circle"
                    :loading="paidLoading"
                    class="ms-1"
                    >Set paid</Button
                >
            </template>
            <Button
                v-if="isDraft"
                is="confirm"
                @confirm="deleteInvoice"
                small
                color="danger"
                icon="trash"
                :loading="deleteLoading"
                class="ms-1"
                >Delete</Button
            >
        </template>
    </Panel>
</template>

<script>
    import {
        Alert,
        Alerts,
        Badge,
        Button,
        ID,
        Panel,
        Row,
        Sheet,
        Tab,
        Table,
        Tabs,
        Steps,
    } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import LineRow from '@/components/layout/LineRow'
    import InvoiceStatus from './status'
    import PaymentStatus from './paymentStatus'
    import RawOutput from '@/components/RawOutput'
    import Swal from 'sweetalert2'

    export default {
        name: 'InvoicePanel',
        props: {
            id: String,
        },
        data() {
            return {
                billingAccount: null,
                deleteLoading: false,
                downloadLoading: false,
                finalizeLoading: false,
                invoice: null,
                lines: null,
                loading: true,
                loadingLines: true,
                paidLoading: false,
                processLoading: false,
                quote: null,
                stepsArray: ['Created', 'Finalized', 'Paid'],
            }
        },
        mounted() {
            this.fetchData()
        },
        mixins: [Modals],
        methods: {
            fetchData() {
                this.loading = true

                this.$api.billing.invoices
                    .show(this.id, { includes: ['credit_note'] })
                    .then((invoice) => {
                        this.invoice = invoice

                        // Fetch Invoice lines
                        if (!invoice.canceled_at) this.fetchLines()
                        // Fetch BA
                        this.fetchBillingAccount(invoice.billing_account_id)
                        // Fetch Quote
                        if (invoice.quote_id) this.fetchQuote(invoice.quote_id)
                        // stop loading
                        this.loading = false
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response ? error.response.data.message : 'Invoice: an error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
            },
            fetchQuote(id) {
                this.$api.billing.quotes
                    .show(id)
                    .then((quote) => {
                        this.quote = quote
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response ? error.response.data.message : 'Quote: an error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
            },
            fetchBillingAccount(id) {
                this.$api.billing.billingAccounts
                    .show(id)
                    .then((billingAccount) => {
                        this.billingAccount = billingAccount
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response
                                ? error.response.data.message
                                : 'Billing account: an error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
            },
            fetchLines() {
                this.loadingLines = true
                this.$api.billing.invoices
                    .getLines(this.id)
                    .then((response) => {
                        this.lines = response.data
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response ? error.response.data.message : 'Lines: an error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loadingLines = false
                    })
            },
            setPaid() {
                this.paidLoading = true
                this.$api.billing.invoices
                    .setPaid(this.id)
                    .then(() => {
                        this.fetchData()
                    })
                    .catch((error) => {
                        let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.paidLoading = false
                    })
            },
            finalizeInvoice() {
                this.finalizeLoading = true
                this.$api.billing.invoices
                    .finalize(this.id)
                    .then(() => {
                        this.fetchData()
                    })
                    .catch((error) => {
                        let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.finalizeLoading = false
                    })
            },
            deleteInvoice() {
                this.deleteLoading = true
                this.$api.billing.invoices
                    .delete(this.id)
                    .then(() => {
                        Alerts.messageSuccess('The invoice has been deleted')
                        this.$emit('closePanel', { refresh: true })
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response
                                ? error.response.data.message
                                : 'An error occurred when deleting this invoice'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.deleteLoading = false
                    })
            },
            downloadInvoice() {
                this.downloadLoading = true
                this.$api.billing.invoices
                    .download(this.id)
                    .then((payment) => {
                        window.open(payment.file_url, '_blank')
                        setTimeout(() => (this.downloadLoading = false), 4000)
                    })
                    .catch((error) => {
                        let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
            },
            openCreditNotePanel(id) {
                this.openPanel('CreditNotePanel', id)
            },
            openQuote(quote) {
                this.openPanel('QuotePanel', quote.id)
            },
            openBillingAccount(account) {
                this.openPanel('BillingAccountPanel', account.id)
            },
            processQuote() {
                this.processLoading = true
                this.$api.billing.quotes
                    .process(this.quote.id)
                    .then(() => {
                        Alerts.messageSuccess('Quote processing...')
                        this.fetchData()
                    })
                    .catch((error) => {
                        let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.processLoading = false
                    })
            },
            async sendByMail() {
                const { value: email } = await Swal.fire({
                    title: 'Email address',
                    input: 'email',
                    inputLabel: 'To receive the email',
                    inputPlaceholder: 'Email address',
                })

                if (email) {
                    this.$api.billing.invoices
                        .mail(this.invoice.id, { email: email })
                        .then(() => {
                            Alerts.messageSuccess('Sent by mail')
                        })
                        .catch((error) => {
                            let errorTxt =
                                error && error.response ? error.response.data.message : 'An error has occurred'
                            Alerts.notificationError(errorTxt)
                        })
                }
            },
        },
        components: {
            Alert,
            Badge,
            Button,
            ID,
            InvoiceStatus,
            Panel,
            RawOutput,
            Row,
            Sheet,
            Tab,
            Tabs,
            Steps,
            LineRow,
            Table,
            PaymentStatus,
        },
        computed: {
            activeSteps() {
                return this.invoice.paid_at ? 2 : this.invoice.finalized_at ? 1 : 0
            },
            isDraft() {
                return this.invoice?.status === 'draft'
            },
            isNotFinalized() {
                return !this.invoice?.finalized_at
            },
            isPaid() {
                return this.invoice?.paid_at
            },
            title() {
                return this.invoice?.reference ? 'Invoice' : 'Order'
            },
        },
    }
</script>
<style lang="scss" scoped>
    .progress-container {
        margin: 1rem auto 2.6rem auto;
        width: 82%;
    }
</style>
