<template>
    <FormRoot v-if="user">
        <!-- Intro -->
        <p>
            You are about to assign a random / temporary password to this user. Once you've clicked the button, copy the
            generated password and send it to the user. As soon as they log in using it, they will be asked to choose
            their own password.
        </p>
        <p>
            <strong>Please note!</strong> It will not be possible to display this password again, so make a note of it.
        </p>

        <Spinner v-if="form.loading" class="my-3" />
        <template v-else>
            <!-- Initial password generated -->
            <div v-if="isSent && fields.password" class="my-4" ref="displayPassword">
                <Button color="light" @click="copyPassword">
                    <code class="text-uppercase">Generated password</code>
                    <Badge color="light" class="ms-1">{{ fields.password }}</Badge>
                    <Icon icon="paste" class="text-secondary ms-2" />
                </Button>
            </div>

            <!-- Actions -->
            <FormActions>
                <Submit v-if="!isSent" class="float-right" :disabled="isSent" @click="onSubmit"
                    >Set the password</Submit
                >
                <Button v-else color="secondary" @click="rollPassword">Relaunch password generation</Button>
            </FormActions>
        </template>
    </FormRoot>
    <Alert v-else color="danger">No user has been specified.</Alert>
</template>

<script>
    import { Alert, Badge, Button, Field, FormActions, FormRoot, Spinner, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import Generator from '@/mixins/Generator'

    export default {
        name: 'InitialPassword',
        components: {
            Alert,
            Badge,
            Button,
            Field,
            FormActions,
            FormRoot,
            Spinner,
            Submit,
        },
        mixins: [Forms, Generator],
        props: {
            user: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                fields: { password: null },
                isSent: false,
                messageSuccess: 'The password has been assigned to the user',
            }
        },
        methods: {
            copyPassword() {
                this.$copyText(this.fields.password, this.$refs.displayPassword)
            },
            rollPassword() {
                this.isSent = false
                this.fields.password = this.generatePassword()
            },
            submit() {
                // Set initial password
                this.isSent = true
                return this.$api.users.setInitialPassword(this.user, {
                    password: this.fields.password,
                })
            },
        },
        mounted() {
            this.rollPassword()
        },
    }
</script>
