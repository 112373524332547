<template>
    <FormRoot :loading="dataLoading">
        <Field :form.sync="form" type="text" name="name" placeholder="Name of store" hint="Name of store"></Field>
        <Field :form.sync="form" type="text" name="branding" placeholder="Branding" hint="Branding"></Field>
        <Field :form.sync="form" type="boolean" name="apple_pay_enabled" hint="Apple Pay"></Field>
        <Field :form.sync="form" type="text" name="app_url" placeholder="App URL" hint="App URL"></Field>
        <Field :form.sync="form" type="text" name="manager_url" placeholder="Manager URL" hint="Manager URL"></Field>
        <Field :form.sync="form" type="text" name="admin_url" placeholder="Admin URL" hint="Admin URL"></Field>
        <Field :form.sync="form" type="text" name="landing_url" placeholder="Landing URL" hint="Landing URL"></Field>
        <Field
            :form.sync="form"
            type="text"
            name="expense_request_fees"
            placeholder="Expense request fee"
            hint="Expense request fee"
        ></Field>
        <Field
            :form.sync="form"
            type="text"
            name="expense_request_min_amount"
            placeholder="Expense request min amount"
            hint="Expense request min amount"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit"
                >Update</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        props: {
            id: Number,
            namespace: {
                type: String,
                default: 'default',
            },
        },
        data() {
            return {
                fields: {
                    name: null,
                    branding: null,
                    app_url: null,
                    manager_url: null,
                    admin_url: null,
                    landing_url: null,
                    expense_request_fees: 0,
                    expense_request_min_amount: 0,
                    apple_pay_enabled: false,
                },
                messageSuccess: 'The configuration has been saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            loadData() {
                return this.$api.stores.show(this.id)
            },
            submit() {
                return this.$api.stores.update(this.id, this.form.data())
            },
        },
        watch: {
            'form.config': {
                deep: true,
                handler: function() {
                    this.form.errors.clear()
                    this.form.keyUp()
                },
            },
        },
    }
</script>
