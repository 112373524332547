<template>
    <FormRoot :loading="dataLoading">
        <Field
            :form.sync="form"
            type="editor"
            name="description"
            :translate="true"
            placeholder="Description"
            hint="Description of the activity"
        ></Field>
        <Medias
            v-model="medias"
            :uploadFunction="uploadFunction"
            ref="medias"
            @deleted="fetchImages"
            @askRefresh="fetchImages"
        ></Medias>
        <FormActions>
            <Submit class="float-right" :loading="form.loading" @click="onSubmit">Update</Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import Medias from '@/components/layout/Medias'

    export default {
        data() {
            return {
                fields: {
                    description: null,
                },
                medias: [],
                messageSuccess: 'The description has been saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
            Medias,
        },
        mounted() {
            this.fetchImages()
        },
        methods: {
            loadData() {
                return this.$api.activities.show(this.id, {
                    includes: [
                        'types.parent',
                        'address',
                        'contacts',
                        'medias',
                        'first_media',
                        'tags',
                        'processes',
                        'description',
                    ],
                })
            },
            uploadFunction(file) {
                // Create form data
                const formData = new FormData()
                formData.append('media', file.file)

                // Set config
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }

                // Create voucher
                return this.$api.activities.addMedia(this.id, formData, config)
            },
            submit() {
                return Promise.all([
                    this.$api.activities.update(this.id, {
                        description: this.form.description,
                    }),
                    this.$refs.medias.startUpload(),
                ])
            },
            fetchImages() {
                this.loadData().then((response) => {
                    this.medias = response.medias
                })
            },
        },
    }
</script>
