<template>
    <FormRoot>
        <div class="mb-4">
            <p>
                This will change the status from <strong>{{ allowance.status.name }}</strong> to
                <strong>{{ status }}</strong
                >.
            </p>
            <ul>
                <li v-if="allowance.user && allowance.user.name">
                    Allowance claim from: <strong>{{ allowance.user.name }}</strong>
                </li>
                <li v-if="allowance.amount">
                    Amount: <strong>{{ allowance.amount | currency }}</strong>
                </li>
                <li v-if="allowance.created_at">
                    Created on: <strong>{{ allowance.created_at | formatDate }}</strong>
                </li>
            </ul>
        </div>
        <div class="mb-2">
            <Button
                color="light"
                small
                v-for="(answer, index) in answers"
                class="text-truncate mb-1 me-1"
                style="max-width: 200px"
                :key="index"
                icon="align-left"
                @click="setReason(answer)"
                >{{ answer[allowance.user.locale] }}</Button
            >
        </div>
        <Field :form.sync="form" type="textarea" name="reason" hint="Explanation of new status to the user"></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit"
                >Set status</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit, Button, Sheet, Row } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import answers from './answers'

    export default {
        mixins: [Forms],
        components: {
            Button,
            FormActions,
            FormRoot,
            Field,
            Row,
            Sheet,
            Submit,
        },
        data() {
            return {
                answers: answers,
                fields: { reason: null },
                messageSuccess: 'The status has been set',
            }
        },
        props: {
            allowance: {
                type: Object,
                required: true,
            },
            status: {
                type: String,
                required: true,
                validator: (value) => {
                    return ['decline'].includes(value)
                },
            },
        },
        methods: {
            setReason(answer) {
                this.form.reason = answer[this.allowance.user.locale]
            },
            submit() {
                switch (this.status) {
                    case 'decline':
                        return this.$api.allowances.decline(this.allowance.id, this.$data.form.data())
                }
            },
        },
    }
</script>
