export default [
    {
        en: 'The number of days you have cycled should be included in the validity dates.',
        fr: 'Le nombre de jours où vous avez pris le vélo doit être pris en compte dans les dates de validité.',
        nl: 'Het aantal dagen dat je hebt gefietst moet worden meegenomen in de geldigheidsdata.',
    },
    {
        en: 'The number of days you have carpooled must be included in the validity dates.',
        fr: 'Le nombre de jours où vous avez fait du covoiturage doit être pris en compte dans les dates de validité.',
        nl: 'Het aantal dagen dat u heeft gecarpoold moet worden meegenomen in de geldigheidsdata.',
    },
    {
        en: 'Please indicate the validity dates of your subscription ticket on the receipt.',
        fr: 'Veuillez indiquer les dates de validité de votre abonnement mentionnées sur le ticket justificatif.',
        nl: 'Vermeld de geldigheidsdata van je abonnement op de kassabon.',
    },
    {
        en:
            'Please add a receipt with your name on it. Or add a photo of your subscription ticket in addition to the ticket.',
        fr:
            'Veuillez ajouter un ticket justificatif sur lequel votre nom est repris. Ou ajouter une photo de votre carte d’abonnement en plus du ticket.',
        nl: 'Voeg een bon toe met je naam erop. Of voeg een foto van je abonnement toe naast het bonnetje.',
    },
    {
        en: "Please do not enter dates after today's date. You cannot anticipate absences (illness, holidays, etc.).",
        fr:
            'Veuillez ne pas entrer de dates postérieures à la date du jour. Vous ne pouvez pas prévoir les absences (maladies, vacances,…).',
        nl:
            'Vul geen data in na de datum van vandaag. Je kunt niet anticiperen op afwezigheden (ziekte, vakantie, enz.).',
    },
    {
        en:
            'You must not re-enter a request every month if you submitted one at the beginning of the year covering several months.',
        fr:
            'Vous ne devez pas ré-introduire de demande tous les mois si vous en avez introduit une début d’année qui couvre plusieurs mois.',
        nl:
            'Je moet niet elke maand opnieuw een aanvraag indienen als je er aan het begin van het jaar een hebt ingediend voor meerdere maanden.',
    },
    {
        en: 'Expenses prior to the current year are not accepted on UpOne.',
        fr: 'Les dépenses antérieures à l’année en cours ne sont pas acceptées sur UpOne.',
        nl: 'Uitgaven van voor het lopende jaar worden niet aanvaard in UpOne.',
    },
];
