<template>
    <Datatable
        name="Administrators"
        :pagination.sync="pagination"
        @search="fetchData"
        :loading="loading"
        :hover="false"
        search-class="d-none"
    >
        <template v-slot:thead>
            <tr>
                <th>User</th>
                <th>Email</th>
                <th>Actions</th>
            </tr>
        </template>
        <tr v-for="(user, index) in administrators" :key="'administrator-' + index" valign="middle">
            <td>
                <Icon icon="user" class="me-1" />
                <span class="me-3" v-if="user.name">{{ user.name }}</span>
            </td>
            <td>
                <a href="#" @click.prevent="openUser(user)">{{ user.email }}</a>
            </td>
            <td align="right">
                <a
                    href="#"
                    is="confirm"
                    tag="a"
                    @confirm="sendWelcomeMail(user.id)"
                    title="Send welcome mail?"
                    class="me-2"
                    ><Icon icon="envelope" /> Send welcome mail</a
                >
                <a
                    href="#"
                    is="confirm"
                    tag="a"
                    @confirm="removeAdministrator(user.id)"
                    title="Remove the administrator?"
                    class="text-danger"
                    ><Icon icon="times" /> Remove administrator</a
                >
            </td>
        </tr>
        <template v-slot:actions v-if="$store.getters.userHasPermission('manageGroups')">
            <Button color="secondary" small @click="openModal('addAdministrator')" icon="plus" class="me-1"
                >Add existing user</Button
            >
            <Modal
                v-if="isModalOpen('addAdministrator')"
                @close="closeModal('addAdministrator')"
                title="Add an administrator"
            >
                <AddAdministrator :group="group.id" @submitted="administratorAdded" />
            </Modal>
        </template>
    </Datatable>
</template>

<script>
    import { Alerts, Button, Datatable, Modal } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import AddAdministrator from './AddAdministrator'

    export default {
        name: 'Administrators',
        components: {
            AddAdministrator,
            Button,
            Datatable,
            Modal,
        },
        props: {
            group: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                administrators: [],
                loading: true,
                pagination: { current_page: 1 },
            }
        },
        mixins: [Modals],
        methods: {
            fetchData() {
                if (!this.group) return
                this.loading = true
                this.$api.groups
                    .getAdministrators(this.group.id, { page: this.pagination.current_page })
                    .then((response) => {
                        this.administrators = response.data
                        this.pagination = response.meta.pagination
                    })
                    .catch((error) => {
                        let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            openUser(user) {
                this.openPanel('userPanel', user.id)
            },
            sendWelcomeMail(user) {
                this.$api.users
                    .sendGroupAdminWelcomeMail(user, {
                        group: this.group.id,
                    })
                    .then(() => {
                        Alerts.notificationSuccess('Mail has been sent')
                    })
            },
            removeAdministrator(user_id) {
                this.$api.groups
                    .removeAdministrator(this.group.id, {
                        user_id: user_id,
                    })
                    .then(() => {
                        Alerts.notificationSuccess('The administrator has been removed')
                        this.pagination.current_page = 1
                        this.fetchData()
                    })
            },
            administratorAdded() {
                this.closeModal('addAdministrator')
                this.fetchData()
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
