<template>
    <FormRoot>
        <Field :form.sync="form" type="component" component="Partners" name="partner_id" hint="Enter partner"></Field>
        <div class="text-xxs text-muted">
            <Icon icon="info-circle" /> The partner is not listed ? Create
            <a href="#" @click.prevent="createPartner">a new one</a> with the transaction merchant details.
        </div>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit"
                >Identify merchant</Submit
            >
        </FormActions>
        <Modal
            v-if="isModalOpen('createPartner')"
            @close="closeModal('createPartner')"
            title="Create partner from authorization"
        >
            <UpdatePartner :values="partnerValues" @submitted="partnerCreated"></UpdatePartner>
        </Modal>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit, Modal } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import UpdatePartner from '@/components/Partners/UpdatePartner'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'

    export default {
        props: {
            transaction: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                fields: {
                    partner_id: null,
                },
                partnerValues: {},
                mids: [],
                messageSuccess: 'The merchant of the transaction has been identified',
            }
        },
        mixins: [Forms, Modals],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
            UpdatePartner,
            Modal,
        },
        methods: {
            submit() {
                return this.$api.cards.setCardAuthorizationPartner(this.transaction, this.$data.form.data())
            },
            createPartner() {
                this.$api.cards
                    .showAuthorization(this.transaction, {
                        includes: ['merchant'],
                    })
                    .then((authorization) => {
                        this.partnerValues = {
                            name: {
                                en: authorization.merchant.name,
                                nl: authorization.merchant.name,
                                fr: authorization.merchant.name,
                            },
                            address: {
                                city: authorization.merchant.address.city,
                                zipcode: authorization.merchant.address.postal_code,
                                country: authorization.merchant.address.country,
                            },
                        }
                        this.mids = [authorization.merchant.id]
                        this.openModal('createPartner')
                    })
            },
            partnerCreated(partner) {
                this.closeModal('createPartner')
                this.$api.partners
                    .update(partner.id, {
                        mids: this.mids,
                    })
                    .then(() => {
                        this.$emit('submitted')
                    })
            },
        },
    }
</script>
