<template>
    <div>
        <Datatable
            name="Users"
            @search="fetchData"
            :pagination.sync="pagination"
            :loading="loading"
            :hideActions="hideActions"
        >
            <template v-slot:filters>
                <Dropdown v-if="types" class="me-1" color="secondary" title="By wallet types" small>
                    <li v-for="(type, index) in types" :key="index">
                        <a
                            href="#"
                            class="dropdown-item text-xs"
                            :class="isActiveType(type.id) ? 'bg-primary text-white' : 'bg-white'"
                            @click.prevent="filterByWalletTypes(type.id)"
                        >
                            {{ type.name[$store.state.lang.displayLanguage] }}
                        </a>
                    </li>
                </Dropdown>
                <Dropdown v-if="getStores.length > 0" class="me-1" color="secondary" title="By store" small>
                    <li v-for="(store, index) in getStores" :key="index">
                        <a
                            href="#"
                            class="dropdown-item text-xs"
                            :class="storeSelected == store.id ? 'bg-primary text-white' : 'bg-white'"
                            @click.prevent="filterByStore(store.id)"
                            >{{ store.name }}</a
                        >
                    </li>
                </Dropdown>
                <Dropdown v-if="licences" class="me-1" color="secondary" title="With licence" small>
                    <li>
                        <a
                            href="#"
                            class="dropdown-item text-xs"
                            :class="
                                licences['virtual'] === 1
                                    ? 'bg-success text-white'
                                    : licences['virtual'] === 0
                                      ? 'bg-danger text-white'
                                      : 'bg-white'
                            "
                            @click.prevent="toggleFilterLicence('virtual')"
                        >
                            Virtual
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            class="dropdown-item text-xs"
                            :class="
                                licences['physical'] === 1
                                    ? 'bg-success text-white'
                                    : licences['physical'] === 0
                                      ? 'bg-danger text-white'
                                      : 'bg-white'
                            "
                            @click.prevent="toggleFilterLicence('physical')"
                        >
                            Physical
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            class="dropdown-item text-xs"
                            :class="
                                licences['user_account'] === 1
                                    ? 'bg-success text-white'
                                    : licences['user_account'] === 0
                                      ? 'bg-danger text-white'
                                      : 'bg-white'
                            "
                            @click.prevent="toggleFilterLicence('user_account')"
                        >
                            User account
                        </a>
                    </li>
                </Dropdown>

                <Button
                    class="me-1"
                    @click="toggleFilterWithCardPhysical"
                    :color="withCardsPhysical ? 'success' : withCardsPhysical === false ? 'danger' : 'secondary'"
                    small
                >
                    With physical card
                </Button>

                <Button
                    class="me-1"
                    @click="toggleFilterWithCardVirtual"
                    :color="withCardsVirtual ? 'success' : withCardsVirtual === false ? 'danger' : 'secondary'"
                    small
                >
                    With virtual card
                </Button>

                <Button
                    class="me-1"
                    @click="toggleFilterWithPhone"
                    :color="withPhone ? 'success' : withPhone === false ? 'danger' : 'secondary'"
                    small
                >
                    With phone
                </Button>

                <Button
                    class="me-1"
                    @click="toggleFilterWalletDeposited"
                    :color="withWalletDeposited ? 'success' : withWalletDeposited === false ? 'danger' : 'secondary'"
                    small
                >
                    With deposited wallet
                </Button>

                <Button
                    class="me-1"
                    @click="toggleFilterWithCredential"
                    :color="withCredential ? 'success' : withCredential === false ? 'danger' : 'secondary'"
                    small
                >
                    With credentials
                </Button>

                <Button
                    class="me-1"
                    @click="toggleFilterWithLastLogin"
                    :color="withLastLogin ? 'success' : withLastLogin === false ? 'danger' : 'secondary'"
                    small
                >
                    Logged in
                </Button>
            </template>
            <template v-slot:thead>
                <tr>
                    <th v-if="$store.getters.userHasPermission('manageUsers')"></th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>E-mail</th>
                    <th>Store</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </template>
            <tr v-for="(user, index) in users" @click="open(user)" :key="index" class="cursor-pointer">
                <td @click.stop="toggleSelect(user)" v-if="$store.getters.userHasPermission('manageUsers')">
                    <ToggleCheckbox :value="isSelected(user)" />
                </td>
                <td>
                    <code>{{ user.id }}</code>
                </td>
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td><Store :id="user.store_id" /></td>
                <td><Boolean v-model="user.phone" textOn="Phone" textOff="Phone" /></td>
                <td>
                    <Boolean
                        v-model="user.enabled"
                        :textOn="user.last_login ? 'Logged in' : 'Enabled'"
                        textOff="Disabled"
                    />
                </td>
                <td class="text-right"><Icon icon="angle-right" /></td>
            </tr>
            <template v-slot:actions v-if="$store.getters.userHasPermission('manageUsers')">
                <SelectAllButton
                    :metaSelected="metaSelected"
                    @selectAll="selectAll"
                    @unselectAll="unselectAll"
                    @reset="reset"
                >
                    <DropdownItem icon="users" :disabled="metaSelected.noneSelected" @click="openModal('updateGroups')"
                        >Add users to group</DropdownItem
                    >
                    <DropdownItem
                        icon="user-minus"
                        :disabled="metaSelected.noneSelected"
                        @click="openModal('removeGroups')"
                        >Remove users from group</DropdownItem
                    >
                    <DropdownItem icon="lock" :disabled="metaSelected.noneSelected" @click="bulkInitialPassword"
                        >Set initial password</DropdownItem
                    >
                    <DropdownItem
                        icon="envelope"
                        v-if="params.group"
                        :disabled="metaSelected.noneSelected"
                        @click="bulkWelcomeMail"
                        >Send welcome mail</DropdownItem
                    >
                    <DropdownItem
                        icon="envelope"
                        v-if="params.group"
                        :disabled="metaSelected.noneSelected"
                        @click="bulkForceWelcomeMail"
                        >Force send welcome mail</DropdownItem
                    >
                    <DropdownItem icon="wallet" :disabled="metaSelected.noneSelected" @click="openModal('createWallet')"
                        >Create wallet and deposit</DropdownItem
                    >
                    <DropdownItem
                        icon="credit-card"
                        :disabled="metaSelected.noneSelected"
                        @click="openModal('issueCard')"
                        >Issue card</DropdownItem
                    >
                    <DropdownItem
                        icon="certificate"
                        :disabled="metaSelected.noneSelected"
                        @click="openModal('createLicense')"
                        >Create license</DropdownItem
                    >
                    <DropdownItem icon="check-circle" :disabled="metaSelected.noneSelected" @click="bulkEnable"
                        >Enable</DropdownItem
                    >
                    <DropdownItem icon="times-circle" :disabled="metaSelected.noneSelected" @click="bulkDisable"
                        >Disable</DropdownItem
                    >
                    <DropdownItem icon="trash-alt" :disabled="metaSelected.noneSelected" @click="bulkDelete"
                        >Delete</DropdownItem
                    >
                </SelectAllButton>
                <Button small @click="openModal('createUser')" icon="plus">Create user</Button>

                <!-- Create user -->
                <Modal v-if="isModalOpen('createUser')" @close="closeModal('createUser')" title="Create an user">
                    <UpdateUser :values="params" @submitted="userSubmitted"></UpdateUser>
                </Modal>

                <!-- Issue card -->
                <Modal v-if="isModalOpen('issueCard')" @close="closeModal('issueCard')" title="Issue card">
                    <IssueCard :user="selected[0]" @submitted="bulkIssueCard" preventSubmit></IssueCard>
                </Modal>

                <!-- Create license -->
                <Modal v-if="isModalOpen('createLicense')" @close="closeModal('createLicense')" title="Create license">
                    <CreateLicense :values="params" @submitted="bulkCreateLicense" preventSubmit></CreateLicense>
                </Modal>

                <!-- Create wallet -->
                <Modal v-if="isModalOpen('createWallet')" @close="closeModal('createWallet')" title="Create wallets">
                    <CreateWallet @submitted="bulkDeposit" preventSubmit></CreateWallet>
                </Modal>

                <!-- Update Groups -->
                <Modal
                    v-if="isModalOpen('updateGroups')"
                    @close="closeModal('updateGroups')"
                    title="Add users to group"
                >
                    <Groups @submitted="bulkAddUsersToGroup" preventSubmit></Groups>
                </Modal>

                <!-- Remove Groups -->
                <Modal
                    v-if="isModalOpen('removeGroups')"
                    @close="closeModal('removeGroups')"
                    title="Remove users from group"
                >
                    <RemoveGroups @submitted="bulkRemoveUserFromGroup" preventSubmit></RemoveGroups>
                </Modal>

                <!-- Deposit -->
                <Modal v-if="isModalOpen('deposit')" @close="closeModal('deposit')" title="Deposit on wallets">
                    <Deposit @submitted="bulkDepositSubmit" preventSubmit></Deposit>
                </Modal>
            </template>
        </Datatable>
    </div>
</template>

<script>
    import {
        Datatable,
        Modal,
        Button,
        Boolean,
        ToggleCheckbox,
        Dropdown,
        DropdownItem,
    } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import UpdateUser from '@/components/Users/UpdateUser'
    import SelectAll from '@/mixins/SelectAll'
    import Generator from '@/mixins/Generator'
    import SelectAllButton from '@/components/layout/SelectAllButton'
    import IssueCard from '@/components/Users/IssueCard'
    import CreateLicense from '@/components/Users/CreateLicense'
    import Groups from '@/components/Users/Groups'
    import RemoveGroups from '@/components/Users/RemoveGroups'
    import CreateWallet from './CreateWallet'
    import Deposit from '../Wallets/Deposit'
    import Store from '@/components/layout/Store/index.vue'
    import { formatBoolean } from '@/utils/query'
    export default {
        name: 'Users',
        mixins: [Generator, Modals, SelectAll],
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                search: null,
                pagination: {
                    current_page: 1,
                },
                users: [],
                withCardsVirtual: null,
                withCardsPhysical: null,
                withPhone: null,
                withCredential: null,
                withLastLogin: null,
                withWalletDeposited: null,
                withWalletTypes: [],
                bulkWalletsData: null,
                types: [],
                storeSelected: null,
                licences: {
                    virtual: null,
                    physical: null,
                    user_account: null,
                },
            }
        },
        components: {
            Store,
            Deposit,
            CreateWallet,
            Datatable,
            Modal,
            UpdateUser,
            Button,
            ToggleCheckbox,
            SelectAllButton,
            Dropdown,
            DropdownItem,
            Boolean,
            IssueCard,
            Groups,
            CreateLicense,
            RemoveGroups,
        },
        mounted() {
            this.fetchTypes()
            this.fetchData()
        },
        computed: {
            getStores() {
                return this.$store.getters.getStores
            },
            filterLicences() {
                return {
                    virtual_card: this.licences.virtual,
                    physical_card: this.licences.physical,
                    user_account: this.licences.user_account,
                }
            },
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.search = search
                this.$api.users
                    .get(
                        this.mergeParams({
                            search: this.search,
                            page: this.pagination.current_page,
                            with_cards: {
                                virtual: formatBoolean(this.withCardsVirtual),
                                physical: formatBoolean(this.withCardsPhysical),
                            },
                            with_phone: formatBoolean(this.withPhone),
                            has_credential: formatBoolean(this.withCredential),
                            has_logged: formatBoolean(this.withLastLogin),
                            with_wallet_deposited: formatBoolean(this.withWalletDeposited),
                            with_wallet_types: this.withWalletTypes,
                            with_licenses: this.filterLicences,
                            order_by: 'created_at',
                            sort_by: 'desc',
                            store_id: this.storeSelected ? this.storeSelected : undefined,
                            includes: ['contacts'],
                        })
                    )
                    .then((response) => {
                        this.users = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
                    .catch((error) => {
                        if (error && error.response) console.log(error.response.data.message)
                    })
            },
            fetchTypes() {
                this.$api.types
                    .get({
                        depth: 0,
                        page: 1,
                    })
                    .then((response) => {
                        this.types = response.data
                    })
            },
            updateFilterCard(type) {
                if (type == 'physical' || type === 'virtual') {
                    if (this.filterCards['any']) {
                        this.filterCards['any'] = 0
                    }
                }
                this.filterCards[type] = this.filterCards[type] === 0 ? 1 : 0
                this.fetchData(this.search)
            },
            allIds() {
                return this.$options.filters.pluck(this.users, 'id')
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            open(user) {
                let panel = this.openPanel('UserPanel', user.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
            userSubmitted(user) {
                this.fetchData(this.search)
                this.closeModal('createUser')
                this.$emit('userSubmitted', user)
            },
            bulkWelcomeMail() {
                this.bulkAction('Send Welcome Mail', (id) => {
                    return this.$api.users.sendWelcomeMail(id, { group: this.params.group })
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            bulkForceWelcomeMail() {
                this.bulkAction('Force send Welcome Mail', (id) => {
                    return this.$api.users.resendWelcomeMail(id, { group: this.params.group })
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            bulkIssueCard(data) {
                this.closeModal('issueCard')
                this.bulkAction('Issue Cards', (id) => {
                    if (data) delete data.pin
                    if (data.random_pin) {
                        const pin = this.generatePin()
                        return this.encryptPin(pin)
                            .then((encrypted_pin) => {
                                data.encrypted_pin = encrypted_pin
                            })
                            .finally(() => {
                                return this.$api.users.createCard(id, data)
                            })
                    } else {
                        return this.$api.users.createCard(id, data)
                    }
                })
            },
            bulkCreateLicense(data) {
                this.closeModal('createLicense')
                this.bulkAction('Create license', (id) => {
                    return this.$api.groups.createUserLicense(data.group_id, id, data)
                })
            },
            bulkDeposit(data) {
                this.bulkWalletsData = data
                this.closeModal('createWallet')
                this.openModal('deposit')
            },
            bulkDepositSubmit(depositData) {
                this.closeModal('deposit')
                this.bulkAction('Create wallets and deposit', (id) => {
                    // Create wallet
                    return this.$api.users.createWallet(id, this.bulkWalletsData).then((wallet) => {
                        // Deposit on wallet
                        return this.$api.wallets.deposit(wallet.id, depositData)
                    })
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            bulkInitialPassword() {
                this.bulkAction(
                    'Set initial password',
                    (id) => {
                        // Generate password
                        let password = this.generatePassword()
                        // Set it to the User
                        return this.$api.users
                            .setInitialPassword(id, {
                                password: password,
                            })
                            .then((response) => {
                                return {
                                    ...response,
                                    ...{ data: password },
                                }
                            })
                    },
                    true
                ).then(() => {
                    this.fetchData(this.search)
                })
            },
            bulkEnable() {
                this.bulkAction('Enable', (id) => {
                    return this.$api.users.enable(id)
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            bulkDisable() {
                this.bulkAction('Disable', (id) => {
                    return this.$api.users.disable(id)
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            bulkDelete() {
                this.bulkAction('Delete', (id) => {
                    return this.$api.users.delete(id)
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            bulkAddUsersToGroup(data) {
                this.closeModal('updateGroups')
                this.bulkAction('Add users to group', (id) => {
                    return this.$api.groups.addUser(data.group_id, {
                        user_id: id,
                    })
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            bulkRemoveUserFromGroup(data) {
                this.closeModal('removeGroups')
                this.bulkAction('Remove users from group', (id) => {
                    return this.$api.groups.removeUser(data.group_id, {
                        user_id: id,
                    })
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            isActiveType(type) {
                return this.withWalletTypes.includes(type)
            },
            toggleFilterWithCardVirtual() {
                if (this.withCardsVirtual === null) this.withCardsVirtual = true
                else if (this.withCardsVirtual === true) this.withCardsVirtual = false
                else this.withCardsVirtual = null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            toggleFilterWithCardPhysical() {
                if (this.withCardsPhysical === null) this.withCardsPhysical = true
                else if (this.withCardsPhysical === true) this.withCardsPhysical = false
                else this.withCardsPhysical = null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            toggleFilterWithPhone() {
                if (this.withPhone === null) this.withPhone = true
                else if (this.withPhone === true) this.withPhone = false
                else this.withPhone = null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            toggleFilterWithCredential() {
                if (this.withCredential === null) this.withCredential = true
                else if (this.withCredential === true) this.withCredential = false
                else this.withCredential = null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            toggleFilterWithLastLogin() {
                if (this.withLastLogin === null) this.withLastLogin = true
                else if (this.withLastLogin === true) this.withLastLogin = false
                else this.withLastLogin = null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            toggleFilterWalletDeposited() {
                if (this.withWalletDeposited === null) this.withWalletDeposited = true
                else if (this.withWalletDeposited === true) this.withWalletDeposited = false
                else this.withWalletDeposited = null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            toggleFilterLicence(type) {
                if (this.licences[type] === null) this.licences[type] = 1
                else if (this.licences[type] === 1) this.licences[type] = 0
                else this.licences[type] = null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            filterByWalletTypes(id) {
                // Remove OR add selected type
                const index = this.withWalletTypes.indexOf(id)
                index > -1 ? this.withWalletTypes.splice(index, 1) : this.withWalletTypes.push(id)
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            filterByStore(id) {
                if (this.storeSelected == id) {
                    this.storeSelected = null
                } else if (this.storeSelected != id) {
                    this.storeSelected = id
                }
                this.fetchData(this.search)
            },
        },
    }
</script>
