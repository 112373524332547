<template>
    <div>
        <Tabs :pills="!embedInTab" :small="embedInTab" @selectTab="selectTab" ref="embedTabs">
            <Tab
                name="Users"
                :selected="selected('users', true)"
                :to="{ name: 'users' }"
                v-if="$store.getters.userHasAnyPermission(['showUsers', 'manageUsers'])"
            />
            <Tab
                name="Groups"
                :selected="selected('groups')"
                :to="{ name: 'groups' }"
                v-if="$store.getters.userHasAnyPermission(['showGroups', 'manageGroups'])"
            />
            <Tab
                name="Wallets"
                :selected="selected('wallets')"
                :to="{ name: 'wallets' }"
                v-if="$store.getters.userHasAnyPermission(['showWallets', 'manageWallets'])"
            />
        </Tabs>
        <router-view :params="params" v-if="!embedInTab" />
        <template v-else-if="activeComponent">
            <component :params="params" :is="activeComponent.name" />
        </template>
    </div>
</template>

<script>
    import TabsMixin from '../../../mixins/TabsMixin'
    import Users from '../../Users'
    import Cards from '../../Cards'

    export default {
        components: {
            Users,
            Cards,
        },
        mixins: [TabsMixin],
    }
</script>
