<template>
    <div>
        <!-- Medias -->
        <div v-if="Array.isArray(this.value) && value.length > 0" class="mb-1">
            <Media :media="media" v-for="(media, index) in value" @delete="deleteMedia(media)" :key="index" />
        </div>
        <!-- Dropzone -->
        <Dropzone v-model="files" :multiple="multiple"></Dropzone>
        <!-- Hint -->
        <InputHint class="mb-4"
            >Click to select file<span v-if="multiple">s</span> or drag-and-drop from your computed</InputHint
        >
    </div>
</template>

<script>
    import { InputHint, Dropzone } from '@tech_hexeko/design-system'
    import Media from './Media'
    let _ = require('lodash')

    export default {
        props: {
            value: Array,
            uploadFunction: Function,
            multiple: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                files: [],
                loading: false,
                timer: null,
            }
        },
        mounted() {
            this.enableCheckThumbs()
        },
        destroyed() {
            this.disableCheckThumbs()
        },
        methods: {
            startUpload() {
                return new Promise((resolve, reject) => {
                    // Start progress
                    if (this.filesToUpload.length === 0) resolve()
                    this.loading = true

                    // Create requests
                    _.forEach(this.filesToUpload, (file) => {
                        file.error = false
                        file.uploading = true
                        this.uploadFunction(file)
                            .then(() => {
                                file.success = true
                            })
                            .catch((error) => {
                                let errors = error.response.data.errors
                                file.error = _(errors)
                                    .values()
                                    .flatten()
                                    .value()
                                file.uploading = false
                                this.loading = false
                                reject(error)
                            })
                            .finally(() => {
                                if (this.checkEnd()) {
                                    file.uploading = false
                                    this.loading = false
                                    resolve()
                                }
                            })
                    })
                })
            },
            checkEnd() {
                return this.filesUploaded.length === this.queueCount
            },
            deleteMedia(media) {
                this.$api.medias.delete(media.id).then(() => {
                    this.$set(media, 'deleted', true)
                })
            },
            enableCheckThumbs() {
                this.timer = setInterval(() => {
                    if (this.missingThumb) {
                        this.$emit('askRefresh')
                    }
                }, 3000)
            },
            disableCheckThumbs() {
                clearInterval(this.timer)
            },
        },
        components: {
            Dropzone,
            InputHint,
            Media,
        },
        computed: {
            missingThumb: function() {
                return (
                    Array.isArray(this.value) &&
                    _.filter(this.value, function(media) {
                        return !media.responsive_images
                    }).length > 0
                )
            },
            queueCount: function() {
                return this.files.length
            },
            filesUploaded: function() {
                return _.filter(this.files, function(file) {
                    return file.success
                })
            },
            filesToUpload: function() {
                return _.filter(this.files, function(file) {
                    return !file.success
                })
            },
        },
        watch: {
            files: {
                deep: true,
                handler: function(val) {
                    this.$emit('input', val)
                },
            },
        },
    }
</script>
