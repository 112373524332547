<template>
    <div>
        <Card title="Roles" class="mb-3" color="light">
            <Checkbox
                v-model="userRoles"
                :modelValue="role.name"
                v-for="(role, index) in roles"
                :key="index"
                class="mb-1"
                small
            >
                {{ role.name }}</Checkbox
            >
        </Card>
        <Card title="Permissions" class="mb-3" color="light">
            <Checkbox
                v-model="userPermissions"
                :modelValue="permission.name"
                v-for="(permission, index) in permissions"
                :key="index"
                class="mb-1"
                small
            >
                {{ permission.name }}</Checkbox
            >
        </Card>
        <Submit @click="savePermissionsAndRoles" :disabled="loading">Save</Submit>
    </div>
</template>

<script>
    import { Checkbox, Card, Submit, Alerts } from '@tech_hexeko/design-system'
    import axios from 'axios'

    export default {
        props: {
            id: Number,
        },
        components: {
            Card,
            Checkbox,
            Submit,
        },
        data() {
            return {
                loading: false,
                roles: [],
                permissions: [],
                userRoles: [],
                userPermissions: [],
            }
        },
        mounted() {
            this.fetchRoles()
            this.fetchPermissions()
            this.fetchUser()
        },
        methods: {
            fetchUser() {
                this.$api.users.getRoles(this.id).then((response) => {
                    this.userRoles = this.$options.filters.pluck(response.data, 'name')
                })
                this.$api.users.getPermissions(this.id).then((response) => {
                    this.userPermissions = this.$options.filters.pluck(response.data, 'name')
                })
            },
            fetchRoles() {
                this.$api.roles.get({ per_page: 100 }).then((response) => {
                    this.roles = response.data
                })
            },
            fetchPermissions() {
                this.$api.permissions.get({ per_page: 100 }).then((response) => {
                    this.permissions = response.data
                })
            },
            savePermissionsAndRoles() {
                this.loading = true

                // set requests
                const requestRoles = this.$api.users.setRoles(this.id, { roles: this.userRoles })
                const requestPermissions = this.$api.users.setPermissions(this.id, {
                    permissions: this.userPermissions,
                })

                // make requests
                axios.all([requestRoles, requestPermissions]).then(
                    axios.spread(() => {
                        // success
                        Alerts.notificationSuccess('Permissions saved')
                        // stop loading
                        this.loading = false
                        this.$emit('submitted')
                    })
                )
            },
        },
    }
</script>
