<template>
    <FormRoot>
        <Field
            :form.sync="form"
            type="number"
            name="amount"
            min="0"
            placeholder="Amount in €"
            hint="Enter a positive amount in euros"
        ></Field>
        <Field
            :form.sync="form"
            type="radio"
            name="days"
            :options="[
                {
                    label: 'Per day',
                    value: 1,
                },
                {
                    label: 'Per transaction',
                    value: null,
                },
            ]"
            optionsKeyBy="value"
            optionsLabel="label"
            hint="What is the spending limit period?"
        />
        <label v-for="(day, index) in days" :key="index" class="d-block">
            <input type="checkbox" v-model="form.for_days" :value="index" /> {{ day }}
        </label>
        <InputHint>When is the limit applied? If no days are selected, the limit is always applied.</InputHint>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" @click="onSubmit">Save</Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit, InputHint } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        props: {
            wallet: {
                type: Number,
                required: false,
            },
        },
        data() {
            return {
                fields: {
                    amount: null,
                    days: 1,
                    for_days: [],
                },
                days: {
                    1: 'Monday',
                    2: 'Tuesday',
                    3: 'Wednesday',
                    4: 'Thursday',
                    5: 'Friday',
                    6: 'Saturday',
                    7: 'Sunday',
                },
                messageSuccess: 'The spending limit has been added on wallet',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
            InputHint,
        },
        methods: {
            submit() {
                return this.$api.wallets.setSpendingLimits(this.wallet, this.$data.form.data())
            },
        },
    }
</script>
