export default {
    debit_required: {
        name: 'Debit required',
        color: 'warning',
        icon: 'circle',
    },
    debit_failed: {
        name: 'Debit failed',
        color: 'danger',
        icon: 'times-circle',
    },
    debit_successful: {
        name: 'Debit successful',
        color: 'success',
        icon: 'check-circle',
    },
    switch_payment_methods_requested: {
        name: 'Switch requested',
        color: 'secondary',
        icon: 'random',
    },
    switch_payment_methods_approved: {
        name: 'Switch approved',
        color: 'warning',
        icon: 'random',
    },
    switch_payment_methods_successful: {
        name: 'Switch successful',
        color: 'success',
        icon: 'random',
    },
    switch_payment_methods_declined: {
        name: 'Switch declined',
        color: 'danger',
        icon: 'random',
    },
    switch_payment_methods_failed: {
        name: 'Switch failed',
        color: 'danger',
        icon: 'random',
    },
    refund_required: {
        name: 'Refund required',
        color: 'secondary',
        icon: 'undo',
    },
    refund_successful: {
        name: 'Refund successful',
        color: 'success',
        icon: 'undo',
    },
    refund_failed: {
        name: 'Refund failed',
        color: 'danger',
        icon: 'undo',
    },
};
