<template>
    <div>
        <Sheet v-if="globalStatus" class="mb-2">
            <Row name="Global Status">
                <DocumentStatus :status="globalStatus" />
            </Row>
        </Sheet>
        <Datatable name="Documents" search-class="d-none" :hover="false" :loading="loading">
            <template v-slot:thead>
                <tr>
                    <th>Type</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </template>
            <tr v-for="doc in documents" :key="doc.type">
                <td>
                    <code>{{ doc.type }}</code>
                </td>
                <td>
                    <DocumentStatus :status="doc.status" />
                </td>
                <td align="right">
                    <Button
                        @click="showDocument(doc)"
                        class="me-1"
                        color="secondary"
                        :disabled="loadingShowDocument && loadingShowDocument !== doc.id"
                        icon="eye"
                        :loading="loadingShowDocument === doc.id"
                        small
                    >
                        Show
                    </Button>
                    <Dropdown title="Update status" color="secondary" position="right" small>
                        <DropdownItem
                            v-if="!isVerifiedOrSucceeded(doc.status)"
                            @click="startSetStatus('manually_verified', doc)"
                        >
                            Manually verified
                        </DropdownItem>
                        <DropdownItem v-if="doc.status !== 'failed'" @click="startSetStatus('failed', doc)">
                            Failed
                        </DropdownItem>
                    </Dropdown>
                </td>
            </tr>
        </Datatable>

        <!-- Set document status -->
        <Modal v-if="isModalOpen('setStatus')" @close="stopSetStatus" :title="`Set status to: ${newStatus}`">
            <SetStatus :status="newStatus" :doc="documentSelected" @submitted="statusSubmitted" />
        </Modal>
    </div>
</template>

<script>
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import { Alerts, Button, Datatable, Dropdown, DropdownItem, Modal, Row, Sheet } from '@tech_hexeko/design-system'
    import DocumentStatus from './DocumentStatus'
    import SetStatus from './SetStatus'

    export default {
        name: 'Documents',
        mixins: [Modals],
        components: {
            Alerts,
            Button,
            Datatable,
            DocumentStatus,
            Dropdown,
            DropdownItem,
            Modal,
            Row,
            SetStatus,
            Sheet,
        },
        props: {
            groupId: {
                type: Number,
                default: null,
                required: true,
            },
        },
        data() {
            return {
                documents: [],
                documentsId: null,
                documentSelected: null,
                globalStatus: null,
                loading: true,
                loadingShowDocument: null,
                newStatus: null,
            }
        },
        methods: {
            fetchDocuments() {
                this.loading = true
                this.$verificationApi.verifications
                    .getStatus(this.groupId)
                    .then((res) => {
                        this.documents = res.documents_status
                        this.globalStatus = res.status
                        this.documentsId = res.id
                    })
                    .catch((error) => {
                        if (error && error.response && error.response.status !== 404)
                            Alerts.notificationError(error.response.data.message)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            isVerifiedOrSucceeded(status) {
                return ['manually_verified', 'succeeded'].includes(status)
            },
            showDocument(doc) {
                if (this.loadingShowDocument === doc.id) return
                this.loadingShowDocument = doc.id
                this.$verificationApi.verifications
                    .showDocument(this.documentsId, doc.type)
                    .then((res) => {
                        window.open(res.path, '_blank')
                    })
                    .catch((err) => {
                        let errorTxt =
                            err && err.response && err.response.data
                                ? err.response.data.message
                                : 'An error occurred while trying to show the document'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loadingShowDocument = null
                    })
            },
            startSetStatus(status, doc) {
                this.newStatus = status
                this.documentSelected = doc
                this.openModal('setStatus')
            },
            statusSubmitted() {
                this.stopSetStatus()
                this.fetchDocuments()
            },
            stopSetStatus() {
                this.newStatus = null
                this.closeModal('setStatus')
            },
        },
        mounted() {
            this.fetchDocuments()
        },
    }
</script>
