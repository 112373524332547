<template>
    <Badge v-if="ss" :color="ss.color" :large="large" :icon="ss.icon">{{ ss.name }}</Badge>
</template>
<script>
    import { Badge } from '@tech_hexeko/design-system'
    import statuses from './statuses'

    export default {
        name: 'CardAuthorizationStatus',
        props: {
            status: {
                type: Object,
                default: () => ({}),
            },
            large: Boolean,
        },
        computed: {
            ss: function() {
                return !!this.status.name ? statuses[this.status.name] : null
            },
        },
        components: {
            Badge,
        },
    }
</script>
