<template>
    <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.827 9v10h5.703c.714 0 1.297-.66 1.297-1.472V9h-7ZM8.827 9h-7v8.528c0 .812.582 1.472 1.297 1.472h5.703V9ZM18.353 3.79h-2.842c.298-.396.474-.888.474-1.421A2.371 2.371 0 0 0 13.616 0c-1.317 0-2.421 1.044-2.962 1.665a6.89 6.89 0 0 0-.827 1.17c-.217-.39-.5-.792-.827-1.17C8.46 1.045 7.354 0 6.038 0a2.372 2.372 0 0 0-2.369 2.369c0 .533.177 1.025.474 1.42H1.301a.474.474 0 0 0-.474.474v2.843c0 .262.211.473.474.473h17.052c.261 0 .474-.211.474-.473V4.263a.475.475 0 0 0-.474-.473Zm-12.315 0a1.422 1.422 0 0 1-1.421-1.421c0-.784.637-1.422 1.42-1.422.688 0 1.507.489 2.249 1.34.456.525.788 1.084.952 1.503h-3.2Zm7.578 0h-3.2c.164-.419.495-.978.953-1.503.74-.851 1.56-1.34 2.247-1.34.783 0 1.421.638 1.421 1.422 0 .783-.638 1.42-1.42 1.42Z"
            fill="#000"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-gift',
    }
</script>
