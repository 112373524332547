<template>
    <span v-if="!!slug" class="custom-reason">
        <template v-if="$te(`shared.reasons.${slug}`)">{{ $t(`shared.reasons.${slug}`) }}</template>
        <template v-else>{{ parseReason }}</template>
    </span>
</template>

<script>
    export default {
        name: 'Reason',
        props: {
            slug: {
                type: String,
                default: null,
            },
        },
        computed: {
            parseReason() {
                try {
                    const reason = JSON.parse(this.slug)
                    return reason.details.map((data) => data.reason).join('\n')
                } catch (error) {
                    return this.slug
                }
            },
        },
    }
</script>
<style lang="css" scoped>
    .custom-reason {
        white-space: pre-line;
    }
</style>
