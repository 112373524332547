<template>
    <div>
        <Spinner v-if="loading" />
        <template v-else>
            <!-- Status -->
            <Alert color="warning" v-if="!can_switch.status" icon="exclamation-circle"
                ><Reason :slug="can_switch.reason"
            /></Alert>
            <template v-else-if="allowedPaymentMethods">
                <Alert
                    v-if="allowedPaymentMethods.payment_methods.length === 0"
                    color="warning"
                    icon="exclamation-circle"
                    >No payment methods available</Alert
                >
                <template v-else>
                    <SelectPaymentMethod
                        v-model="payment_methods_to_debit"
                        :payment_methods="allowedPaymentMethods.payment_methods"
                        :amount="allowedPaymentMethods.amount_to_select"
                        useBalance
                    />
                    <Submit class="float-right" @click="submit" :disabled="payment_methods_to_debit.length === 0"
                        >Switch</Submit
                    >
                </template>
            </template>
        </template>
    </div>
</template>

<script>
    import { Submit, Alert, Spinner, Alerts } from '@tech_hexeko/design-system'
    import Reason from '@/components/Reason'
    import SelectPaymentMethod from '@tech_hexeko/hexeko-user-account/src/components/PaymentMethods'
    import axios from 'axios'

    export default {
        name: 'SwitchPaymentMethods',
        props: {
            id: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                payment_methods_to_debit: [],
                can_switch: null,
                allowedPaymentMethods: null,
                loading: true,
            }
        },
        components: {
            Submit,
            Alert,
            Reason,
            SelectPaymentMethod,
            Spinner,
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.loading = true

                // set requests
                const requestStatus = this.$api.cards.showCardTransactions(this.id, {
                    includes: ['can_switch_payment_methods'],
                })
                const requestPaymentMethods = this.$api.cards.getPaymentMethodsAllowed(this.id, {
                    includes: [
                        'payment_methods.source.types.slugs',
                        'payment_methods.source.total_deposit',
                        'payment_methods.source.spendable_amount',
                    ],
                })

                // make requests
                axios
                    .all([requestStatus, requestPaymentMethods])
                    .then(
                        axios.spread((...responses) => {
                            const responseStatus = responses[0].can_switch_payment_methods
                            const responsePaymentMethods = responses[1]
                            // set data
                            this.can_switch = responseStatus
                            this.allowedPaymentMethods = responsePaymentMethods
                        })
                    )
                    .catch(() => {
                        Alerts.notificationError('Error while fetching data')
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            submit() {
                return this.$api.cards
                    .switchPaymentMethods(this.id, { payment_methods_to_debit: this.payment_methods_to_debit })
                    .then(() => {
                        this.submitted()
                        Alerts.messageSuccess('Switch requested')
                    })
                    .catch(() => {
                        Alerts.messageError('Failed to switch')
                    })
            },
            submitted() {
                this.$emit('submitted')
            },
        },
    }
</script>
