export default {
    pending: {
        name: 'Pending',
        color: 'warning',
        icon: 'pause-circle',
    },
    confirmed: {
        name: 'Confirmed',
        color: 'success',
        icon: 'check-circle',
    },
    declined: {
        name: 'Declined',
        color: 'danger',
        icon: 'stop-circle',
    },
    cancelled: {
        name: 'Cancelled',
        color: 'danger',
        icon: 'times-circle',
    },
};
