<template>
    <FormRoot>
        <Field
            :form.sync="form"
            type="boolean"
            name="terms_confirmed"
            placeholder="Terms confirmed"
            hint="If checked, user aggree with General Terms and Conditions"
        ></Field>
        <Field
            :form.sync="form"
            type="boolean"
            name="opt_in"
            placeholder="Opt-in"
            hint="If checked, user wants to receive news and promotions by e-mail"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" @click="onSubmit">Save user</Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot } from '@tech_hexeko/design-system'
    import { Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        data() {
            return {
                fields: {
                    terms_confirmed: null,
                    opt_in: null,
                },
                messageSuccess: 'Options saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            loadData() {
                return this.$api.users.show(this.id)
            },
            submit() {
                return this.$api.users.update(this.id, this.$data.form.notNullData())
            },
        },
    }
</script>
