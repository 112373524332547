import { Form } from '@tech_hexeko/design-system';
import { Alerts } from '@tech_hexeko/design-system';
let _ = require('lodash');
let flatten = require('flat');

export default {
    props: {
        values: {
            type: Object,
            default: () => ({}),
        },
        id: {
            type: [Number, String],
            required: false,
        },
        preventSubmit: {
            type: Boolean,
            default: false,
        },
    },
    data: function() {
        return {
            form: null,
            dataLoading: false,
        };
    },
    created() {
        this.form = new Form(this.fields);
        this.setValuesIntoForm(this.values);
    },
    methods: {
        onSubmit() {
            if (!this.preventSubmit) {
                this.form
                    .submit(() => {
                        return this.submit();
                    })
                    .then((response) => {
                        this.submitted(response);
                        Alerts.messageSuccess(this.messageSuccess ?? 'Successfully submitted');
                    })
                    .catch((error) => {
                        let errorTxt = error && error.message ? error.message : 'An error has occurred, but no details';
                        if (error && error.errors)
                            errorTxt +=
                                '\nErrors:\n- ' +
                                _(error.errors)
                                    .values()
                                    .flatten()
                                    .value()
                                    .join('\n- ');
                        Alerts.notificationError(errorTxt);
                    });
            } else {
                this.submitted(this.form.notNullData());
            }
        },
        submitted(response) {
            this.$emit('submitted', response);
        },
        setValuesIntoForm(values) {
            if (values) {
                // Get keys to add
                let keysToAdd = this.getKeysToAddFromValues(values);

                // Create fill object with values
                let toFill = {};
                keysToAdd.forEach((key) => {
                    _.set(toFill, key, _.get(values, key));
                });

                // Fill form with object
                this.form.fillAsOriginalData(toFill);
            }
        },
        getKeysToAddFromValues(values) {
            // Exceptions
            delete values['medias'];

            // Get keys of values to add
            let flattened = flatten(values);
            let inputKeys = this.getKeys(flattened);
            let inputRootKeys = this.getKeys(values);
            inputKeys = inputKeys.concat(inputRootKeys);

            // Intersect of allowed and input
            return _.intersection(inputKeys, this.allowedKeys);
        },
        getKeys(obj) {
            var allKeys = [];
            for (var key in obj) {
                allKeys.push(key);
            }
            return allKeys;
        },
        fetchData() {
            this.dataLoading = true;
            this.loadData().then((response) => {
                this.setValuesIntoForm(response);
                this.dataLoading = false;
            });
        },
    },
    watch: {
        form: {
            deep: true,
            handler: function() {
                this.form.keyUp();
            },
        },
        values: {
            deep: true,
            handler: function() {
                this.setValuesIntoForm(this.values);
            },
        },
        id: {
            immediate: true,
            handler: function(val) {
                if (val) {
                    this.fetchData();
                }
            },
        },
    },
    computed: {
        allowedKeys: function() {
            let flattened = flatten(this.fields);
            let originalKeys = _.keys(flattened);
            let translated = originalKeys.map(function(key) {
                return [key, key + '.fr', key + '.nl', key + '.en'];
            });
            return _.flatten(translated);
        },
    },
};
