<template>
    <FormRoot :loading="dataLoading">
        <Field :form.sync="form" type="tags" multiple name="tags" placeholder="Tags" hint="Tags of activity"></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit"
                >Update</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    let _ = require('lodash')

    export default {
        data() {
            return {
                fields: {
                    tags: [],
                },
                messageSuccess: 'The options has been saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            loadData() {
                return this.$api.activities
                    .show(this.id, {
                        includes: [
                            'tags',
                            'types.parent',
                            'address',
                            'contacts',
                            'medias',
                            'first_media',
                            'processes',
                            'description',
                        ],
                    })
                    .then((activity) => {
                        activity.tags = _.map(activity.tags, function(item) {
                            return typeof item === 'object' ? item.en : item
                        })
                        return activity
                    })
            },
            submit() {
                if (this.id) {
                    return this.$api.activities.update(this.id, this.$data.form.data())
                } else {
                    return this.$api.activities.create(this.$data.form.data())
                }
            },
        },
    }
</script>
