<template>
    <Datatable
        name="Pending Quote Lines"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
        @search="fetchData"
        :hover="false"
    >
        <template v-slot:thead>
            <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Quotable ID</th>
                <th width="100px">User</th>
                <th>Failed</th>
                <th class="text-end">Actions</th>
            </tr>
        </template>
        <tr v-for="(line, index) in pendingQuoteLines" :key="'pendingQuoteLine-' + index">
            <td>{{ line.created_at | formatDate }}</td>
            <td>{{ line.type }}</td>
            <td>{{ line.quotable_id }}</td>
            <td>
                <a v-if="line.user_id" href="#" @click.prevent="openUser(line.user_id)"
                    ><Icon icon="user" /> {{ line.user_id }}</a
                >
            </td>
            <td>
                <span v-if="line.failed_at" v-tooltip="line.failed_reason">{{ line.failed_at | formatDate }}</span>
                <template v-else>-</template>
            </td>
            <td class="text-end">
                <a
                    v-if="$store.getters.userHasPermission('manageQuotes')"
                    href="#"
                    class="text-danger"
                    is="confirm"
                    tag="a"
                    @confirm="deletePendingQuoteLine(line.id)"
                    title="Are you sure you want to delete this pending quote line?"
                    ><Icon icon="trash-alt" /> Delete</a
                >
            </td>
        </tr>
    </Datatable>
</template>

<script>
    import { Alerts, Boolean, Datatable } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'

    export default {
        name: 'PendingQuoteLines',
        components: {
            Boolean,
            Datatable,
        },
        props: {
            billingAccount: {
                type: String,
                required: true,
            },
            hideActions: Boolean,
            params: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                loading: true,
                pagination: { current_page: 1 },
                pendingQuoteLines: [],
                search: null,
            }
        },
        methods: {
            deletePendingQuoteLine(id) {
                this.$api.billing.billingAccounts
                    .deletePendingQuoteLine(id)
                    .then(() => {
                        Alerts.messageSuccess('Pending Quote Line deleted')
                        this.fetchData()
                    })
                    .catch((error) => {
                        let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
            },
            fetchData(search) {
                if (!this.billingAccount) return
                this.search = search
                this.loading = true
                this.$api.billing.billingAccounts
                    .getPendingQuoteLines(
                        this.billingAccount,
                        this.mergeParams({
                            page: this.pagination.current_page,
                            search: search ? search : undefined,
                        })
                    )
                    .then((response) => {
                        this.pendingQuoteLines = response.data
                        this.pagination = response.meta.pagination
                    })
                    .catch((error) => {
                        let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            openUser(id) {
                this.openPanel('userPanel', Number(id))
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
