<template>
    <Badge :color="ss.color" :large="large" :icon="ss.icon">{{ ss.name }}</Badge>
</template>
<script>
    import { Badge } from '@tech_hexeko/design-system'

    let statuses = {
        active: {
            name: 'Active',
            color: 'success',
            icon: 'check-circle',
        },
        pending: {
            name: 'Pending',
            color: 'warning',
            icon: 'question-circle',
        },
        blocked: {
            name: 'Blocked',
            color: 'danger',
            icon: 'exclamation-circle',
        },
    }

    export default {
        name: 'CardPinStatus',
        components: {
            Badge,
        },
        props: {
            status: [String, Object],
            large: Boolean,
        },
        computed: {
            ss: function() {
                return statuses[this.status]
            },
        },
    }
</script>
