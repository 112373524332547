<template>
    <Datatable
        name="Vouchers"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
    >
        <template v-slot:thead>
            <tr>
                <th>&nbsp;</th>
                <th>ID</th>
                <th>Value</th>
                <th>Code</th>
                <th>Expiration date</th>
                <th>Picked</th>
                <th></th>
            </tr>
        </template>
        <tr v-for="(voucher, index) in vouchers" @click="open(voucher)" class="cursor-pointer" :key="index">
            <td @click.stop="toggleSelect(voucher)"><ToggleCheckbox :value="isSelected(voucher)" /></td>
            <td>
                <code>{{ voucher.id }}</code>
            </td>
            <td>
                <span v-if="voucher.value">{{ voucher.value | currency }}</span>
            </td>
            <td>
                <span v-if="voucher.code_last4">###{{ voucher.code_last4 }}</span>
            </td>
            <td>{{ voucher.expiration_date | formatDate }}</td>
            <td>
                <VoucherStatus :voucher="voucher"></VoucherStatus>
            </td>
            <td class="text-right"><Icon icon="angle-right" /></td>
        </tr>
        <template v-slot:actions v-if="$store.getters.userHasPermission('manageVouchers')">
            <Dropdown small title="Add vouchers" position="right">
                <DropdownItem @click="openModal('importPdf')" icon="upload">Import PDF vouchers</DropdownItem>
                <DropdownItem @click="openModal('importExcel')" icon="file-excel">Import list of vouchers</DropdownItem>
            </Dropdown>
            <SelectAllButton
                class="ms-1"
                :metaSelected="metaSelected"
                @selectAll="selectAll"
                @unselectAll="unselectAll"
                @reset="reset"
            >
                <DropdownItem icon="trash-alt" @click="bulkDelete">Delete</DropdownItem>
            </SelectAllButton>
            <!-- Import PDF -->
            <Modal v-if="isModalOpen('importPdf')" @close="closeModal('importPdf')" title="Import PDF vouchers">
                <UploadPDF :list="params.list" @finished="vouchersUploaded"></UploadPDF>
            </Modal>
            <!-- Import Excel -->
            <Modal v-if="isModalOpen('importExcel')" @close="closeModal('importExcel')" title="Import Excel vouchers">
                <div class="alert alert-warning">Soon available</div>
            </Modal>
        </template>
    </Datatable>
</template>

<script>
    import { Alerts, Datatable, Dropdown, DropdownItem, Modal, ToggleCheckbox } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import SelectAll from '@/mixins/SelectAll'
    import SelectAllButton from '@/components/layout/SelectAllButton'
    import UploadPDF from '@/components/Vouchers/UploadPDF'
    import VoucherStatus from './VoucherStatus'

    export default {
        name: 'Vouchers',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                pagination: {
                    current_page: 1,
                },
                vouchers: [],
            }
        },
        components: {
            Datatable,
            Dropdown,
            DropdownItem,
            Modal,
            SelectAllButton,
            ToggleCheckbox,
            UploadPDF,
            VoucherStatus,
        },
        mixins: [Modals, SelectAll],
        mounted() {
            this.fetchData()
        },
        methods: {
            allIds() {
                return this.$options.filters.pluck(this.vouchers, 'id')
            },
            bulkDelete() {
                this.bulkAction('Delete', (id) => {
                    return this.$api.vouchers.delete(id)
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            fetchData(search) {
                this.loading = true
                this.$api.vouchers
                    .get(
                        this.mergeParams({
                            search: search,
                            page: this.pagination.current_page,
                        })
                    )
                    .then((response) => {
                        this.vouchers = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            open(voucher) {
                let panel = this.openPanel('VoucherPanel', voucher.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
            vouchersUploaded() {
                Alerts.notificationSuccess('Vouchers uploaded')
                this.closeModal('importPdf')
                this.fetchData()
            },
        },
    }
</script>
