<template>
    <Datatable
        name="Expenses"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
    >
        <template v-slot:filters>
            <DateRange class="float-left" time @setFromDate="searchFromDate" @setToDate="searchToDate" />
            <Dropdown v-if="getStores.length > 0" class="mb-1" color="secondary" title="Filter by store" small>
                <li v-for="(store, index) in getStores" :key="index">
                    <a
                        href="#"
                        class="dropdown-item text-xs"
                        :class="storeSelected == store.id ? 'bg-primary text-white' : 'bg-white'"
                        @click.prevent="filterByStore(store.id)"
                        >{{ store.name }}</a
                    >
                </li>
            </Dropdown>
            <Button
                class="ms-1"
                @click="toggleIsRefund"
                :color="is_refund ? 'success' : is_refund === false ? 'danger' : 'secondary'"
                small
                >Refund</Button
            >
        </template>
        <template v-slot:thead>
            <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Store</th>
                <th>User</th>
                <th>Partner</th>
                <th>Amount</th>
                <th>VAT</th>
                <th>Status</th>
                <th></th>
            </tr>
        </template>
        <template v-slot:default>
            <tr v-for="(expense, index) in expenses" @click="open(expense)" :key="'expense-' + index">
                <td>
                    <code>{{ expense.id }}</code>
                </td>
                <td>{{ expense.created_at | formatDate }}</td>
                <td><Store v-if="expense.user" :id="expense.user.store_id" /></td>
                <td>
                    <div v-if="expense.user">{{ expense.user.name }}</div>
                </td>
                <td>
                    <Badge v-if="expense.partner" color="success" icon="handshake">{{
                        expense.partner.name[$store.state.lang.displayLanguage]
                    }}</Badge>
                    <Badge v-else color="secondary" icon="question-circle">Partner unknown</Badge>
                </td>
                <td>{{ expense.amount | currency }}</td>
                <td>{{ expense.vat_rate }} %</td>
                <td>
                    <ExpenseStatus :expense="expense" />
                </td>
                <td class="text-right"><Icon icon="angle-right" /></td>
            </tr>
        </template>
    </Datatable>
</template>

<script>
    import { Datatable, Dropdown, Boolean, DateRange, Badge, Button } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import Store from '@/components/layout/Store'
    import moment from 'moment'
    import ExpenseStatus from '@/components/Expenses/status.vue'
    import { formatBoolean } from '@/utils/query'

    export default {
        name: 'Expenses',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                search: null,
                pagination: {
                    current_page: 1,
                },
                expenses: [],
                date_from: null,
                date_to: null,
                is_refund: null,
                storeSelected: null,
            }
        },
        components: {
            ExpenseStatus,
            Datatable,
            Dropdown,
            DateRange,
            Badge,
            Button,
            Store,
        },
        computed: {
            getStores() {
                return this.$store.getters.getStores
            },
        },
        methods: {
            fetchData(search) {
                this.search = search
                this.loading = true
                let params = this.mergeParams({
                    search: this.search,
                    page: this.pagination.current_page,
                    order_by: 'created_at',
                    sort_by: 'desc',
                    includes: ['partner', 'user'],
                    date_from: this.date_from ? this.date_from : undefined,
                    date_to: this.date_to ? this.date_to : undefined,
                    store_id: this.storeSelected ? this.storeSelected : undefined,
                    is_refund: formatBoolean(this.is_refund),
                })
                return this.$api.expenses
                    .get(params)
                    .then((response) => {
                        this.expenses = response.data
                        this.pagination = response.meta.pagination
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            open(expense) {
                let panel = this.openPanel('ExpensePanel', expense.id)
                panel.promise.then((result) => {
                    if (result) {
                        this.fetchData(this.search)
                    }
                })
            },
            searchFromDate(val) {
                this.date_from = this.isDate(val) ? val : null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            searchToDate(val) {
                this.date_to = this.isDate(val) ? val : null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            toggleIsRefund() {
                if (this.is_refund === null) this.is_refund = true
                else if (this.is_refund === true) this.is_refund = false
                else this.is_refund = null
                this.fetchData()
            },
            isDate(val) {
                return moment(val, 'YYYY-MM-DD').isValid()
            },
            filterByStore(id) {
                if (this.storeSelected == id) {
                    this.storeSelected = null
                } else if (this.storeSelected != id) {
                    this.storeSelected = id
                }
                this.fetchData(this.search)
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
