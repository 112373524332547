<template>
    <Badge :color="getColor" :icon="getIcon">{{ getTitle }}</Badge>
</template>
<script>
    import { Badge } from '@tech_hexeko/design-system'

    export default {
        name: 'DocumentStatus',
        props: {
            status: {
                type: String,
            },
        },
        components: {
            Badge,
        },
        data() {
            return {
                statuses: {

                    // Common statuses
                    succeeded: {
                        icon: 'check-circle',
                        title: 'Succeeded',
                        color: 'success',
                    },
                    in_progress: {
                        icon: 'pause-circle',
                        title: 'In progress',
                        color: 'warning',
                    },
                    failed: {
                        icon: 'stop-circle',
                        title: 'Failed',
                        color: 'danger',
                    },

                    // Document statuses
                    api_verified: {
                        icon: 'check-circle',
                        title: 'Automatically verified',
                        color: 'success',
                    },
                    manually_verified: {
                        icon: 'check-circle',
                        title: 'Manually verified',
                        color: 'success',
                    },

                    // Global statuses
                    not_started: {
                        icon: 'pause-circle',
                        title: 'Not started',
                        color: 'secondary',
                    },
                    manual_verification_required: {
                        icon: 'pause-circle',
                        title: 'Manual verification required',
                        color: 'warning',
                    },
                },
            }
        },
        computed: {
            getColor() {
                return this.statuses[this.status]?.color ?? 'danger'
            },
            getIcon() {
                return this.statuses[this.status]?.icon ?? 'stop-circle'
            },
            getTitle() {
                return this.statuses[this.status]?.title ?? 'Unknown'
            },
        },
    }
</script>
