var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormRoot',[_c('Field',{attrs:{"form":_vm.form,"type":"number","name":"amount","min":"0","placeholder":"Amount in €","hint":"Enter a positive amount in euros"},on:{"update:form":function($event){_vm.form=$event}}}),_c('Field',{attrs:{"form":_vm.form,"type":"radio","name":"days","options":[
            {
                label: 'Per day',
                value: 1,
            },
            {
                label: 'Per transaction',
                value: null,
            } ],"optionsKeyBy":"value","optionsLabel":"label","hint":"What is the spending limit period?"},on:{"update:form":function($event){_vm.form=$event}}}),_vm._l((_vm.days),function(day,index){return _c('label',{key:index,staticClass:"d-block"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.for_days),expression:"form.for_days"}],attrs:{"type":"checkbox"},domProps:{"value":index,"checked":Array.isArray(_vm.form.for_days)?_vm._i(_vm.form.for_days,index)>-1:(_vm.form.for_days)},on:{"change":function($event){var $$a=_vm.form.for_days,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=index,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "for_days", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "for_days", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "for_days", $$c)}}}}),_vm._v(" "+_vm._s(day)+" ")])}),_c('InputHint',[_vm._v("When is the limit applied? If no days are selected, the limit is always applied.")]),_c('FormActions',[_c('Submit',{staticClass:"float-right",attrs:{"disabled":!_vm.form.submittable},on:{"click":_vm.onSubmit}},[_vm._v("Save")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }