<template>
    <tr>
        <td v-if="icon !== false"><IconWallet :category="icon" v-if="icon" /></td>
        <td>
            <div>{{ title }}</div>
            <div v-if="$slots.default" class="text-xxs text-muted"><slot /></div>
        </td>
        <td>{{ quantity }}</td>
        <td>{{ amount_unit | currency }}</td>
        <td>{{ amount | currency }}</td>
    </tr>
</template>

<script>
    import IconWallet from '@/components/IconWallet'
    export default {
        name: 'LineRow',
        props: {
            icon: {
                type: [String, Boolean],
                default: null,
            },
            title: {
                type: String,
                default: null,
            },
            quantity: {
                type: Number,
                default: 0,
            },
            amount: {
                type: Number,
                default: 0,
            },
            amount_unit: {
                type: Number,
                default: 0,
            },
        },
        components: {
            IconWallet,
        },
    }
</script>

<style scoped>
    .text-small {
        font-size: 14px;
    }
</style>
