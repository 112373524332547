<template>
    <div class="row">
        <template v-if="hasLine2">
            <div class="col-12">
                <Field
                    :form.sync="form"
                    type="text"
                    :name="name + '.line1'"
                    @input="handleLine1Input"
                    placeholder="Street address"
                    hint="Street address"
                ></Field>
            </div>
            <div class="col-12">
                <Field
                    :form.sync="form"
                    type="text"
                    :name="name + '.line2'"
                    @input="handleLine2Input"
                    placeholder="Additional address"
                    hint="Additional address"
                ></Field>
            </div>
        </template>
        <div v-else class="col-12">
            <Field
                :form.sync="form"
                type="text"
                :name="name + '.address'"
                @input="clearErrors"
                placeholder="Street address"
                hint="Address"
            ></Field>
        </div>
        <div class="col-6">
            <Field
                :form.sync="form"
                type="text"
                :name="name + '.zipcode'"
                @input="clearErrors"
                placeholder="Zipcode"
                hint="Zipcode"
            ></Field>
        </div>
        <div class="col-6">
            <Field
                :form.sync="form"
                type="text"
                :name="name + '.city'"
                @input="clearErrors"
                placeholder="City"
                hint="City"
            ></Field>
        </div>
        <div class="col-12">
            <Field
                :form.sync="form"
                type="country"
                :name="name + '.country'"
                @input="clearErrors"
                placeholder="Country"
                hint="Country"
            ></Field>
        </div>
        <div class="col-12">
            <Error v-if="form.errors.has(name)" :error="form.errors.get(name)" class="mb-3" />
        </div>
    </div>
</template>

<script>
    import { Field, Error } from '@tech_hexeko/design-system'

    export default {
        props: {
            form: Object,
            hasLine2: {
                type: Boolean,
                default: false,
            },
            name: String,
        },
        components: {
            Field,
            Error,
        },
        computed: {
            zipcodeBuffer() {
                return this.form[this.name].zipcode
            },
        },
        methods: {
            clearErrors() {
                this.form.errors.clear(this.name)
                this.$forceUpdate()
            },
            handleLine1Input(value) {
                this.clearErrors()
                if (value.length > 50) {
                    this.form.errors.add(`${this.name}.line1`, 'Please reduce field to max length 50 or less')
                }
            },
            handleLine2Input(value) {
                this.clearErrors()
                if (value.length > 50) {
                    this.form.errors.add(`${this.name}.line2`, 'Please reduce field to max length 50 or less')
                }
            },
        },
        watch: {
            zipcodeBuffer: {
                immediate: true,
                handler(zipcode) {
                    if (!zipcode) return
                    this.form[this.name].zipcode = zipcode.toString()
                },
            },
        },
    }
</script>
