<template>
    <div>
        <Datatable name="Cards" @search="fetchData" :pagination.sync="pagination" :loading="loading" hover scrollX>
            <template v-slot:filters>
                <Button
                    class="me-1"
                    @click="toggleFilterCanceled(0)"
                    :color="cardIsCanceled === 0 ? 'success' : 'secondary'"
                    small
                >
                    Active
                </Button>
                <Button
                    class="me-1"
                    @click="toggleFilterCanceled(1)"
                    :color="cardIsCanceled === 1 ? 'success' : 'secondary'"
                    small
                >
                    All
                </Button>
            </template>
            <template v-slot:thead>
                <tr>
                    <th v-if="$store.getters.userHasPermission('manageCards')"></th>
                    <th>ID</th>
                    <th>Type</th>
                    <th>Number</th>
                    <th>Expiry</th>
                    <th>Currency</th>
                    <th>Name on card</th>
                    <th>Issued by</th>
                    <th>Status</th>
                    <th v-if="wallet">Linked&nbsp;on</th>
                    <th v-if="wallet">Updated&nbsp;at</th>
                    <th></th>
                </tr>
            </template>
            <tr v-for="(card, index) in cards" @click="openCard(card)" :key="'card-' + index">
                <td @click.stop="toggleSelect(card)" v-if="$store.getters.userHasPermission('manageCards')">
                    <ToggleCheckbox :value="isSelected(card)" />
                </td>
                <td>
                    <code>{{ card.id }}</code>
                </td>
                <td>
                    <Badge class="text-capitalize">{{ card.type }}</Badge>
                </td>
                <td><Icon icon="credit-card" /> •••• {{ card.last_four }}</td>
                <td>{{ card.expiry }}</td>
                <td>{{ card.currency }}</td>
                <td>{{ card.name_on_card }}</td>
                <td>
                    <span v-if="card.issuer"><Icon icon="building" /> {{ card.issuer.name }}</span>
                </td>
                <td><CardStatus :card="card" /></td>
                <td v-if="card && card.wallet_linked && card.wallet_linked.wallet_card_created_at">
                    {{ card.wallet_linked.wallet_card_created_at | formatDate('DD/MM/YYYY') }}
                </td>
                <td v-if="card && card.wallet_linked && card.wallet_linked.wallet_card_updated_at">
                    {{ card.wallet_linked.wallet_card_updated_at | formatDate('DD/MM/YYYY') }}
                </td>
                <td><Icon icon="angle-right" /></td>
            </tr>
            <template v-slot:actions v-if="$store.getters.userHasPermission('manageCards')">
                <SelectAllButton
                    :metaSelected="metaSelected"
                    @selectAll="selectAll"
                    @unselectAll="unselectAll"
                    @reset="reset"
                >
                    <DropdownItem
                        icon="stop-circle"
                        :disabled="metaSelected.noneSelected"
                        @click="openModal('stopCard')"
                        >Stop cards</DropdownItem
                    >
                </SelectAllButton>
                <Modal v-if="isModalOpen('stopCard')" @close="closeModal('stopCard')" title="Stop cards">
                    <StopCard @submitted="bulkStopCards" bulk preventSubmit />
                </Modal>
            </template>
        </Datatable>
    </div>
</template>

<script>
    import { Alerts, Badge, Button, Datatable, DropdownItem, Modal, ToggleCheckbox } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import SelectAll from '@/mixins/SelectAll'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import SelectAllButton from '@/components/layout/SelectAllButton'
    import StopCard from '@/components/Cards/StopCard'
    import CardStatus from '@/components/Cards/status.vue'

    export default {
        name: 'Cards',
        mixins: [Modals, SelectAll],
        components: {
            Badge,
            Button,
            CardStatus,
            Datatable,
            DropdownItem,
            Modal,
            SelectAllButton,
            StopCard,
            ToggleCheckbox,
        },
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            wallet: {
                type: Number,
            },
        },
        data() {
            return {
                loading: false,
                search: null,
                pagination: {
                    current_page: 1,
                },
                cards: [],
                cardIsCanceled: 0,
            }
        },
        methods: {
            allIds() {
                return this.$options.filters.pluck(this.cards, 'id')
            },
            bulkStopCards(data) {
                this.closeModal('stopCard')
                this.bulkAction('Stop cards', (id) => {
                    return this.$api.cards.stop(id, data)
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            fetchData(search) {
                this.loading = true
                this.search = search
                if (this.params?.cards?.length > 0) {
                    this.cards = this.params.cards
                    this.loading = false
                } else {
                    this.fetchFunction(
                        this.mergeParams({
                            search: this.search,
                            page: this.pagination.current_page,
                            with_cancelled: this.cardIsCanceled ? 1 : undefined,
                            includes: ['issuer'],
                            order_by: 'created_at',
                            sort_by: 'desc',
                        })
                    )
                        .then((response) => {
                            this.cards = response.data
                            this.pagination = response.meta.pagination
                        })
                        .catch((error) => {
                            if (error && error.response) Alerts.notificationError(error.response.data.message)
                        })
                        .finally(() => {
                            this.loading = false
                        })
                }
            },
            fetchFunction(params) {
                if (this.params.user) {
                    return this.$api.users.getCards(this.params.user, params)
                } else if (this.params.group) {
                    return this.$api.groups.getCards(this.params.group, params)
                } else if (this.wallet) {
                    return this.$api.wallets.getCards(this.wallet, params)
                } else {
                    return this.$api.cards.get(params)
                }
            },
            openCard(card) {
                let panel = this.openPanel('CardPanel', card.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
            openGroup(group) {
                this.openPanel('GroupPanel', group.id)
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            toggleFilterCanceled(value) {
                if (this.cardIsCanceled === value) {
                    this.cardIsCanceled = null
                } else {
                    this.cardIsCanceled = value
                }
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
