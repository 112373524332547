<template>
    <svg viewBox="0 0 26 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M25.616 31.724a1.825 1.825 0 0 1-2.507.502l-.8-.542v9.88a1.814 1.814 0 0 1-1.815 1.802H6.047a1.813 1.813 0 0 1-1.816-1.803v-9.88l-.8.543c-.297.196-.646.3-1.003.298a1.804 1.804 0 0 1-1.003-3.307l3.605-2.399h.014l7.223-4.824a1.802 1.802 0 0 1 2.006 0l7.224 4.824h.013l3.605 2.4a1.808 1.808 0 0 1 .502 2.506ZM11.986 14.976a1.815 1.815 0 1 0 2.567 2.566 1.815 1.815 0 0 0-2.567-2.566ZM18.38 12.955a1.806 1.806 0 0 0 1.278-3.084 9.045 9.045 0 0 0-12.776 0 1.806 1.806 0 1 0 2.555 2.555 5.43 5.43 0 0 1 7.666 0 1.802 1.802 0 0 0 1.277.53Z"
            fill="#000"
        />
        <path
            d="M4.327 7.317a12.646 12.646 0 0 1 17.885 0 1.806 1.806 0 0 0 2.555-2.554 16.259 16.259 0 0 0-22.994 0 1.807 1.807 0 0 0 2.554 2.554Z"
            fill="#000"
        />
    </svg>
</template>

<script>
    export default {
        name: 'icon-telework',
    }
</script>
