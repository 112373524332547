<template>
    <FormRoot :loading="dataLoading">
        <Field
            :form.sync="form"
            type="text"
            name="name"
            :translate="true"
            placeholder="Name"
            hint="Name of the partner"
        ></Field>
        <FieldAddress :form.sync="form" name="address" hasLine2></FieldAddress>
        <div class="row">
            <div class="col-6">
                <Field :form.sync="form" type="phone" name="contacts.phone" placeholder="Phone" hint="Phone"></Field>
            </div>
            <div class="col-6">
                <Field :form.sync="form" type="text" name="contacts.email" placeholder="E-mail" hint="E-mail"></Field>
            </div>
        </div>
        <Field :form.sync="form" type="text" name="website" placeholder="http://" hint="Website of the partner"></Field>
        <Field :form.sync="form" type="text" name="vat_number" placeholder="VAT number" hint="VAT number"></Field>
        <Field
            :form.sync="form"
            type="text"
            name="registration_number"
            placeholder="Registration number"
            hint="Registration number"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit"
                >Update</Submit
            >
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import FieldAddress from '@/components/layout/FieldAddress'

    export default {
        data() {
            return {
                fields: {
                    name: null,
                    address: {
                        line1: null,
                        line2: null,
                        zipcode: null,
                        city: null,
                        country: null,
                    },
                    contacts: {
                        phone: null,
                        email: null,
                    },
                    website: null,
                    medias: [],
                    vat_number: null,
                    registration_number: null,
                },
                messageSuccess: 'The partner has been saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
            FieldAddress,
        },
        methods: {
            loadData() {
                return this.$api.partners.show(this.id).then((partner) => {
                    if (partner.address?.zipcode) partner.address.zipcode = partner.address.zipcode.toString()
                    return partner
                })
            },
            submit() {
                if (this.id) {
                    return this.$api.partners.update(
                        this.id,
                        this.$data.form.notNullData(['contacts', 'mid', 'commission'])
                    )
                } else {
                    return this.$api.partners.create(this.$data.form.notNullData())
                }
            },
        },
    }
</script>
