<template>
    <Panel :title="role ? role.name : null" :loading="loading" @closePanel="closePanel">
        <Card class="my-3" color="light">
            <Checkbox
                v-model="checkedPermissions"
                :modelValue="permission.id"
                v-for="(permission, index) in permissions"
                :key="index"
                class="mb-1"
                small
            >
                {{ permission.name }}</Checkbox
            >
        </Card>
        <Submit @click="savePermissions" :loading="formLoading">Save</Submit>
    </Panel>
</template>

<script>
    import { Panel, Card, Checkbox, Submit, Alerts } from '@tech_hexeko/design-system'
    import axios from 'axios'

    export default {
        name: 'RolePanel',
        props: {
            id: Number,
        },
        data() {
            return {
                loading: true,
                role: null,
                checkedPermissions: [],
                permissions: [],
                formLoading: false,
            }
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.loading = true

                // set requests
                const requestRole = this.$api.roles.show(this.id)
                const requestPermissions = this.$api.permissions.get({ per_page: 1000 })

                // make requests
                axios.all([requestRole, requestPermissions]).then(
                    axios.spread((...responses) => {
                        const responseRole = responses[0]
                        const responsePermissions = responses[1]
                        // set data
                        this.role = responseRole
                        this.checkedPermissions = this.$options.filters.pluck(responseRole.permissions, 'id')
                        this.permissions = responsePermissions.data
                        // stop loading
                        this.loading = false
                    })
                )
            },
            savePermissions() {
                this.formLoading = true
                this.$api.roles.update(this.id, { permissions: this.checkedPermissions }).then((response) => {
                    // success
                    Alerts.notificationSuccess('Permissions saved')
                    // stop loading
                    this.formLoading = false
                    this.loading = true
                    this.$emit('submitted')
                    this.closePanel()
                })
            },
            closePanel() {
                this.$emit('closePanel')
            },
        },
        components: {
            Panel,
            Card,
            Checkbox,
            Submit,
        },
    }
</script>
